import React from "react";
import styled from "styled-components";
import { BiError } from "react-icons/bi";
import { theme } from "../../../theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${theme.translucentRed};
  color: #393939;
  border-radius: 0.92em;
  position: relative;
  padding: 0.76em;
  font-size: 13px;
  margin: 15px 0;

  svg {
    color: ${theme.red1};
    font-size: 18px;
  }
`;

const ErrorMessage = ({ message }) => {
  return (
    <Container>
      <BiError />
      <div>{message}</div>
    </Container>
  );
};

export default ErrorMessage;
