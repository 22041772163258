import React from "react";
import styled from "styled-components";

const CompareContainer = styled.div`
  padding: 0 140px;
  background-color: transparent;
  color: #fff;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const CompareHeader = styled.p`
  margin-bottom: 20px;
  font-size: 50px;
  font-weight: 700;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const CompareSubText = styled.p`
  margin-bottom: 20px;
  font-size: 32px;
  color: ${({ theme }) => theme.lightGray};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const CompareRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 100px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;

const CompareType = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;

  p {
    font-size: 38px;
    color: ${({ theme }) => theme.black};
    font-weight: 500;
    border-bottom: 4px solid #6198b0;
    text-align: left;

    @media (max-width: 768px) {
      padding: 15px 0;
      font-size: 18px;
      text-align: center;
    }
  }
`;

const SpecContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  border-radius: 20px;
  padding: 10px;
  place-content: center;
  align-items: center;
  justify-content: center;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }

  p {
    font-size: 38px;
    color: ${({ theme }) => theme.black};
    font-weight: 500;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  div {
    padding: 30px 0;
    place-items: center;
    width: 100%;

    @media (max-width: 768px) {
      padding: 15px 0;
    }

    img {
      width: 70px;

      @media (max-width: 768px) {
        width: 50px;
      }
    }
  }
`;

const Compare = () => {
  return (
    <CompareContainer>
      <CompareHeader>Compare</CompareHeader>
      <CompareSubText>Why 400 Million users choose MitterPay</CompareSubText>
      <CompareRow>
        <CompareType>
          <p></p>
          <p>Team Organization</p>
          <p>Cheaper Transaction Fee</p>
          <p>Faster Transaction Speed</p>
        </CompareType>
        <SpecContainer style={{ backgroundColor: "#00C853" }}>
          <p>MitterPay</p>
          <div>
            <img src="/assets/img/PNG/White_check_pic.png" alt="Check" />
          </div>
          <div>
            <img src="/assets/img/PNG/White_check_pic.png" alt="Check" />
          </div>
          <div>
            <img src="/assets/img/PNG/White_check_pic.png" alt="Check" />
          </div>
        </SpecContainer>
        <SpecContainer style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}>
          <p>BulkSender</p>
          <div>
            <img src="/assets/img/PNG/Yellow_check_pic.png" alt="Check" />
          </div>
          <div>
            <img src="/assets/img/PNG/Yellow_check_pic.png" alt="Check" />
          </div>
          <div>
            <img src="/assets/img/PNG/green_check_pic.png" alt="Check" />
          </div>
        </SpecContainer>
        <SpecContainer style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}>
          <p>MultiSender</p>
          <div>
            <img src="/assets/img/PNG/Yellow_check_pic.png" alt="Check" />
          </div>
          <div>
            <img src="/assets/img/PNG/Yellow_check_pic.png" alt="Check" />
          </div>
          <div>
            <img src="/assets/img/PNG/green_check_pic.png" alt="Check" />
          </div>
        </SpecContainer>
      </CompareRow>
    </CompareContainer>
  );
};

export default Compare;
