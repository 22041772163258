import { useSelector, useDispatch } from "react-redux";
import { deletePayrollRecipient, toggleModal } from "../../redux/actions";
import { useParams } from "react-router-dom";
import Logo from "../home/images/logo.png";
import { useTheme } from "styled-components";

const DeleteRecipient = (props) => {
  let { payrollId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const recipientEmail = useSelector(
    (state) => state.GeneralReducer.recipientemail
  );

  return (
    <div>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Delete Recipient
        </h2>

        <div className="modal-body">
          <div className="text-center">
            <p>Are you sure want to delete this recipient?</p>
          </div>
          <div className="modal-btn delete-action">
            <div className="row">
              <div className="col-6">
                <p
                  data-dismiss="modal"
                  onClick={() =>
                    dispatch(deletePayrollRecipient(recipientEmail, payrollId))
                  }
                  className="btn btn-primary continue-btn"
                >
                  Delete
                </p>
              </div>
              <div className="col-6" onClick={() => dispatch(toggleModal(0))}>
                <p className="btn btn-primary cancel-btn">Cancel</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteRecipient;
