import axios from "axios";
import Web3 from "web3";




export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

//format transaction hash
export const formathash = (hash) => {
  if (hash) {
    let firsthalf = hash.slice(0, 4);
    let secondhalf = hash.slice(-3);
    let formattedhash = firsthalf + "..." + secondhalf;
    return formattedhash;
  }
};

export const formatAddress = (address) => {
  if (!address) return "";
  const start = address.slice(0, 6);
  const end = address.slice(-4);
  return `${start}...${end}`;
};

const fetchTokenBalancesFromCovalent = async (walletAddress, chainId) => {
  const apiKey = "cqt_rQ4RWCMpYwmCgwXpTCG9gdQjyrwX"; // Replace with your Covalent API key
  const url = `https://api.covalenthq.com/v1/${chainId}/address/${walletAddress}/balances_v2/?key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data.error === false) {
      return data.data.items.map((token) => {
        if(!token.native_token){
          return {
            tokenAddress: token.contract_address,
            symbol: token.contract_ticker_symbol,
            decimal: token.contract_decimals,
            balance: (
              token.balance / Math.pow(10, token.contract_decimals)
            ).toFixed(4)
          }
        }
        
      });
    } else {
      throw new Error(data.error_message);
    }
  } catch (error) {
    console.error("Error fetching token balances:", error);
    return null;
  }
};

export default fetchTokenBalancesFromCovalent;

export const isValidAddress = (address) => {
  return Web3.utils.isAddress(address);
};
export const checkDuplicatesAndValidity = async (lines) => {
  let seen = {};
  let errors = [];


  for (let i = 0; i < lines?.length; i++) {
    const line = lines[i];
    let address = line?.address;

  


    if (!isValidAddress(line?.address)) {
      errors.push(`Line ${i + 1}: Invalid address ${address}`);
      continue; // Skip checking duplicates for invalid addresses
    }
    // Check if the amount has more than two decimal places
    if (line?.amount && !/^\d+(\.\d{1,4})?$/.test(line?.amount)) {
      errors.push(`Line ${i + 1}: Invalid amount ${line?.amount}. Amount must have at most four decimal places.`);
      break;
    }

    if (seen[address]) {
      errors.push(
        `Line ${i + 1}: Duplicate address ${address} found on Line ${
          seen[address]
        }`
      );
    } else {
      seen[address] = i + 1; // Store line number where address is seen
    }
  }

  return errors;
};

export const calculateTotalAmount = (dataArray) => {
  return dataArray.reduce((total, item) => {
    // Parse the amount as a float and add it to the total
    return total + parseFloat(item.amount);
  }, 0);
};

export const formatToFourDecimalPlaces = (num) => {
  const roundedNum = Math.round(Number(num) * 10000) / 10000; // Round to 4 decimal places
  return roundedNum.toString();
};




