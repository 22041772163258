import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPayrolls,
  getFormDetails,
  editFormDetails
} from "../../redux/actions";
import { EmptyCard } from "../reusables";
import moment from "moment";
import { Link } from "react-router-dom";

export const ListviewPayrolls = () => {
  const dispatch = useDispatch();
  const allpayrolls = useSelector(state => state.PayrollReducer.allpayrolls);
  const user = useSelector(state => state.persistedStore.user);

  const payrollSearchResult = useSelector(
    state => state.PayrollReducer.payrollSearchResult
  );
  const searchname = useSelector(state => state.GeneralReducer.searchname);
  let payrolls =
    (payrollSearchResult.length !== 0) & (searchname !== "")
      ? payrollSearchResult
      : allpayrolls;

  useEffect(() => {
    user && dispatch(getPayrolls(user.id, 1, 30));
  }, [dispatch]);

  return (
    <div className="row staff-grid-row">
      {payrolls.lemgth === 0 ? (
        <EmptyCard
          message="No Payrolls"
          description="Create payrolls to have them show up here!"
        />
      ) : (
        <table className="table table-striped custom-table datatable">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Created</th>
              <th>Reminder Day</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {payrolls.length !== 0 &&
              payrolls.map(each => {
                return (
                  <tr key={each.id}>
                    <td>
                      <h2 className="table-avatar">
                        <Link to={`/payroll/${each.id}`} className="avatar">
                          <img
                            alt="avatar"
                            src={
                              each.logoUrl
                                ? each.logoUrl
                                : "https://w7.pngwing.com/pngs/607/252/png-transparent-yellow-bank-sack-illustration-emoji-money-bag-money-logo-sticker-desktop-wallpaper.png"
                            }
                          />
                        </Link>
                      </h2>
                    </td>
                    <td>{each.name}</td>
                    <td>
                      {moment(each.createdAt).format("Do MMMM YYYY, h:mm")}
                    </td>
                    <td>
                      { `${each.reminderDay} of every month`  }
                      
                    </td>

                    <td className="text-right">
                      <div className="dropdown dropdown-action">
                        <a
                          href="#"
                          className="action-icon dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="material-icons">more_vert</i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a
                            className="dropdown-item"
                            onClick={() => dispatch(editFormDetails(each))}
                            data-toggle="modal"
                            data-target="#edit_employee"
                          >
                            <i className="fa fa-pencil m-r-5" /> Edit
                          </a>
                          {/* <a
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#set_reminder"
                          >
                            <i className="fa fa-bell m-r-5" /> Set Reminder
                          </a> */}
                          <a
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#delete_employee"
                            onClick={() =>
                              dispatch(
                                getFormDetails({
                                  props: ["payrollId"],
                                  value: each.id
                                })
                              )
                            }
                          >
                            <i className="fa fa-trash-o m-r-5" /> Delete
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};
