import styled from "styled-components/macro";

export const Selector = styled.div`
  max-height: 200px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.lightGray};
  border-style: solid;
  margin-bottom: 20px;
  padding: 20px;
  cursor: pointer;
  border-radius: 30px;
  color: ${({ theme }) => theme.lightGray} !important;
`;
