import React, {useState} from 'react';
import { useDispatch,useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { IoPieChartOutline } from "react-icons/io5";
import { RiFileList3Line } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import WhiteLogo from '../../home/images/white-logo.png';
import './SideNavbar.css'; // Importing CSS from the same directory

import {
  toggleModal
} from "../../../redux/actions";
import { CreatePayroll } from "../../home/createPayroll";
import CustomModal from "../../reusables/modal/customModal";






export const SideNavbar = () => {
  const dispatch = useDispatch();
  const modalPage = useSelector(state => state.GeneralReducer.modalPage);
  const [isChildNavVisible, setIsChildNavVisible] = useState(false);

  const toggleChildNav = () => {
    setIsChildNavVisible(!isChildNavVisible);
  };
  return (

    <div className="sidebard">
      <img  className='img-responsive sidebard-image' src={WhiteLogo} alt="logo" height={40} width={100} />
      <NavLink exact to="/dashboard" activeClassName="active"><IoPieChartOutline className='mr-2' /> Dashboard</NavLink>
      <NavLink to="/payroll" activeClassName="active"><RiFileList3Line className='mr-2' /> Payroll</NavLink>
      <div
        onClick={toggleChildNav}
        className={`parent-link ${isChildNavVisible ? 'active' : ''}`}
        style={{ cursor: 'pointer' }}
      >
        <RiFileList3Line className='mr-2' /> Transactions
      </div>
      {isChildNavVisible && (
        <div className="child-nav">
          {/* Child link remains a NavLink */}
          <NavLink to="/quicksend/transactions/:quicksendId" activeClassName="active" className="child-link">
            Quick send
          </NavLink>
          <NavLink to="/transaction/details" activeClassName="active" className="child-link">
            Payroll
          </NavLink>
          {/* More child links can be added here */}
        </div>
      )}

      <NavLink to="/profile" activeClassName="active" style={{ marginBottom: 51 }}><CgProfile className='mr-2' /> Profile</NavLink>
      

     
    </div>
  )

}