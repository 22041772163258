import axios from "axios";
import config from "../config";
import _const from "../types";
import { addError } from "./error";
import { authHeader } from "../api";
import { successfullAction } from ".";
import {payPayrollRecipients} from "./payusers";
import moment from "moment";
import { toggleModal } from "./generalActions";
import { toast } from 'react-toastify';


//Gets payroll summary
export const getPayrollSummary = userId => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_PAYROLL_SUMMARY,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/Transaction/PayrollSummaryReview?payrollSenderId=${userId}`,
        {
          headers: authHeader()
        }
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_PAYROLL_SUMMARY,
          payload: false
        });
        dispatch({
          type: _const.ALL_PAYROLL_SUMMARY,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_PAYROLL_SUMMARY,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Gets all the payrolls by a user
export const getPayrolls = (userId, page, pageSize, payrollId) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_ALL_PAYROLL,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/Payroll/GetUserPayrolls?payrollUserId=${userId}&page=${page}&pageSize=${pageSize}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_ALL_PAYROLL,
          payload: false
        });

        dispatch({
          type: _const.ALL_PAYROLL,
          payload: res.data.data
        });
        if (payrollId) {
          dispatch(getOnePayroll(payrollId, res.data.data.records));
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_ALL_PAYROLL,
          payload: false
        });
        dispatch(addError(err));
        dispatch(toggleModal(0));
      });
  };
};

//Create payroll
export const createPayroll = (
  walletAddress,
  name,
  currencyId,
  identifier,
  userId,
  networkId,
  ReminderDay
) => {
  var bodyFormData = new FormData();
  bodyFormData.append("Name", name);
  bodyFormData.append("WalletAddress", walletAddress);
  bodyFormData.append("NetworkChain", networkId);
  bodyFormData.append("CurrencyId", currencyId);
  bodyFormData.append("Company_logo", identifier);
  bodyFormData.append("imageTag", name);
  bodyFormData.append("ReminderDay", ReminderDay);
  return dispatch => {
    if (name === "") {
      dispatch({
        type: _const.CREATING_PAYROLL_ERR,
        payload: "Payroll name is required"
      });
    } else if (currencyId === "") {
      dispatch({
        type: _const.CREATING_PAYROLL_ERR,
        payload: "Please select Currency"
      });
    } else {
      dispatch({
        type: _const.CREATING_PAYROLL_ERR,
        payload: ""
      });
      dispatch({
        type: _const.CREATING_PAYROLL,
        payload: true
      });
      axios
        .post(config.apiUrl + `/Payroll/AddPayroll`, bodyFormData)
        .then(res => {
          dispatch({
            type: _const.CREATING_PAYROLL,
            payload: false
          });
          dispatch(toggleModal(10));
          dispatch(getPayrolls(userId, 1, 30, ""));
          dispatch(getPayrollSummary(userId));
          dispatch({
            type: _const.PAYROLL_CREATED,
            payload: res
          });
          window.scrollTo({
            top: 800,
            behavior: "auto"
          });
        })
        .catch(err => {
          dispatch({
            type: _const.CREATING_PAYROLL,
            payload: false
          });
          dispatch({
            type: _const.CREATING_PAYROLL_ERR,
            payload: err.response.data.data
          });
        });
    }
  };
};

//Delete payroll
export const deleteOnePayroll = (payrollId, userId) => {
  return dispatch => {
    axios
      .get(config.apiUrl + `/Payroll/RemovePayroll?payrollId=${payrollId}`)
      .then(res => {
        dispatch(successfullAction("removed"));
        dispatch(getPayrolls(userId, 1, 30));
        dispatch(getPayrollSummary(userId));
      })
      .catch(err => {
        dispatch(addError(err));
      });
  };
};

//Get Payroll Recipients
export const getPayrollRecipients = (
  payrollId,
  page,
  pageSize,
  tagName,
  dateFrom,
  dateTo
) => {
  let dateFromStr = dateFrom === "" ? "" : moment(dateFrom).format();
  let dateToStr = dateTo === "" ? "" : moment(dateTo).format();
  return dispatch => {
    dispatch({
      type: _const.GETTING_PAYROLL_RECIPIENTS,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/Payroll/Recipient?payrollId=${payrollId}&page=${page}&pageSize=${pageSize}&tagName=${tagName}&dateFromStr=${dateFromStr}&dateToStr=${dateToStr}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_PAYROLL_RECIPIENTS,
          payload: false
        });
        dispatch({
          type: _const.ALL_PAYROLL_RECIPIENTS,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_PAYROLL_RECIPIENTS,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Get Payroll Recipients
export const getOnePayroll = (payrollId, allpayrolls) => {
  let payroll = allpayrolls.find(payroll => payroll.id === payrollId);
  return dispatch => {
    dispatch({
      type: _const.SELECTED_PAYROLL_DETAILS,
      payload: payroll
    });
  };
};

export const createMultiplePayrollRecipient = (payrollid, payload) => {
  let body = {
    payrollRecipients: payload,
    payrollId: payrollid
  };

  return dispatch => {
    dispatch({
      type: _const.CREATING_PAYROLL_RECIPIENT,
      payload: true
    });
    axios
      .post(config.apiUrl + `/Payroll/Recipient`, body)
      .then(res => {
        dispatch(successfullAction("created recepient"));
        dispatch(getPayrollRecipients(payrollid, 1, 30, "", "", ""));
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_PAYROLL_RECIPIENT,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Create payroll recipient
export const createPayrollRecipient = (
  payrollid,
  walletAddress,
  recipientEmail,
  amount,
  netPay,
  totalBonuses,
  totalDeductions
) => {

  let body = {
    payrollRecipients: [
      {
        walletAddress: walletAddress,
        amount: amount,
        emailAddress: recipientEmail,
        netPay:netPay,
        totalBonuses: totalBonuses,
        totalDeductions: totalDeductions
      }
    ],
    payrollId: payrollid
  };
console.log(body)
  return dispatch => {
    dispatch({
      type: _const.CREATING_PAYROLL_RECIPIENT,
      payload: true
    });
    dispatch({
      type: _const.CREATING_PAYROLL_RECIPIENT_ERR,
      payload: ""
    });
    axios
      .post(config.apiUrl + `/Payroll/Recipient`, body)
      .then(res => {
        dispatch(successfullAction("created recepient"));
        dispatch(getPayrollRecipients(payrollid, 1, 30, "", "", ""));
        dispatch(toggleModal(0));
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_PAYROLL_RECIPIENT,
          payload: false
        });
        dispatch({
          type: _const.CREATING_PAYROLL_RECIPIENT_ERR,
          payload: err.response.data.data
        });
        dispatch(addError(err));
      });
  };
};

//Delete payroll recipient
export const deletePayrollRecipient = (recipientEmail, payrollId) => {
  return dispatch => {
    dispatch({
      type: _const.CREATING_PAYROLL_RECIPIENT,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/Payroll/RemovePayrollRecipient?payrollId=${payrollId}&email=${recipientEmail}`,
        { headers: authHeader() }
      )
      .then(res => {
        dispatch(successfullAction("remove recepient"));
        dispatch(getPayrollRecipients(payrollId, 1, 30, "", "", ""));
        dispatch(toggleModal(0));
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_PAYROLL_RECIPIENT,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Edit payroll
export const editPayroll = (
  walletAddress,
  name,
  currencyId,
  payrollId,
  userId,
  image
) => {
  let body = {
    payrollId: payrollId,
    walletAddress: walletAddress,
    name: name,
    networkChain: 1,
    currencyId: currencyId,
    Company_logo: image
  };

  // var bodyFormData = new FormData();
  // bodyFormData.append("Name", name);
  // bodyFormData.append("WalletAddress", walletAddress);
  // bodyFormData.append("NetworkChain", 1);
  // bodyFormData.append("CurrencyId", currencyId);
  // bodyFormData.append("Company_logo", image);
  // bodyFormData.append("imageTag", name);

  return dispatch => {
    axios
      .post(config.apiUrl + `/Payroll/UpdatePayroll`, body)
      .then(res => {
        dispatch(successfullAction("edited payroll"));
        dispatch(getPayrolls(userId, 1, 30));
        dispatch(getPayrollSummary(userId));
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_PAYROLL,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//initialte payment for payroll
export const initiatePayrollPayment = (
  payrollId,
  payrollreciinetsAmounts,
  payrollrecipinetsAddresses,
  currrencyAddress,
  userWalletAddress,
  blockchain
) => {
  return dispatch => {
    dispatch({
      type: _const.APPROVING_PAYMENT,
      payload: true
    });
    axios
      .post(config.apiUrl + `/Payroll/PayrollRequest?payrollId=${payrollId}`, {
        headers: authHeader()
      })
      .then(res => {
        console.log(res)
        dispatch({
          type: _const.APPROVING_PAYMENT,
          payload: false
        });
        console.log(blockchain, "ajkhdjsdjadjahj")

        dispatch({
          type: _const.INITIATING_PAYMENT_SUCCESS,
          payload: res.data.data
        });
        dispatch(setRequestStatus(res.data.data.payrollRequestId, 0));
        dispatch(
          payPayrollRecipients(
            payrollreciinetsAmounts,
            payrollrecipinetsAddresses,
            res.data.data.batchId,
            currrencyAddress,
            userWalletAddress,
            res.data.data.payrollRequestId,
            blockchain
          )
        );
      })
      .catch(err => {
        dispatch({
          type: _const.APPROVING_PAYMENT,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//initialte payment for payroll
export const updatePayment = (
  userid,
  transactionHash,
  payrollRequestId,
  blockchain
) => {
  return dispatch => {
  
    axios
      .put(config.apiUrl + `/QuickSend/update?userid=${userid}&transactionHash=${transactionHash}&payrollRequestId=${payrollRequestId}&blockchain=${blockchain}`, {
        headers: authHeader()
      })
      .then(res => {
        console.log(res)
        toast("Payroll request updated successfully");
        
      })
      .catch(err => {
        dispatch({
          type: _const.APPROVING_PAYMENT,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Set Reminder
export const setReminder = (payrollId, dateTime) => {
  var dateObj = new Date(dateTime);
  var momentObj = moment(dateObj);
  var momentString = momentObj.format("YYYY-MM-DD");
  return dispatch => {
    dispatch({
      type: _const.SETTING_REMINDER,
      payload: true
    });
    axios
      .post(
        config.apiUrl +
          `/Payroll/Reminder?payrollId=${payrollId}&date=${momentString}`,
        {
          headers: authHeader()
        }
      )
      .then(res => {
        dispatch({
          type: _const.SETTING_REMINDER,
          payload: false
        });
        dispatch({
          type: _const.SETTING_REMINDER_SUCCESS,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.SETTING_REMINDER,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Set Payroll request status
export const setRequestStatus = (payrollId, status) => {
  return dispatch => {
    dispatch({
      type: _const.SETTING_RESET_STATUS,
      payload: true
    });
    axios
      .post(
        config.apiUrl +
          `/Payroll/SetRequestStatus?payrollRequestId=${payrollId}&status=${status}`,
        {
          headers: authHeader()
        }
      )
      .then(res => {
        dispatch({
          type: _const.SETTING_RESET_STATUS,
          payload: false
        });
        dispatch({
          type: _const.SETTING_RESET_STATUS_SUCCESS,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.SETTING_RESET_STATUS,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};
