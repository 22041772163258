import React, { useEffect } from "react";
import { SideNavbar } from "../reusables/nav/SideNavbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  storeAddress,
  searchPayroll,
  getPayrollSummary,
  getPayrolls,
  getFormDetails,
  editFormDetails,
  toggleModal,
} from "../../redux/actions";
import { EditPayroll } from "./editPayroll";
import moment from "moment";
import { ListviewPayrolls } from "./listviewPayrolls";
import { CreatePayroll } from "./createPayroll";

import { AllPayroll } from "./allPayroll";
import CustomModal from "../reusables/modal/customModal";
import SuccessModal from "../reusables/modal/successModal";
import { CreateCurrency } from "./createCurrency";
import CreateRecipient from "../payroll/createRecipient";
import Loader from "../reusables/loader";
import QuickSendApprovalModal from "../reusables/modal/quicksendapprovalModal";
import modalConstants from "../reusables/modal/modalConstants";

import { PayrollCard } from "../reusables";
import { useTheme } from "styled-components";

export const NewPayroll = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.persistedStore.user);
  const blockchain = useSelector((state) => state.persistedStore.blockchain);
  const allpayrolls = useSelector((state) => state.PayrollReducer.allpayrolls);
  const payrolldetails = useSelector(
    (state) => state.PayrollReducer.payrolldetails
  );
  const searchname = useSelector((state) => state.GeneralReducer.searchname);
  const modalPage = useSelector((state) => state.GeneralReducer.modalPage);
  const listview = useSelector((state) => state.GeneralReducer.listview);
  const madeAction = useSelector((state) => state.GeneralReducer.successfull);
  const curr = useSelector((state) => state.GeneralReducer.selectedCurrency);
  const chainChangedHandler = async () => {
    dispatch(storeAddress());
  };
  const transactionAmt = useSelector(
    (state) => state.PayrollReducer.transactionAmt
  );

  useEffect(() => {
    auth && dispatch(getPayrollSummary(auth.id));
    dispatch(getPayrolls(auth.id, 1, 30));
  }, [madeAction]);

  const viewReceipients = (id) => {
    dispatch(
      getFormDetails({
        props: ["payrollId"],
        value: id,
      })
    );
    navigate("/payroll/receipients", { state: { payrollId: id } });
  };

  window.ethereum.on("chainChanged", chainChangedHandler);

  function generateGreetings() {
    var currentHour = moment().format("HH");

    if (currentHour >= 3 && currentHour < 12) {
      return "Rise and Shine, Time To Get That Bread ☕";
    } else if (currentHour >= 12 && currentHour < 15) {
      return "Scorching Weather Ain’t it, Still Gotta Stay On The Grind 🪓";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "Good Evening, How was your day? 🌇";
    } else if (currentHour >= 20 || currentHour < 3) {
      return "Night Owl 🦉, I see you!";
    } else {
      return "Hello";
    }
  }
  const payrollSearchResult = useSelector(
    (state) => state.PayrollReducer.payrollSearchResult
  );
  let payrolls =
    (payrollSearchResult.length !== 0) & (searchname !== "")
      ? payrollSearchResult
      : allpayrolls;

  return (
    <>
      <SideNavbar />

      <div className="sidebard-content">
        {/* Page Header */}
        <div
          style={{ backgroundColor: theme.white, padding: "1%" }}
          className="page-header"
        >
          <div className="row align-items-center">
            <div className="col">
              <h3 className="dashboard-name">Hi, Anthony</h3>
              <ul className="breadcrumb">
                <li className="dashboard-subname">{generateGreetings()}</li>
              </ul>
            </div>
            <div className="col-auto float-right ml-auto">
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  padding: "2%",
                  backgroundColor: theme.gray7,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            {modalPage === modalConstants.PayrollCreated ? (
              <SuccessModal
                icon="/assets/img/success.svg"
                msg="Payroll created successsfuly!"
              />
            ) : (
              ""
            )}
            {modalPage === modalConstants.CurrencyAdded ? (
              <SuccessModal
                icon="/assets/img/success.svg"
                msg="Currency has been added to this blockchain successfully!"
              />
            ) : (
              ""
            )}
            {modalPage === modalConstants.CreatePayroll ? (
              <CustomModal>
                {" "}
                <CreatePayroll />{" "}
              </CustomModal>
            ) : (
              ""
            )}

            {modalPage === modalConstants.CreateCurrency ? (
              <CustomModal>
                <CreateCurrency />
              </CustomModal>
            ) : (
              ""
            )}
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div
                  className="col-sm-12"
                  style={{ flexDirection: "row", flex: 1 }}
                >
                  <div className="float-left">
                    <h3 className="landing-title">{"Payroll"}</h3>
                    <p className="landing-subHead">List of all your payrolls</p>
                  </div>

                  <div className="col-auto float-right ml-auto">
                    <div
                      onClick={() => dispatch(toggleModal(1))}
                      className="landing-btn"
                    >
                      <i className="fa fa-plus mr-3" /> Create Payroll
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* /Page Header */}
            {/* Search Filter */}
            <div className="row filter-row">
              <div className="col-sm-6 col-md-3">
                <div className="form-group form-focus">
                  <input
                    type="text"
                    value={searchname}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["searchname"],
                          value: e.target.value,
                        })
                      )
                    }
                    className="form-control"
                  />
                  <label className="focus-label">Payroll Name</label>
                </div>
              </div>
              {/* <div className="col-sm-6 col-md-3">
                                <div
                                    onClick={() => dispatch(searchPayroll(searchname, allpayrolls))}
                                    className="btn btn-success btn-block"
                                >
                                    {" "}
                                    Search{" "}
                                </div>
                            </div> */}
              <div className="col-sm-12 col-md-9">
                <div className="view-icons">
                  <div
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["listview"],
                          value: false,
                        })
                      )
                    }
                    className={
                      listview
                        ? "grid-view btn btn-link"
                        : "grid-view btn btn-link active"
                    }
                  >
                    <i className="fa fa-th" />
                  </div>
                  <div
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["listview"],
                          value: true,
                        })
                      )
                    }
                    className={
                      listview
                        ? "list-view btn btn-link active"
                        : "list-view btn btn-link"
                    }
                  >
                    <i className="fa fa-bars" />
                  </div>
                </div>
              </div>
            </div>
            {/* Search Filter */}

            {listview ? (
              <ListviewPayrolls />
            ) : (
              <div className="row staff-grid-row">
                {payrolls.map((each, i) => {
                  console.log(each);
                  return (
                    <PayrollCard
                      key={each.id}
                      id={each.id}
                      icon={
                        each.logoUrl
                          ? each.logoUrl
                          : "https://w7.pngwing.com/pngs/607/252/png-transparent-yellow-bank-sack-illustration-emoji-money-bag-money-logo-sticker-desktop-wallpaper.png"
                      }
                      currency={each.currency.symbol}
                      name={each.name}
                      createdAt={each.createdAt}
                      edit={() => dispatch(editFormDetails(each))}
                      reminder={() =>
                        dispatch(
                          getFormDetails({
                            props: ["payrollId"],
                            value: each.id,
                          })
                        )
                      }
                      receipients={() => viewReceipients(each.id)}
                      remove={() =>
                        dispatch(
                          getFormDetails({
                            props: ["payrollId"],
                            value: each.id,
                          })
                        )
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
