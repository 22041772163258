import excelToJson from "../../excelToJson";
import { useDispatch } from "react-redux";
import {
  excelRecipient,
  madeImport,
  quickpayRecipient,
  quickpayToggle,
  toggleModal,
  getFormDetails
} from "../../redux/actions";
import { Selector } from "./excelstyle";
import swal from "sweetalert";

export const UploadFile = ({ quick }) => {
  const dispatch = useDispatch();

  const convertExcelToObject = file => {
    const reader = new FileReader();
    reader.onload = function(event) {
      const data = new Uint8Array(event.target.result);

      let result = excelToJson({ source: data });
      let d = result.Sheet1[0];

      if (!result.Sheet1) {
        swal({
          title: "Import Failed!",
          text:
            "Wrong file arrangement. Download template for expected column arrangement",
          icon: "warning",
          dangerMode: true
        });
      } else if (!d.A) {
        swal({
          title: "Import Failed!",
          text:
            "Wrong file arrangement. Download template for expected column arrangement",
          icon: "warning",
          dangerMode: true
        });
      } else if (
        d.A.toLowerCase()
          .split(" ")
          .join("") !== "walletaddress" ||
        d.B.toLowerCase()
          .split(" ")
          .join("") !== "emailaddress" ||
        d.C.toLowerCase()
          .split(" ")
          .join("") !== "amount"
      ) {
        swal({
          title: "Import Failed!",
          text:
            "Wrong file arrangement. Download template for expected column arrangement",
          icon: "warning",
          dangerMode: true
        });
        dispatch(toggleModal(0));
      } else {
        result.Sheet1.shift() && quick
          ? dispatch(quickpayRecipient(result.Sheet1))
          : dispatch(excelRecipient(result.Sheet1));
        dispatch(toggleModal(0));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const dropHandler = event => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    convertExcelToObject(file);
  };

  const uploadFileHandler = event => {
    const file = event.target.files[0];
    dispatch(
      getFormDetails({
        props: ["quickpayrecipientFile"],
        value: file
      })
    );
    file && convertExcelToObject(file);
  };

  const dragOverHandler = event => {
    event.preventDefault();
  };

  return (
    <Selector
      onClick={() => {
        quick && dispatch(quickpayToggle(true));
        quick && dispatch(madeImport(true));
      }}
    >
      {" "}
      <i
        className="fa fa-upload fa-2x "
        style={{ float: "left", marginLeft: "5%" }}
      />
      <div className="">
        <div>
          <label
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
            htmlFor="file"
          >
            Upload Excel Sheet
            <input
              onChange={uploadFileHandler}
              id="file"
              type="file"
              accept=".xlsx, .xls, .csv"
              className="inputfile"
            />
            {/* <div>or drop excel files here</div> */}
          </label>
        </div>
      </div>
    </Selector>
  );
};
