import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPayrollRecipient, getFormDetails } from "../../redux/actions";
import Logo from "../home/images/logo.png";
import Loader from "../reusables/loader";
import { useTheme } from "styled-components";

const CreateRecipient = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const payrollId = useSelector((state) => state.GeneralReducer.payrollId);
  // const recipientname = useSelector(
  //   state => state.GeneralReducer.recipientname
  // );

  const recipientemail = useSelector(
    (state) => state.GeneralReducer.recipientemail
  );
  const recipientwalletAddress = useSelector(
    (state) => state.GeneralReducer.recipientwalletAddress
  );
  const amount = useSelector((state) => state.GeneralReducer.amount);
  const totalBonuses = useSelector(
    (state) => state.GeneralReducer.totalBonuses
  );
  const totalDeductions = useSelector(
    (state) => state.GeneralReducer.totalDeductions
  );
  const netPay = useSelector((state) => state.GeneralReducer.netPay);
  const creatingRecipientErr = useSelector(
    (state) => state.PayrollReducer.creatingRecipientErr
  );
  const creatingPayrollRecepient = useSelector(
    (state) => state.LoaderReducer.creatingPayrollRecepient
  );

  return (
    <div>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "10%",
          paddingLeft: "10%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Add Payroll Recipient
        </h2>

        <div className="modal-body">
          <form>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Recipient's wallet address</label>
                  <input
                    className="form-control"
                    value={recipientwalletAddress}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["recipientwalletAddress"],
                          value: e.target.value,
                        })
                      )
                    }
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Amount</label>
                  <input
                    className="form-control"
                    type="text"
                    value={amount}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["amount"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Bonuses</label>
                  <input
                    className="form-control"
                    type="text"
                    value={totalBonuses}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["totalBonuses"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Recipient's email address</label>
                  <input
                    className="form-control"
                    value={recipientemail}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["recipientemail"],
                          value: e.target.value,
                        })
                      )
                    }
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Deductions</label>
                  <input
                    className="form-control"
                    type="text"
                    value={totalDeductions}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["totalDeductions"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Net Pay</label>
                  <input
                    className="form-control"
                    value={netPay}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["netPay"],
                          value: e.target.value,
                        })
                      )
                    }
                    type="text"
                  />
                </div>
              </div>
            </div>
            {creatingRecipientErr ? (
              <div className="err-msg">
                <p className="mb-0"> {creatingRecipientErr}</p>
              </div>
            ) : (
              ""
            )}
            <div className="submit-section">
              <div
                // data-dismiss="modal"
                onClick={() =>
                  dispatch(
                    createPayrollRecipient(
                      payrollId,
                      recipientwalletAddress,
                      recipientemail,
                      amount,
                      netPay,
                      totalBonuses,
                      totalDeductions
                    )
                  )
                }
                className="btn btn-success btn-block"
              >
                {creatingPayrollRecepient ? <Loader /> : "Submit"}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateRecipient;
