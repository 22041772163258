import web3 from "web3";
/**
 * This function is used to create contracts web3 objects used for calling contract methods.
 * @param abi
 * @param contractAddress
 */

async function createContract(abi, contractAddress) {
  let WEB3 = new web3(window.ethereum);
  console.log(window.ethereum, contractAddress)
  let CONTRACT = new WEB3.eth.Contract(abi, contractAddress);
  return CONTRACT;
}

export default createContract;
