import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  madeImport,
  excelRecipient,
  toggleModal,
  quickpayToggle,
  quickpayRecipient,
  getCurrenciesAction,
  getAllQuickSendRecepients,
  getFormDetails,
  setReminder
} from "../../redux/actions";
import AddExcelRecipient from "../payroll/addExcelRecipient";
import PayrollTransactionAction from "../../redux/actions/payrolltransactionActions";

const QuickSendRecipients = ({ data }) => {
  const dispatch = useDispatch();

  //Temp state settings
  const [currencyError, setErr] = useState("");

  const recipients = useSelector(
    state => state.QuickSendReducer.quickpayrecipients
  );
  const quickPayrecipientsAddresses = useSelector(
    state => state.QuickSendReducer.quickPayrecipientsAddresses
  );
  const quickPayrecipientsAmounts = useSelector(
    state => state.QuickSendReducer.quickPayrecipientsAmounts
  );
  const currency = useSelector(state => state.GeneralReducer.currency);
  const curr = useSelector(state => state.GeneralReducer.selectedCurrency);
  const user = useSelector(state => state.persistedStore);

  const currencyCheck = value => {
    let s = currency.records.find(c => c.id === value);
    setErr("");
    dispatch(
      getFormDetails({
        props: ["selectedCurrency"],
        value: s
      })
    );
  };

  const handlePay = () => {
    if (curr.id) {
      dispatch(
        PayrollTransactionAction(
          quickPayrecipientsAmounts,
          quickPayrecipientsAddresses
        )
      );
      dispatch(toggleModal(40));
      setErr("");
    } else {
      setErr("*Please select currency you would like to pay in");
    }
  };

  useEffect(() => {
    dispatch(getCurrenciesAction(user.blockchain));
    currency &&
      currency.records &&
      currency.records.length > 0 &&
      data &&
      console.log(data, "here");
    dispatch(getAllQuickSendRecepients(data));
  }, []);

  function arrayRemove(value) {
    const b = recipients.filter(function(ele) {
      return ele.B != value.B;
    });
    // setRecipient(b);
    if (b.length === 0) {
      dispatch(quickpayRecipient([]));
    }
  }

  // function payAll() {
  //   const result = recipients.map(item => {
  //     let set = {};
  //     let structure = { A: "walletAddress", C: "amount", B: "emailAddress" };
  //     for (let each in structure) {
  //       if (item[each]) {
  //         set = { ...set, [structure[each]]: item[each] };
  //       }
  //     }
  //     return set;
  //   });
  //   dispatch(madeImport(false));
  //   dispatch(quickpayToggle(false));
  //   dispatch(quickpayRecipient([]));
  // }
  return (
    <div className="row mb-5 mt-2">
      <div className="col-md-12">
        <div className="table-responsive">
          <p className="ml-3">Excel Import : </p>

          <div className="col-auto float-right ml-auto mb-2">
            <a
              href="#"
              className="btn btn-success btn-block"
              onClick={() => handlePay()}
            >
              <i className="fa fa-money" /> Pay All Imports
            </a>
          </div>

          <div className="col-sm-4 mb-2">
            <label  className="col-form-label">
              Quick Pay Currency <span key={0} className="text-danger">*</span>
            </label>

            <select
              className="form-control"
              onChange={e => {
                currencyCheck(e.target.value);
              }}
              required
            >
              <option name="city" value="" key={1}>
                Choose Currency
              </option>
              {currency &&
                currency.records &&
                currency.records.map(c => (
                  <>
                    <option name="currency" value={c.id} key={c.id} id={c.id}>
                      {c.name}
                    </option>
                  </>
                ))}
            </select>
          </div>
          {currencyError ? (
            <div className="col-sm-6 mb-2">
              <div className="err-msg mb-3">
                <p className="mb-0"> {currencyError}</p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-auto float-right ml-auto mb-2">
            <a
              href="#"
              className="btn btn-success btn-block"
              onClick={() => {
                dispatch(madeImport(false));
                dispatch(quickpayToggle(false));
                dispatch(quickpayRecipient([]));
              }}
            >
              <i className="fa fa-trash" /> Remove All Imports
            </a>
          </div>
          {/* <div className="col-auto float-right ml-auto mb-2">
            <a
              href="#"
              className="btn btn-success btn-block"
              data-toggle="modal"
              data-target="#add_recipient"
            >
              <i className="fa fa-plus" /> Add Recipient
            </a>
          </div> */}
          <table className="table table-striped custom-table datatable">
            <thead>
              <tr>
                <th className="none">Address</th>
                <th>Email</th>
                <th>Salary</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {recipients.length !== 0 &&
                recipients.map((each, i) => {
                  return (
                    <tr id={i} key={i}>
                      <td className="none">
                        <h2 className="table-avatar">
                          <p className="avatar">
                            <img
                              alt="avatar"
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png"
                            />
                          </p>
                          <p>
                            <span>{each.A}</span>
                          </p>
                        </h2>
                      </td>
                      <td>{each.B ? "--" : each.B}</td>
                      <td>{each.C}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary mr-3"
                          onClick={() => arrayRemove(each)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {/* <AddExcelRecipient
            addRecipient={value => setRecipient(recipients.concat(value))}
          /> */}
        </div>
      </div>
    </div>
  );
};
export default QuickSendRecipients;
