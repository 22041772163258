import React, { useEffect, useState } from "react";
import { SideNavbar } from "../reusables/nav/SideNavbar";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { EmptyCard } from "../reusables";
import {
  copyToClipboard,
  getQuickSendTransactionHistory,
} from "../../redux/actions";
import Loader from "../reusables/loader";
import { formathash } from "../../methods/helper";
import { useTheme } from "styled-components";

const QuickSendRecipients = () => {
  const { quicksendId } = useParams();
  const [recipients, setRecipients] = useState([]);
  const quickSendTransaction = useSelector(
    (state) => state.ReportReducer.quickSendTransaction
  );
  const theme = useTheme();

  useEffect(() => {
    // Assuming quickSendTransaction is an array of transactions
    const foundTransaction = quickSendTransaction.find(
      (p) => p.quickSendId === quicksendId
    );
    if (foundTransaction && foundTransaction.quickSendRecipientDetails) {
      setRecipients(foundTransaction.quickSendRecipientDetails);
    }
  }, [quickSendTransaction, quicksendId]);

  return (
    <>
      <SideNavbar />
      {/* Page Wrapper */}
      <div>
        {/* Page Content */}
        <div className="sidebard-content">
          {/* Page Header */}
          <div
            style={{ backgroundColor: theme.white, padding: "1%" }}
            className="page-header"
          >
            <div className="row align-items-center">
              <div className="col">
                <h3 className="dashboard-name">Hi</h3>
                <ul className="breadcrumb">
                  <li className="dashboard-subname">
                    How is your day going?😎
                  </li>
                </ul>
              </div>
              <div className="col-auto float-right ml-auto">
                <div
                  style={{
                    height: 50,
                    width: 50,
                    borderRadius: 25,
                    padding: "2%",
                    backgroundColor: theme.gray7,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table custom-table mb-0 datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Wallet Address</th>
                        <th>Email</th>
                        <th className="text-center">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recipients.map((eachReport, i) => {
                        return (
                          <tr id={eachReport.id} key={i + 1}>
                            <td>{i + 1}</td>
                            <td>
                              {eachReport.createdAt
                                ? moment(eachReport.createdAt).format(
                                    "D MMM YYYY, h:mm:ss a"
                                  )
                                : "--"}
                            </td>
                            <td>{formathash(eachReport.walletAddress)}</td>
                            <td>{eachReport.email}</td>
                            <td className="text-center">{eachReport.amount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickSendRecipients;
