import React from "react";
import styles from "./style.module.css"; // Import custom CSS for styling
import { useTheme } from "styled-components";

const CustomToggleSwitch = ({ isToggled, setIsToggled }) => {
  const theme = useTheme();

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className={styles.toggle_container}>
      <label className={styles.switch}>
        <input type="checkbox" checked={isToggled} onChange={handleToggle} />
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
      <span className={styles.label} style={{ color: theme.black }}>
        Use Native Token
      </span>
    </div>
  );
};

export default CustomToggleSwitch;
