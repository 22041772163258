import axios from "axios";
import config from "../config";
import _const from "../types";
import { addError } from "./error";
import { authHeader } from "../api";
import {payPayrollRecipients} from "./payusers";
import { setRequestStatus } from "./index";

export const getAllQuickSendRecepients = data => {
  return dispatch => {
    dispatch({
      type: _const.ALL_QUICKPAY_RECIPIENTS,
      payload: data
    });
  };
};

//initialte payment for payroll
export const initiateQuickSendPayment = (
  blockchain,
  currencyId,
  currencyAddress,
  walletAddress,
  file,
  quickPayrecipientsAddresses,
  quickPayrecipientsAmounts
) => {
  return dispatch => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("blockchain", blockchain);
    dispatch({
      type: _const.INITIATING_QUICKSEND_PAYMENT,
      payload: true
    });
    axios
      .post(
        config.apiUrl +
          `/QuickSend/Initiate?blockchain=${blockchain}&currencyId=${currencyId}&walletAddress=${walletAddress}`,
        bodyFormData,
        {
          headers: authHeader()
        }
      )
      .then(res => {
        dispatch({
          type: _const.INITIATING_QUICKSEND_PAYMENT,
          payload: false
        });
        dispatch(setRequestStatus(res.data.data.payrollRequestId, 0));
        dispatch(
          payPayrollRecipients(
            quickPayrecipientsAmounts,
            quickPayrecipientsAddresses,
            res.data.data.batchId,
            currencyAddress,
            walletAddress,
            res.data.data.payrollRequestId,
            blockchain
          )
        );
      })
      .catch(err => {
        dispatch({
          type: _const.INITIATING_QUICKSEND_PAYMENT,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};
