import _const from "../types";

const initialState = {
  user: {},
  isAuthenticated: false,
  address: null,
  networkId: null,
  signature: null,
  isTestnet: true,
  isConnected: false,
  blockchain: null,
  network: {}
};

const normalizeNetwork = (network) => {
  const normalizedNetwork = {
    ...network,
    chainId: Number(network.chainId),
    nativeCurrency: {
      ...network.nativeCurrency,
      decimals: Number(network.nativeCurrency.decimals),
    },
    rpcUrls: network.rpcUrls,
    blockExplorerUrls: network.blockExplorerUrls,
    FORWARDER_ADDRESS: network.FORWARDER_ADDRESS,
    FORWARDER_PROXY_ADDRESS: network.FORWARDER_PROXY_ADDRESS,
  };


  return normalizedNetwork;
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.STORE_ADDRESS:
      return {
        ...state,
        address: action.address,
        networkId: action.networkId,
        signature: action.signature,
        isConnected: true,
        network: normalizeNetwork(action.network),
        blockchain: Number(action.blockchain),
        isTestnet: action.isTestnet
      };
    case _const.GET_USER:
      return {
        ...state,
        user: action.payload
      };
    case _const.REMOVE_ADDRESS:
      return {
        ...state,
        isAuthenticated: false,
        address: null,
        networkId: null,
        signature: null
      };
    case _const.SIGNATURE:
      return {
        ...state,
        signature: action.signature
      };
    case _const.RESET_USER:
      return initialState;
    case _const.CONNECT_APP_FAIL:
      return {
        ...state,
        isConnected: false
      };
    default:
      return state;
  }
};

export default authReducer;
