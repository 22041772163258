import _const from "../types";

const initialState = {
  creatingPayroll: false,
  approvingPayment: false,
  gettingPayroll: false,
  gettingpayrollRecipients: false,
  gettingTransaction: false,
  settingReminder: false,
  authenticatingUser: false,
  creatingCurrency: false,
  creatingPayrollRecepient: false
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.CREATING_PAYROLL:
      return {
        ...state,
        creatingPayroll: action.payload
      };

    case _const.GETTING_PAYROLL_RECIPIENTS:
      return { ...state, gettingpayrollRecipients: action.payload };

    case _const.APPROVING_PAYMENT:
      return { ...state, approvingPayment: action.payload };

    case _const.GETTING_ALL_PAYROLL:
      return { ...state, gettingPayroll: action.payload };

    case _const.GETTING_TRANSACTION_HISTORY:
      return { ...state, gettingTransaction: action.payload };

    case _const.SETTING_REMINDER:
      return { ...state, settingReminder: action.payload };

    case _const.CREATING_CURRENCY:
      return { ...state, creatingCurrency: action.payload };

    case _const.AUTHENTICATING_USER:
      return {
        ...state,
        authenticatingUser: action.payload
      };

    case _const.CREATING_PAYROLL_RECIPIENT:
      return { ...state, creatingPayrollRecepient: action.payload };

    default:
      return state;
  }
};

export default LoaderReducer;
