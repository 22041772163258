import { CenterCard } from "./style";

export const EmptyCard = ({ message, description }) => {
  return (
    <div className="col-md-8 col-sm-8 col-12 col-lg-8 col-xl-4 offset-lg-2 offset-xl-4 mt-5">
      <div className="profile-widget emptyccardheight">
        <div className="profile-img mb-5 mt-2">
          <div className="avatar">
            <img
              src="https://www.clipartmax.com/png/full/26-262353_folder-blank-icon-minecraft-file-icon.png"
              alt=""
            />
          </div>
        </div>
        <h4 className="user-name m-t-10 mb-0 text-ellipsis">{message}</h4>
        <div className="small text-muted">{description}</div>
      </div>
    </div>
  );
};
