// customTheme.js
import * as monaco from "monaco-editor";

export const defineCustomTheme = (themeBg) => {
  monaco.editor.defineTheme("custom-theme", {
    base: "vs-dark", // can also be 'vs-dark' or 'hc-black'
    inherit: false, // can also be false to completely replace the base theme
    rules: [],
    colors: {
      "editor.background": themeBg, // Set the background color
      "editorLineNumber.foreground": themeBg, // Line numbers color
      "editorGutter.background": themeBg, // Background color of the gutter (line numbers)
    },
  });

  return true;
};
