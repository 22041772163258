import React, { useEffect, useState } from "react";
import { Container, Navbar, NavItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal, resetUser, connectWallet } from "../../redux/actions";
import { MultisendPageContentWrapper } from "./styles";
import StepperComponent from "../reusables/Steppers";
import ProvideForm from "./MultisendForms/FormOne";
import ApproveForm from "./MultisendForms/FormTwo";
import { formatAddress } from "../../methods/helper";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { persistor } from "./../../redux/index";
import SuccessPage from "./MultisendForms/SuccessPage";
import { NavItems, ThemeToggleContainer } from "../reusables/Navs/styles";
import { IoMoonOutline } from "react-icons/io5";
import { BsSun } from "react-icons/bs";
import { useTheme } from "styled-components";
import modalConstants from "../reusables/modal/modalConstants";
import SelectNetwork from "../auth/SelectNetwork";

const steps = [
  { label: "Provide" },
  { label: "Approve" },
  { label: "Sending" },
];

const Multisend = ({ currentTheme, themeToggler }) => {
  const modalPage = useSelector((state) => state.GeneralReducer.modalPage);
  const user = useSelector((state) => state.persistedStore);
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const [networkName, setNetworkName] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [sendNativeToken, setSendNativeToken] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const connectToNetwork = async (network) => {
    dispatch(connectWallet(network));
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleDisconnect = () => {
    persistor.purge().then(() => {
      dispatch(resetUser());
      dispatch(toggleModal(0));
      setNetworkName("");
      setWalletBalance(0);
      navigate("/");
    });
  };

  useEffect(() => {
    if (user.address) {
      const web3 = new Web3(window.ethereum); // Replace with your Infura Project ID
      const fetchBalance = async () => {
        let balanceWei = await web3.eth.getBalance(user.address);
        // Convert balance from Wei to Ether
        const formattedBalance = web3.utils.fromWei(balanceWei, "ether");
        setWalletBalance(formattedBalance);
      };
      fetchBalance();
    }
  }, [user]);

  useEffect(() => {
    if (!user.isConnected) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    // handle switching of account
    const handleAccountsChanged = async (accounts) => {
      const persistStore = JSON.parse(
        localStorage.getItem("persist:persistedStore")
      );

      if (accounts.length > 0) {
        const changedAddress = {
          ...persistStore,
          address: `\"${accounts[0]}\"`,
        };
        const stringifiedData = JSON.stringify(changedAddress);
        localStorage.setItem("persist:persistedStore", stringifiedData);
        window.location.reload();
      } else {
      }
    };

    // handle changing of network
    const handleChainChanged = async (chainId) => {
      // const findChangedNetwork = networks?.filter(
      //   (x) => x?.chainId === chainId
      // );
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [dispatch]);

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="top_nav">
        <Container>
          <Navbar.Brand href="/">
            <img
              style={{ width: 120 }}
              src={
                currentTheme === "light"
                  ? "/assets/img/PNG/mitterpay_logo_light.png"
                  : "/assets/img/PNG/mitter_logo.png"
              }
              alt="logo"
            />
          </Navbar.Brand>
          <NavItems>
            {networkName && (
              <NavItem
                className="nav-item mx-2 navbar-btn p-0 nav-link"
                style={{ textTransform: "uppercase" }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(toggleModal(101));
                }}
              >
                {networkName}
              </NavItem>
            )}

            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <NavItem
                className="nav-item mx-2 navbar-btn p-0 nav-link"
                onClick={() => {
                  dispatch(toggleModal(modalConstants.SelectNetwork));
                }}
                style={{ cursor: "pointer" }}
              >
                {user?.network?.nativeCurrency?.symbol}
              </NavItem>
              <NavItem className="nav-item mx-2 navbar-btn p-0 nav-link">
                {formatAddress(user.address)}
              </NavItem>
              <NavItem
                className="nav-item mx-2 navbar-btn p-0 nav-link"
                onClick={handleDisconnect}
                style={{ cursor: "pointer" }}
              >
                Disconnect Wallet
              </NavItem>
              <ThemeToggleContainer>
                {currentTheme === "light" && (
                  <IoMoonOutline
                    size={24}
                    color={theme.black}
                    cursor={"pointer"}
                    onClick={themeToggler}
                  />
                )}
                {currentTheme === "dark" && (
                  <BsSun
                    size={24}
                    color={theme.black}
                    cursor={"pointer"}
                    onClick={themeToggler}
                  />
                )}
              </ThemeToggleContainer>
            </div>
          </NavItems>
        </Container>
      </Navbar>
      <MultisendPageContentWrapper>
        <StepperComponent activeStep={activeStep} steps={steps} />
        {activeStep === 0 && (
          <ProvideForm
            handleNextStep={handleNextStep}
            walletBalance={walletBalance}
            connectedNetwork={networkName}
            walletAddress={user?.address}
            chainId={user?.network?.chainId}
            setSendNativeToken={setSendNativeToken}
            sendNativeToken={sendNativeToken}
            nativeTokenSymbol={user?.network?.nativeCurrency?.symbol}
            currentTheme={currentTheme}
          />
        )}
        {activeStep === 1 && (
          <ApproveForm
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            walletBalance={walletBalance}
            chainId={user?.network?.chainId}
            sendNativeToken={sendNativeToken}
            setSendNativeToken={setSendNativeToken}
            network={user?.network}
          />
        )}
        {activeStep === 2 && <SuccessPage setActiveStep={setActiveStep} />}
        
      </MultisendPageContentWrapper>

      {modalPage === modalConstants.SelectNetwork ? (
        <SelectNetwork
          connectToNetwork={connectToNetwork}
          currentTheme={currentTheme}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Multisend;
