import { useSelector, useDispatch } from "react-redux";
import Loader from "../../reusables/loader";
import { initiateQuickSendPayment, toggleModal } from "../../../redux/actions";
import CustomModal from "./customModal";
import {
  CenterContent,
  SuccessMsg,
  CancelBtn,
  ContinueBtn,
} from "./modalstyles";
import Logo from "../../home/images/logo.png";
import { useTheme } from "styled-components";

const QuickSendApprovalModal = ({ msg }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const quickPayrecipientsAddresses = useSelector(
    (state) => state.QuickSendReducer.quickPayrecipientsAddresses
  );
  const quickPayrecipientsAmounts = useSelector(
    (state) => state.QuickSendReducer.quickPayrecipientsAmounts
  );
  const approvingPayment = useSelector(
    (state) => state.LoaderReducer.approvingPayment
  );

  const user = useSelector((state) => state.persistedStore);
  const curr = useSelector((state) => state.GeneralReducer.selectedCurrency);
  const quickpayrecipientFile = useSelector(
    (state) => state.GeneralReducer.quickpayrecipientFile
  );
  return (
    <CustomModal>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Approve Transaction
        </h2>

        <CenterContent>
          <SuccessMsg> {msg}</SuccessMsg>
          <div className="row">
            <div className="col-lg-6">
              <CancelBtn onClick={() => dispatch(toggleModal(0))}>
                {" "}
                Cancel
              </CancelBtn>
            </div>
            <div className="col-lg-6">
              <ContinueBtn
                onClick={() =>
                  dispatch(
                    initiateQuickSendPayment(
                      user.blockchain,
                      curr.id,
                      curr.contractAddress,
                      user.address,
                      quickpayrecipientFile,
                      quickPayrecipientsAddresses,
                      quickPayrecipientsAmounts
                    )
                  )
                }
              >
                {approvingPayment ? <Loader /> : "Continue"}
              </ContinueBtn>
            </div>
          </div>
        </CenterContent>
      </div>
    </CustomModal>
  );
};

export default QuickSendApprovalModal;
