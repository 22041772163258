import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PayrollCard } from "../reusables";
import {
  getPayrolls,
  getFormDetails,
  editFormDetails
} from "../../redux/actions";
import { EmptyCard } from "../reusables";
import Loader from "../reusables/loader";

export const AllPayroll = () => {
  const dispatch = useDispatch();
  const allpayrolls = useSelector(state => state.PayrollReducer.allpayrolls);
  const user = useSelector(state => state.persistedStore.user);
  const gettingPayroll = useSelector(state => state.LoaderReducer.searchname);

  const payrollSearchResult = useSelector(
    state => state.PayrollReducer.payrollSearchResult
  );
  const searchname = useSelector(state => state.GeneralReducer.searchname);
  let payrolls =
    (payrollSearchResult.length !== 0) & (searchname !== "")
      ? payrollSearchResult
      : allpayrolls;

  useEffect(() => {
    user && dispatch(getPayrolls(user.id, 1, 30));
  }, [dispatch]);

  return (
    <div className="row staff-grid-row">
      {gettingPayroll ? (
        <EmptyCard
          message={<Loader size={12} />}
          description="Getting Payrolls"
        />
      ) : payrolls.length === 0 ? (
        <EmptyCard
          message="No Payrolls"
          description="Create payrolls to have them show up here!"
        />
      ) : (
        payrolls.map((each, i) => {
          return (
            <PayrollCard
              key={each.id}
              id={each.id}
              icon={
                each.logoUrl
                  ? each.logoUrl
                  : "https://w7.pngwing.com/pngs/607/252/png-transparent-yellow-bank-sack-illustration-emoji-money-bag-money-logo-sticker-desktop-wallpaper.png"
              }
              name={each.name}
              createdAt={each.createdAt}
              edit={() => dispatch(editFormDetails(each))}
              reminder={() =>
                dispatch(
                  getFormDetails({
                    props: ["payrollId"],
                    value: each.id
                  })
                )
              }
              remove={() =>
                dispatch(
                  getFormDetails({
                    props: ["payrollId"],
                    value: each.id
                  })
                )
              }
            />
          );
        })
      )}
    </div>
  );
};
