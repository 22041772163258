import { TopNav } from "../reusables";
import { useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { formathash } from "../../methods/helper";

const PaySlip = () => {
  const recipientReport = useSelector(
    state => state.ReportReducer.recipientReport
  );
  let { transactionId } = useParams();
  let userReport = recipientReport.find(
    each => each.payrollRequest.batchId === transactionId
  );

  return (
    <>
      {/* Page Wrapper */}
      <TopNav />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title breadcrumb-item active ">Payslip</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Payslip</li>
                </ul>
              </div>
              <div className="col-auto float-right ml-auto">
                <div className="btn-group btn-group-sm">
                  <button className="btn btn-white">CSV</button>
                  <button className="btn btn-white">PDF</button>
                  <button className="btn btn-white">
                    <i className="fa fa-print fa-lg" /> Print
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="card">
                <div className="card-body">
                  <h4 className="payslip-title">
                    Payslip for the month of{" "}
                    {moment(
                      userReport.transctionDetail.createdAtTimeStamp
                    ).format("D MMM")}
                  </h4>
                  <div className="row">
                    <div className="col-sm-6 m-b-20">
                      <img
                        src="assets/img/logo2.png"
                        className="inv-logo"
                        alt=""
                      />
                      <ul className="list-unstyled mb-0">
                        <li>
                          {userReport.payrollRequest.payroll.name} Payroll
                        </li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                    <div className="col-sm-6 m-b-20">
                      <div className="invoice-details">
                        <h3 className="text-uppercase">
                          Payslip #
                          {userReport &&
                            formathash(
                              userReport.transctionDetail.transactionHash
                            )}
                        </h3>
                        <ul className="list-unstyled">
                          <li>
                            Salary Month:{" "}
                            <span>
                              {" "}
                              {moment(
                                userReport.transctionDetail.createdAtTimeStamp
                              ).format("MMMM, YYYY")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 m-b-20">
                      <ul className="list-unstyled">
                        <li>
                          <h5 className="mb-0">
                            <strong>{userReport && userReport.name}</strong>
                          </h5>
                        </li>
                        {/* <li>
                          <span>{userReport && userReport.walletAddress}</span>
                        </li> */}
                        <li>
                          Employee ID:{" "}
                          {formathash(userReport && userReport.walletAddress)}
                        </li>
                        <li>
                          Joining Date:{" "}
                          {/* {userReport &&
                            moment(userReport.createdAt).format("D MMM YYYY")} */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div>
                        <h4 className="m-b-10">
                          <strong>Earnings</strong>
                        </h4>
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <strong>Basic Salary</strong>{" "}
                                <span className="float-right">
                                  {userReport &&
                                    userReport.amount +
                                      " " +
                                      userReport.payrollRequest.payroll.currency
                                        .name}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>House Rent Allowance (H.R.A.)</strong>{" "}
                                <span className="float-right"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Conveyance</strong>{" "}
                                <span className="float-right"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Other Allowance</strong>{" "}
                                <span className="float-right"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Total Earnings</strong>{" "}
                                <span className="float-right">
                                  <strong></strong>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <h4 className="m-b-10">
                          <strong>Deductions</strong>
                        </h4>
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <strong>Tax Deducted at Source (T.D.S.)</strong>{" "}
                                <span className="float-right"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Provident Fund</strong>{" "}
                                <span className="float-right"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>ESI</strong>{" "}
                                <span className="float-right"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Loan</strong>{" "}
                                <span className="float-right"></span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Total Deductions</strong>{" "}
                                <span className="float-right">
                                  <strong></strong>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <p>
                        <strong>
                          Net Salary:{" "}
                          {userReport &&
                            userReport.amount +
                              " " +
                              userReport.payrollRequest.payroll.currency.name}
                        </strong>{" "}
                        only)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {/* /Page Wrapper */}
    </>
  );
};
export default PaySlip;
