import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReminder } from "../../redux/actions";
import { Loader } from "../loader";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const SetReminder = () => {
  const dispatch = useDispatch();
  const settingReminder = useSelector(
    state => state.LoaderReducer.settingReminder
  );
  const payrollId = useSelector(state => state.GeneralReducer.payrollId);

  const [startDate, setStartDate] = useState(new Date());

  return (
    <div id="set_reminder" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Set Reminder</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                <div className="form-group form-focus">
                  <div className="cal-icon">
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      className="form-control floating datetimepicker"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-section">
              <button
                data-dismiss="modal"
                onClick={() => dispatch(setReminder(payrollId, startDate))}
                className="btn btn-primary"
              >
                {settingReminder ? <Loader /> : "Set Reminder"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
