import _const from "../types";
import config from "../config";
import { addError } from "./error";
import axios from "axios";

//Gets payroll summary
export const getRecipientTransactionHistory = (
  recipientId,
  payrollName,
  dateFrom,
  dateTo,
  page,
  pageSize
) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_TRANSACTION_HISTORY,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/Transaction/GetRecipientTransactions?recipientId=${recipientId}&payrollName=${payrollName}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&pageSize=${pageSize}`
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_TRANSACTION_HISTORY,
          payload: false
        });
        dispatch({
          type: _const.GET_RECEPIENT_REPORT,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_TRANSACTION_HISTORY,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Gets quicksend recipients
export const getQuickSendRecipients = (quicksendId, page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_TRANSACTION_HISTORY,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/QuickSend/GetRecipients/quicksendId/page/pageSize?quicksendId=${quicksendId}&page=${page}&pageSize=${pageSize}`
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_TRANSACTION_HISTORY,
          payload: false
        });
        dispatch({
          type: _const.GET_QUICKSEND_RECIPIENT,
          payload: res.data.data.result
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_TRANSACTION_HISTORY,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};

//Gets quicksend transactions
export const getQuickSendTransactionHistory = (address, page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_TRANSACTION_HISTORY,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/QuickSend/Transactions/address/page/pageSize?address=${address}&page=${page}&pageSize=${pageSize}`
      )
      .then(res => {
        dispatch({
          type: _const.GETTING_TRANSACTION_HISTORY,
          payload: false
        });
        dispatch({
          type: _const.GET_QUICKSEND_TRANSACTION,
          payload: res.data.data.result
        });
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_TRANSACTION_HISTORY,
          payload: false
        });
        dispatch(addError(err));
      });
  };
};
