import React from "react";
import { PulseLoader } from "react-spinners";
import { useTheme } from "styled-components";

const override = {
  display: "block",
  borderColor: "red",
};

const Loader = ({ size, color }) => {
  const theme = useTheme();
  return (
    <div className="sweet-loading">
      <PulseLoader
        style={override}
        size={size ? size : 5}
        color={color ? color : theme.white}
        loading={true}
      />
    </div>
  );
};

export default Loader;
