import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  createPayrollRecipient,
  excelRecipient,
  getFormDetails,
  manualQuickSendRecipient,
  quickpayRecipient,
  quickpayToggle,
  successfullAction
} from "../../redux/actions";

const CreateQuickSend = props => {
  const dispatch = useDispatch();

  const [recipients, setRecipient] = useState([]);

  const excelImport = useSelector(state => state.GeneralReducer.excelRecipient);
  const madeAction = useSelector(state => state.GeneralReducer.successfull);
  

  useEffect(() => {
    excelImport && setRecipient(excelImport);
    console.log("rece", recipients);
  }, []);

  function onAdd() {
    const data = {
      A: recipientwalletAddress,
      B: recipientemail,
      C: amount
    };
    recipients.push(data);
    dispatch(quickpayRecipient(recipients));
    dispatch(quickpayToggle(true));
    dispatch(successfullAction(!madeAction));
    // dispatch(manualQuickSendRecipient(recipients))
  }

  const recipientemail = useSelector(
    state => state.GeneralReducer.recipientemail
  );
  const recipientwalletAddress = useSelector(
    state => state.GeneralReducer.recipientwalletAddress
  );
  const amount = useSelector(state => state.GeneralReducer.amount);

  return (
    <div>
      <div className="modal-header">
        <h5 className="modal-title">Add Quick Pay Recipient</h5>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Recipient's Name</label>
                    <input
                      className="form-control"
                      value={recipientname}
                      onChange={e =>
                        dispatch(
                          getFormDetails({
                            props: ["recipientname"],
                            value: e.target.value
                          })
                        )
                      }
                      type="email"
                    />
                  </div>
                </div> */}
            <div className="col-sm-6">
              <div className="form-group">
                <label>Recipient's email address</label>
                <input
                  className="form-control"
                  value={recipientemail}
                  onChange={e =>
                    dispatch(
                      getFormDetails({
                        props: ["recipientemail"],
                        value: e.target.value
                      })
                    )
                  }
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Recipient's wallet address</label>
                <input
                  className="form-control"
                  value={recipientwalletAddress}
                  onChange={e =>
                    dispatch(
                      getFormDetails({
                        props: ["recipientwalletAddress"],
                        value: e.target.value
                      })
                    )
                  }
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Amount</label>
                <input
                  className="form-control"
                  type="text"
                  value={amount}
                  onChange={e =>
                    dispatch(
                      getFormDetails({
                        props: ["amount"],
                        value: e.target.value
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="submit-section">
            <div
              // data-dismiss="modal"
              onClick={() => onAdd()}
              className="btn btn-primary submit-btn"
            >
              Submit
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateQuickSend;
