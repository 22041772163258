import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  getRecipientTransactionHistory,
  clearTransactionReport
} from "../../redux/actions";
import { useParams } from "react-router-dom";

import { getFormDetails } from "../../redux/actions";
import DatePicker from "react-datepicker";
import moment from "moment";

const SearchReport = () => {
  const dispatch = useDispatch();
  let { recepientId } = useParams();
  const totalPages = useSelector(state => state.ReportReducer.totalPages);
  const currentPage = useSelector(state => state.ReportReducer.currentPage);
  const selectedpayroll = useSelector(
    state => state.PayrollReducer.selectedpayroll
  );

  const reportdateFrom = useSelector(
    state => state.GeneralReducer.reportdateFrom
  );
  const reportdateTo = useSelector(state => state.GeneralReducer.reportdateTo);

  useEffect(() => {
    let newPage = currentPage + 1;
    if (totalPages > currentPage) {
      dispatch(
        getRecipientTransactionHistory(
          recepientId,
          selectedpayroll.name,
          reportdateFrom === "" ? "" : moment(reportdateFrom).format(),
          reportdateTo === "" ? "" : moment(reportdateTo).format(),
          newPage,
          30
        )
      );
    }
  }, [recepientId, totalPages, currentPage]);

  return (
    <div className="row filter-row">
      <div className="col-sm-6 col-md-3">
        <div className="form-group form-focus">
          <input
            type="text"
            value={""}
            // onChange={e =>
            //   dispatch(
            //     getFormDetails({
            //       props: ["searchname"],
            //       value: e.target.value
            //     })
            //   )
            // }
            className="form-control floating"
          />
          <label className="focus-label">Payroll Name</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="form-group form-focus">
          <div className="cal-icon">
            <DatePicker
              selected={reportdateFrom}
              onChange={date =>
                dispatch(
                  getFormDetails({
                    props: ["reportdateFrom"],
                    value: date
                  })
                )
              }
              className="form-control floating datetimepicker"
            />
          </div>
          <label className="focus-label">From</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="form-group form-focus">
          <div className="cal-icon">
            <DatePicker
              selected={reportdateTo}
              onChange={date =>
                dispatch(
                  getFormDetails({
                    props: ["reportdateTo"],
                    value: date
                  })
                )
              }
              className="form-control floating datetimepicker"
            />
          </div>
          <label className="focus-label">To</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-3">
        <div
          className="btn btn-success btn-block"
          onClick={() => {
            dispatch(clearTransactionReport());
            dispatch(
              getRecipientTransactionHistory(
                recepientId,
                selectedpayroll.name,
                moment(reportdateFrom).format(),
                moment(reportdateTo).format(),
                1,
                30
              )
            );
          }}
        >
          {" "}
          Search{" "}
        </div>
      </div>
    </div>
  );
};

export default SearchReport;
