import { BlockchainSelector, NetworksContainer } from "./style";
import ConnectWalletModal from "../reusables/modal/ConnectWalletModal";
import { ChildrenContent } from "../reusables/modal/modalstyles";
import { networks } from "../../networks";
import { useTheme } from "styled-components";

const SelectNetwork = ({ connectToNetwork, currentTheme }) => {
const theme = useTheme()

  return (
    <ConnectWalletModal>
      <img
        style={{ width: "10%" }}
        src={
          currentTheme === "light"
            ? "/assets/img/PNG/mitterpay_logo_light.png"
            : "/assets/img/PNG/mitter_logo.png"
        }
        alt="logo"
      />
      <ChildrenContent>
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Connect Wallet
        </h2>
        <div className="text-center">
          <p style={{ color: theme.black }}> Select the Network to connect</p>
          <NetworksContainer>
            {networks.map((element) => (
              <BlockchainSelector
                key={element.chainId}
                onClick={() => {
                  connectToNetwork(element);
                }}
              >
                {element?.image}

                <span>{element?.chainName}</span>
              </BlockchainSelector>
            ))}
          </NetworksContainer>
        </div>
      </ChildrenContent>
    </ConnectWalletModal>
  );
};

export default SelectNetwork;
