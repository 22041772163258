import Template from "./midas_recipients.xlsx";
import { UploadFile } from "./UploadSheet";
import Logo from "../home/images/logo.png";
import { Selector } from "./excelstyle";
import { useTheme } from "styled-components";

const UploadExcelModal = ({ quick }) => {
  const theme = useTheme();

  return (
    <div>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Import Recipients
        </h2>
        <div className="text-center">
          <a href={Template} download className=" px-3 py-3">
            <Selector>
              {" "}
              <i
                className="fa fa-download fa-2x "
                style={{ float: "left", marginLeft: "5%" }}
              />{" "}
              Download Template{" "}
            </Selector>
          </a>
          {/* <Selector>
            {" "}
            <i
              className="fa fa-upload fa-2x "
              style={{ float: "left", marginLeft: "5%" }}
            />{" "} */}
          <UploadFile quick={quick} />
          {/* </Selector> */}
        </div>
        {/* <div className="modal-body">
          <div className="row">
            <Selector></Selector>
            <div className="col-sm-6">
              <a href={Template} download className="btn add-btn px-3 py-3">
                <i className="fa fa-download" />
                Download Template
              </a>
            </div>
            <div className="col-sm-6">
              <UploadFile quick={quick} />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default UploadExcelModal;
