import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  createMultiplePayrollRecipient,
  createPayrollRecipient,
  madeImport,
  excelRecipient
} from "../../redux/actions";
import { useParams } from "react-router-dom";
import AddExcelRecipient from "./addExcelRecipient";

const ExcelRecipients = ({ data }) => {
  const dispatch = useDispatch();
  let { payrollId } = useParams();

  const [recipients, setRecipient] = useState([]);
  const allPayrollRecipients = useSelector(
    state => state.PayrollReducer.allPayrollRecipients
  );
  const selectedpayroll = useSelector(
    state => state.PayrollReducer.selectedpayroll
  );

  useEffect(() => {
    data && setRecipient(data);
  }, []);

  function arrayRemove(value) {
    const b = recipients.filter(function(ele) {
      return ele.B != value.B;
    });
    setRecipient(b);
    if (b.length === 0) {
      dispatch(excelRecipient([]));
    }
  }

  function addAll() {
    const result = recipients.map(item => {
      let set = {};
      let structure = { A: "walletAddress", C: "amount", B: "emailAddress" };
      for (let each in structure) {
        if (item[each]) {
          set = { ...set, [structure[each]]: item[each] };
        }
      }
      return set;
    });
    dispatch(createMultiplePayrollRecipient(payrollId, result));
    dispatch(excelRecipient([]));
  }

  return (
    <div className="row mb-5 mt-2">
      <div className="col-md-12">
        <div className="table-responsive">
          <p className="ml-3">Excel Import : </p>

          <div className="col-auto float-right ml-auto mb-2">
            <a
              href="#"
              className="btn btn-success btn-block"
              onClick={() => addAll()}
            >
              <i className="fa fa-plus" /> Add All Imports
            </a>
          </div>

          <div className="col-auto float-right ml-auto mb-2">
            <a
              href="#"
              className="btn btn-success btn-block"
              onClick={() => dispatch(excelRecipient([]))}
            >
              <i className="fa fa-trash" /> Remove All Imports
            </a>
          </div>
          <div className="col-auto float-right ml-auto mb-2">
            <a
              href="#"
              className="btn btn-success btn-block"
              data-toggle="modal"
              data-target="#add_recipient"
            >
              <i className="fa fa-plus" /> Add Recipient
            </a>
          </div>
          <table className="table table-striped custom-table datatable">
            <thead>
              <tr>
                <th className="none">Address</th>
                <th>Email</th>
                <th>Salary</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {recipients.length !== 0 &&
                recipients.map((each, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <h2 className="table-avatar">
                          <p className="avatar">
                            <img
                              alt="avatar"
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png"
                            />
                          </p>
                          <p className="none">
                            <span>{each.A}</span>
                          </p>
                        </h2>
                      </td>
                      <td>{each.B ?? "--"}</td>
                      <td>
                        {each.C}
                        {selectedpayroll.currency &&
                          selectedpayroll.currency.name}
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary mr-3  w-50"
                          onClick={() => arrayRemove(each)}
                        >
                          Remove
                        </button>

                        <button
                          className="btn btn-sm btn-primary w-50"
                          onClick={() => {
                            dispatch(
                              createPayrollRecipient(
                                payrollId,
                                each.A,
                                each.B,
                                each.C
                              )
                            );
                            arrayRemove(each);
                          }}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <AddExcelRecipient
            addRecipient={value => setRecipient(recipients.concat(value))}
          />
        </div>
      </div>
    </div>
  );
};
export default ExcelRecipients;
