let localPlatforms = ["localhost", "user.dev.demidas.co"];
let platform = localPlatforms.includes(window.location.hostname)
  ? "local"
  : "production";
let settings = {};

if (platform === "local") {
  settings = {
    //apiUrl: "https://api.dev.demidas.co/api",
    apiUrl: "https://api.demidas.co/api",
    appName: "Midas",
    subect: "Midas",
    baseUrl: "",
    isTestNet: false
  };
} else {
  settings = {
    apiUrl: "https://api.demidas.co/api",
    appName: "Midas",
    subect: "Midas",
    baseUrl: "",
    isTestNet: false
  };
}

export default settings;
