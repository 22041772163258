import { TopNav } from "../reusables";
import { useSelector, useDispatch } from "react-redux";
import CreateRecipient from "./createRecipient";
import AllRecipient from "./allRecipients";
import DeleteRecipient from "./deleteRecipient";
import { EditRecipient } from "./editRecipiemt";
import PayCurrentrecipinets from "./payCurrentRecipients";
import { Link } from "react-router-dom";
import { excelRecipient, toggleModal } from "../../redux/actions";
import SearchRecipients from "./searchRecipients";
import ExcelRecipients from "./excelRecipients";
import UploadExcelModal from "../excel/UploadExcelModal";
import CustomModal from "../reusables/modal/customModal";
import modalConstants from "../reusables/modal/modalConstants";


const Payroll = props => {
  const dispatch = useDispatch();
  const selectedpayroll = useSelector(
    state => state.PayrollReducer.selectedpayroll
  );
  const excelImport = useSelector(state => state.GeneralReducer.excelRecipient);
  const quickpay = useSelector(state => state.GeneralReducer.quickpay);
  const modalPage = useSelector(state => state.GeneralReducer.modalPage);

  return (
    <div className="landing-background">
      <TopNav />
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title breadcrumb-item active features_head">
                  {selectedpayroll && selectedpayroll.name}
                </h3>
                <ul className="breadcrumb">
                  <li
                    className="breadcrumb-item "
                    onClick={() => {
                      dispatch(excelRecipient([]));
                      // dispatch(madeImport(false));
                      // dispatch(quickpayToggle(false));
                    }}
                  >
                    <Link to="/home">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">payroll</li>
                </ul>
              </div>

              <div className="col-auto float-right ml-auto">
                <div
                  className="btn add-btn"
                  onClick={() => dispatch(toggleModal(7))}
                >
                  <i className="fa fa-file" /> Import Excel File
                </div>
              </div>

              <div className="col-auto float-right ml-auto">
                <div
                  onClick={() => dispatch(toggleModal(3))}
                  className="btn add-btn"
                >
                  <i className="fa fa-plus" /> Add Recipient
                </div>
              </div>

              <PayCurrentrecipinets />
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}
          <SearchRecipients />
          {/* /Search Filter */}
          {/* Excel Recipient */}
          {excelImport.length > 0 && !quickpay && (
            <ExcelRecipients data={excelImport} />
          )}
          {/* Normal Recipient */}
          <AllRecipient />
        </div>
        {/* /Page Content */}
        {/* Add Payroll recipient */}
        {modalPage === modalConstants.CreateRecipient ? (
          <CustomModal>
            <CreateRecipient />
          </CustomModal>
        ) : (
          ""
        )}
        {modalPage === modalConstants.UploadExcelModal ? (
          <CustomModal>
            <UploadExcelModal />
          </CustomModal>
        ) : (
          ""
        )}

        {/* /Add Salary Modal */}
        {/* Edit Payroll recipient */}
        {modalPage === modalConstants.EditRecipient ? (
          <CustomModal>
            <EditRecipient />
          </CustomModal>
        ) : (
          ""
        )}

        {/* /Edit Payroll recipient */}
        {/* Delete Payroll recipient */}
        {modalPage === modalConstants.DeleteRecipient ? (
          <CustomModal>
            <DeleteRecipient />
          </CustomModal>
        ) : (
          ""
        )}

        {/* /Delete Payroll recipient */}
      </div>
      {/* /Page Wrapper */}
    </div>
  );
};
export default Payroll;
