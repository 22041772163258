import { useDispatch, useSelector } from "react-redux";
import { createCurrency, getFormDetails } from "../../redux/actions";
import { Loader } from "../loader";
import Logo from "../home/images/logo.png";
import { useTheme } from "styled-components";

export const CreateCurrency = () => {
  const dispatch = useDispatch();
  const theme = useTheme()
  const contractAddress = useSelector(
    state => state.GeneralReducer.contractAddress
  );

  // const blockchainType = useSelector(
  //   state => state.GeneralReducer.blockchainType
  // );
  // const blockchainTypes = useSelector(
  //   state => state.GeneralReducer.blockchainTypes
  // );
  const creatingCurrencyError = useSelector(
    state => state.GeneralReducer.creatingCurrencyError
  );

  const auth = useSelector(state => state.persistedStore);
  const creatingCurrency = useSelector(
    state => state.LoaderReducer.creatingCurrency
  );

  return (
    <div>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%"
        }}
      >
        <h2 style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}>
          Import Currency
        </h2>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Contract Address <span className="text-danger">*</span>
                </label>
                <input
                  value={contractAddress}
                  onChange={e =>
                    dispatch(
                      getFormDetails({
                        props: ["contractAddress"],
                        value: e.target.value
                      })
                    )
                  }
                  className="form-control"
                  type="text"
                />
              </div>
            </div>

            {/* <div className="col-sm-6">
            <div className="form-group">
              <label className="col-form-label">
                Blockchain<span className="text-danger">*</span>
              </label>

              <select
                className="form-control"
                onChange={e => {
                  dispatch(
                    getFormDetails({
                      props: ["blockchainType"],
                      value: e.target.value
                    })
                  );
                }}
                required
              >
                <option name="city">Choose Blockchain</option>
                {blockchainTypes.map(each => (
                  <>
                    <option
                      name="currency"
                      key={each.id}
                      value={each.id}
                      id={each.id}
                    >
                      {each.value}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </div> */}
            <div className="col-sm-12">
              {creatingCurrencyError ? (
                <div className="err-msg">
                  <p className="mb-0"> {creatingCurrencyError}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="submit-section">
          <button
            onClick={() =>
              dispatch(
                createCurrency(auth.blockchain, contractAddress, auth.isTestNet)
              )
            }
            disabled={contractAddress ? false : true}
            className="btn btn-success btn-block"
          >
            {creatingCurrency ? <Loader /> : "Import Currency"}
          </button>
        </div>
      </div>
    </div>
  );
};
