import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPayroll,
  getCurrenciesAction,
  getFormDetails,
  toggleModal,
} from "../../redux/actions";
import { Loader } from "../loader";
import Logo from "../home/images/logo.png";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";

export const CreatePayroll = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const payrolldetails = useSelector(
    (state) => state.PayrollReducer.payrolldetails
  );
  const payrollname = useSelector((state) => state.GeneralReducer.payrollname);
  const payrollImage = useSelector(
    (state) => state.GeneralReducer.payrollImage
  );
  const currency = useSelector((state) => state.GeneralReducer.currency);
  const currencyId = useSelector((state) => state.GeneralReducer.currencyId);
  const reminderDay = useSelector((state) => state.GeneralReducer.reminderDay);
  const user = useSelector((state) => state.persistedStore);

  const [image, setImage] = useState();
  const [curr, setCurr] = useState();

  const creatingPayroll = useSelector(
    (state) => state.LoaderReducer.creatingPayroll
  );
  const creatingPayrollErr = useSelector(
    (state) => state.PayrollReducer.creatingPayrollErr
  );

  useEffect(() => {
    dispatch(getCurrenciesAction(user.blockchain));

    currency &&
      currency.records &&
      currency.records.length > 0 &&
      setCurr(currency.records[0]);
  }, []);

  const validate = (payrollname, reminderDay, currencyId) => {
    const number = parseInt(reminderDay, 10);
    if (!reminderDay || number < 10 || number > 28) {
      toast.error(
        "Reminder day should be between 10th to 28th of every month "
      );
    }
    if (!payrollname) {
      toast.error("Payroll name is required");
    }
    if (!currencyId) {
      toast.error("Currency is required");
    }
    return true;
  };

  const submitPayrollInfo = () => {
    if (validate(reminderDay, reminderDay, currencyId)) {
      dispatch(
        createPayroll(
          user.address,
          payrollname,
          currencyId,
          payrollImage,
          user.user.id,
          user.blockchain,
          reminderDay
        )
      );
    }
  };

  return (
    <div>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Create Payroll
        </h2>
        <div className="col-sm-12">
          <div className="text-center">
            <input
              type="file"
              name="file"
              id="file"
              onChange={(e) => {
                dispatch(
                  getFormDetails({
                    props: ["payrollImage"],
                    value: e.target.files[0],
                  })
                );
                setImage(e.target.files[0]);
              }}
              className="inputfile"
            />
            <label htmlFor="file">
              {image ? (
                <div className="previewImg">
                  <img
                    className="businessImg"
                    id="Img1"
                    src={URL.createObjectURL(image)}
                    alt="passport image"
                  />
                </div>
              ) : (
                <div>
                  <p className="mt-4"> Click to add Logo</p>
                </div>
              )}
            </label>
            <div className="form-group mb-0">
              <label className="col-form-label">Add Payroll Logo</label>
            </div>
          </div>
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Payroll Name <span className="text-danger">*</span>
                </label>
                <input
                  value={payrollname}
                  onChange={(e) =>
                    dispatch(
                      getFormDetails({
                        props: ["payrollname"],
                        value: e.target.value,
                      })
                    )
                  }
                  className="form-control"
                  type="text"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Reminder day <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  value={reminderDay}
                  onChange={(e) =>
                    dispatch(
                      getFormDetails({
                        props: ["reminderDay"],
                        value: e.target.value,
                      })
                    )
                  }
                  min="1"
                  max="28"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Payroll Currency <span className="text-danger">*</span>
                </label>

                <select
                  className="form-control"
                  onChange={(e) => {
                    dispatch(
                      getFormDetails({
                        props: ["currencyId"],
                        value: e.target.value,
                      })
                    );
                  }}
                  required
                >
                  <option name="city">Choose Currency</option>
                  {currency.records &&
                    currency.records.map((c) => (
                      <option
                        key={c.id}
                        value={c.id}
                        name="currency"
                        key={c.id}
                        id={c.id}
                      >
                        {c.name}
                      </option>
                    ))}
                </select>
              </div>
              <p style={{ fontSize: 14 }}>
                Don't see your currency?{" "}
                <span
                  onClick={() => dispatch(toggleModal(21))}
                  style={{
                    cursor: "pointer",
                    color: theme.red,
                    fontWeight: "bold",
                  }}
                >
                  Import
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="submit-section">
          <button
            onClick={() => submitPayrollInfo()}
            className="btn btn-success btn-block"
          >
            {creatingPayroll ? <Loader /> : "Create Payroll"}
          </button>
        </div>
      </div>
    </div>
  );
};
