import createContract from "./createContract";
import erc20Abi from "../erc20.json";

async function tokenDecimals(currencyAddress) {
  try {
    const contract = await createContract(erc20Abi, currencyAddress);
    return await contract.methods.decimals().call();
  } catch (err) {
    console.log(err);
  }
}

export default tokenDecimals;
