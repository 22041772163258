import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeAddress } from "../../redux/actions";
import { Nav, Container, Navbar } from "react-bootstrap";
import Logo from "../home/images/logo.png";

export const TopNav = () => {
  const dispatch = useDispatch();

  function disconnect() {
    localStorage.clear();
    dispatch(removeAddress());
  }

  return (
    <Navbar collapseOnSelect expand="lg" className="top_nav">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img width="100%" src={Logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto risec">
            <Nav.Link className="nav-item mx-2 navbar-btn p-0">
              <span className="nav-link navbar-btn" onClick={() => disconnect()}>
                {" "}
                Disconnect
              </span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
