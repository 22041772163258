import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormDetails } from "../../redux/actions";
import Logo from "../home/images/logo.png";
import { useTheme } from "styled-components";

export const EditRecipient = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  let { payrollId } = useParams();

  const recipientname = useSelector(
    (state) => state.GeneralReducer.recipientname
  );
  const allPayrollRecipients = useSelector(
    (state) => state.PayrollReducer.allPayrollRecipients
  );
  const recipientemail = useSelector(
    (state) => state.GeneralReducer.recipientemail
  );
  const recipientwalletAddress = useSelector(
    (state) => state.GeneralReducer.recipientwalletAddress
  );
  const amount = useSelector((state) => state.GeneralReducer.amount);

  return (
    <div>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Edit Payroll Recipient
        </h2>

        <div className="modal-body">
          <form>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Recipient's Name</label>
                  <input
                    className="form-control"
                    value={recipientname}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["recipientname"],
                          value: e.target.value,
                        })
                      )
                    }
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Recipient's email address</label>
                  <input
                    className="form-control"
                    value={recipientemail}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["recipientemail"],
                          value: e.target.value,
                        })
                      )
                    }
                    type="email"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Recipient's wallet address</label>
                  <input
                    className="form-control"
                    value={recipientwalletAddress}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["recipientwalletAddress"],
                          value: e.target.value,
                        })
                      )
                    }
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Amount</label>
                  <input
                    className="form-control"
                    type="text"
                    value={amount}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["amount"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="submit-section">
              {/* <div
                // data-dismiss="modal"
                onClick={() =>
                  dispatch(
                    editPayrollRecipient(
                      payrollId,
                      recipientwalletAddress,
                      recipientname,
                      recipientemail,
                      amount,
                      allPayrollRecipients.length
                    )
                  )
                }
                className="btn btn-success btn-block"
              >
                Submit
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
