import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme";

const BalanceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid ${theme.lightGray};
  border-radius: 15px;
  width: 45%;
`;

const BalanceTitle = styled.p`
  font-size: 13px;
  color: ${theme.gray5};
  text-transform: uppercase;
`;
const BalanceValue = styled.h2`
  font-weight: 600;
  text-transform: uppercase;
`;

const BalanceCard = ({ balanceValue, balanceTitle }) => {
  return (
    <BalanceCardContainer>
      <BalanceValue>{balanceValue}</BalanceValue>
      <BalanceTitle>{balanceTitle}</BalanceTitle>
    </BalanceCardContainer>
  );
};

export default BalanceCard;
