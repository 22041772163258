import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editPayroll } from "../../redux/actions";
import Logo from "../home/images/logo.png";
import { theme } from "../../theme";
import { useTheme } from "styled-components";

export const EditPayroll = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const editDetails = useSelector((state) => state.GeneralReducer.editDetails);
  const currency = useSelector((state) => state.GeneralReducer.currency);
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const user = useSelector((state) => state.persistedStore);
  const [curr, setCurr] = useState("");
  const [image, setImage] = useState("");

  function currencyCheck(params) {
    currency.filter((c) => (c.name === params ? setCurr(c.id) : null));
  }
  useEffect(() => {
    setName(editDetails.name);
    setAddress(editDetails.walletAddress);
  }, [editDetails]);

  return (
    <div>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Edit Payroll
        </h2>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <input
                  type="text"
                  name="editImage"
                  id="editImage"
                  onChange={(e) => setImage(e.target.files[0])}
                  className="inputfile"
                />
                <label htmlFor="editImage">
                  {image ? (
                    <div className="previewImg">
                      <img
                        className="businessImg"
                        id="Img1"
                        src={URL.createObjectURL(image)}
                        alt="passport image"
                      />
                    </div>
                  ) : (
                    <div className="previewImg">
                      <img
                        className="businessImg"
                        src={
                          editDetails.logoUrl
                            ? editDetails.logoUrl
                            : "https://w7.pngwing.com/pngs/607/252/png-transparent-yellow-bank-sack-illustration-emoji-money-bag-money-logo-sticker-desktop-wallpaper.png"
                        }
                        alt="passport image"
                      />
                    </div>
                  )}
                </label>
                <div className="form-group mb-0">
                  <label className="col-form-label">Edit Payroll Logo</label>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Payroll Name <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Currency <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    const selectedCurrency = e.target.value;
                    currencyCheck(selectedCurrency);
                  }}
                  required
                >
                  <option name="city">
                    {editDetails.currency ? editDetails.currency.name : ""}
                  </option>
                  {/* {currency &&
                      currency.map(c => (
                        <>
                          <option name="currency" key={c.id} id={c.id}>
                            {c.name}
                          </option>
                        </>
                      ))} */}
                </select>
              </div>
            </div>
          </div>

          <div className="submit-section">
            <button
              className="btn btn-success btn-block"
              data-dismiss="modal"
              disabled={name ? false : true}
              onClick={() =>
                dispatch(
                  editPayroll(
                    address,
                    name,
                    curr === "" ? editDetails.currencyId : curr,
                    editDetails.id,
                    user.user.id
                  )
                )
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
