import React from "react";
import FormCard from "../../reusables/cards/FormCard";
import styled from "styled-components";
import { GoCheckCircleFill } from "react-icons/go";
import { useSelector } from "react-redux";
import web3 from "../../../methods/web3setup";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../theme";

const SuccessImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 30px;
  background-color: ${theme.whiteSmoke};
  border-radius: 10px;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SuccessIcon = styled(GoCheckCircleFill)`
  font-size: 100px;
  color: ${theme.secondary};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 80px;
  }
`;

const SuccessText = styled.div`
  font-size: 22px;
  text-align: center;
  color: ${theme.gray5};
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const DetailHeaderText = styled.div`
  font-size: 24px;
  text-align: center;
  color: ${theme.gray5};
  margin: 20px 0;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 15px 0;
  }
`;

const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 10px;
  column-gap: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    row-gap: 8px;
    column-gap: 0;
  }
`;

const DetailLabel = styled.div`
  font-weight: bold;
  color: #555;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const DetailText = styled.div`
  word-wrap: break-word;
  color: ${theme.gray2};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TransactionHash = styled.a`
  text-decoration: underline;
  color: ${theme.primary};

  &:hover {
    color: ${theme.blue};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

// Utility function to truncate the hash
const truncateHash = (hash) => {
  return `${hash.slice(0, 6)}...${hash.slice(-6)}`;
};

const SuccessPage = ({ setActiveStep }) => {
  const state = useSelector((state) => state.multisendReducer);
  const user = useSelector((state) => state.persistedStore);

  const formattedBlockNumber =
    state?.blockchainResponse?.blockNumber?.toString();
  const formattedGasUsed = web3.utils.fromWei(
    state?.blockchainResponse?.effectiveGasPrice.toString(),
    "ether"
  );
  const payAgain = () => {
    // setActiveStep(0)
    window.location.reload();
  };

  return (
    <FormCard>
      <SuccessImageContainer>
        <SuccessIcon />
        <SuccessText>
          Assets have been deposited to their respective addresses.
        </SuccessText>
        <DetailHeaderText>Transaction Details</DetailHeaderText>
        <DetailContainer>
          <DetailLabel>Hash:</DetailLabel>
          <DetailText>
            <TransactionHash
              href={`${user.network.blockExplorerUrls}${state?.blockchainResponse?.transactionHash}`}
              target="_blank"
            >
              {truncateHash(state?.blockchainResponse?.transactionHash)}
            </TransactionHash>
          </DetailText>

          <DetailLabel>Block Number:</DetailLabel>
          <DetailText>{formattedBlockNumber}</DetailText>

          <DetailLabel>Gas Used:</DetailLabel>
          <DetailText>{formattedGasUsed}</DetailText>
        </DetailContainer>
        <button
          className="nav-item mx-2 navbar-btn p-0 nav-link"
          style={{ width: "300px", marginTop: "10px" }}
          onClick={() => payAgain()}
        >
          Initiate Another Transfer
        </button>
      </SuccessImageContainer>
    </FormCard>
  );
};

export default SuccessPage;
