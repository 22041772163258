import _const from "../types";

const multisendReducer = (
  state = {
    senderAddress: "",
    tokenAddress: "",
    senderWalletBallance: {},
    receiversAddress: {},
    blockchainResponse: null
  },
  action
) => {
  switch (action.type) {
    case _const.STORE_MULTISEND_DETAILS:
      return {
        ...state,
        senderAddress: action?.payload?.sender,
        tokenAddress: action?.payload?.token,
        senderWalletBallance: action?.payload?.walletBalance,
        receiversAddress: action?.payload?.receiver,
      };
    case _const.SUCCESSFUL_PAYMENT:
        return{
          ...state,
          blockchainResponse:action?.payload
        }
    default:
      return state;
  }
};

export default multisendReducer;
