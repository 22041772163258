import createContract from "./createContract";
import payrollAbi from "../forwarderproxy.json";

async function transactionFee(amounts, addresses, network) {
  console.log(network)
  try {
    const contract = await createContract(
      payrollAbi,
      network.FORWARDER_PROXY_ADDRESS
    );

    let transactionDetail = {};
    let approvedAmount = 0;
    let totaltransactionFee = 0;
    for (let i = 0; i < amounts.length; i++) {
      approvedAmount = approvedAmount += amounts[i];
    }
    transactionDetail["approvedAmount"] = approvedAmount;
    

    totaltransactionFee = await contract.methods
      .getTransactionFee(addresses.length)
      .call();
    
      console.log("pay", totaltransactionFee)
    var num = Math.pow(10, 18);

    let formattedFee = Number(totaltransactionFee) / num;
    let totalFee = formattedFee + approvedAmount;
    transactionDetail["transactionFee"] = totaltransactionFee;
    transactionDetail["totaltransactionFee"] = formattedFee;
    transactionDetail["totalFee"] = totalFee;
    return transactionDetail;
  } catch (error) {
    console.log(error)
    return error;
  }
}

export default transactionFee;
