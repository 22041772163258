// src/GlobalStyles.js
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

*{
    transition: all 0.50s linear;

}
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }

/* body::before {
    content: "";
    position: fixed;
    background: linear-gradient(34.56deg, rgba(144, 91, 230, 0) 12.95%, #020C68 63.83%), linear-gradient(40.39deg, #59ACF9 15.02%, rgba(89, 249, 172, 0) 48.97%);
    background-blend-mode: screen, darken; /* Adjust blend modes as needed */
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
    top: 0;
    right: 0;
    border-radius: 72.17px 0 0 0; /* Adjust border-radius as needed */
    opacity: 0.6; /* Adjust opacity as needed */
    z-index: -1; /* Ensure it's behind other content */
} */


  .goog-te-combo {
    color: ${({ theme }) => theme.white};
 }
 .err-msg {
  color: ${({ theme }) => theme.white};
}

.approved {
  color: ${({ theme }) => theme.green};
}

.pending {
  color: ${({ theme }) => theme.orange};
}

.failed {
  color: ${({ theme }) => theme.red};
}

.inputfile + label {
  background: rgba(169, 186, 243, 0.15);
  border: 1px solid ${({ theme }) => theme.lightBlue};
  color: ${({ theme }) => theme.darkBlue};
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.darkBlue};
}

.title {
  color: ${({ theme }) => theme.gray5};
}

.faq {
  border-top: 1px solid ${({ theme }) => theme.gray6};
}

.collapse {
  border: 1px solid ${({ theme }) => theme.gray6};
}

.question__title {
  color: ${({ theme }) => theme.gray5};
}

.enhanced-form {
  background-color: white;
  box-shadow: 0 4px 8px ${({ theme }) => theme.translucentBlack4};
}

.token_balance_container {
  color: ${({ theme }) => theme.gray5};
}

.upload-csv-label {
  border: 1px solid ${({ theme }) => theme.gray6};
  color: ${({ theme }) => theme.gray5};
  /* background-color: ${({ theme }) => theme.gray5}; */
}

.button:disabled {
  background-color: ${({ theme }) => theme.gray5};
  color: ${({ theme }) => theme.greenLight};
}

.button,
.button:hover {
  background-color: ${({ theme }) => theme.gray5};
  color: ${({ theme }) => theme.greenLight};
}

  .name-style {
      color: ${({ theme }) => theme.gray3};
  }

  .dropdown > p {
  color: ${({ theme }) => theme.gray8};
}

.icon {
  color: ${({ theme }) => theme.gray8};
}

.icon :hover {
  color: ${({ theme }) => theme.white};
}

.drop > p:hover {
  color: ${({ theme }) => theme.gray8};
  color: ${({ theme }) => theme.white};
}

.dropdown > div > p {
  color: ${({ theme }) => theme.gray8};
}

.dropdown_item :hover {
  color: ${({ theme }) => theme.white};
}

.dropdownOption:hover {
  background-color: ${({ theme }) => theme.background};
}

.upload-csv-label {
  border: 1px solid ${({ theme }) => theme.gray6};
  color: ${({ theme }) => theme.gray5};
}


.switch {
  border: 1px solid ${({ theme }) => theme.gray13};
}


.slider {
  background-color: ${({ theme }) => theme.gray6};
}

.slider:before {
  background-color: white;
}

input:checked + .slider {
  background-color: ${({ theme }) => theme.green2};
}

.label {
  color: ${({ theme }) => theme.gray5};
}

.landing-title {
	color: ${({ theme }) => theme.text};
}

.avatar-title {
	background-color: #2962ff;
	color: ${({ theme }) => theme.white};	
}

.form-control {
	background-color: white;
	color: ${({ theme }) => theme.black};
	border-color: ${({ theme }) => theme.gray12};
}


.form-control {
	color: ${({ theme }) => theme.black};
	border-color: ${({ theme }) => theme.gray12};
}

.form-control:focus {
	border-color: ${({ theme }) => theme.greenLight};
	color: ${({ theme }) => theme.black};
}

.input-group-text {
	background-color: ${({ theme }) => theme.lightGray2};
	border-color: ${({ theme }) => theme.lightGray3};
}

.table {
	color: ${({ theme }) => theme.gray3};
}

.table.table-white {
	background-color: ${({ theme }) => theme.darkGray1};
}


.table-striped>tbody>tr:nth-of-type(2n+1) {
	background-color: #202e3a;
}


table.table td h2.table-avatar.blue-link a {
	color: #007bff;
}

table.table td h2 a {
	color: ${({ theme }) => theme.gray8};
}

table.table td h2 a:hover {
	color: ${({ theme }) => theme.orange};
}

table.table td h2 span {
	color: #9e9e9e;
}


.table-hover tbody tr:hover {
	background-color: ${({ theme }) => theme.darkGray1};
	color: ${({ theme }) => theme.white};
}

.table-borde${({ theme }) => theme.red} {
	border: 1px solid ${({ theme }) => theme.darkGray8};
}


.text-muted-light {
	color: ${({ theme }) => theme.gray11};
}


.btn-white {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
	color: ${({ theme }) => theme.gray3};
}

.btn-white:focus,
.btn-white:hover {
	background-color: ${({ theme }) => theme.lightGray2};
}

.bg-purple,
.badge-purple {
	background-color: #7460ee !important;
}

.bg-primary,
.badge-primary {
	background-color: ${({ theme }) => theme.orange} !important;
}

.bg-success,
.badge-success {
	background-color: ${({ theme }) => theme.green7} !important;
}

.bg-info,
.badge-info {
	background-color: ${({ theme }) => theme.blue5} !important;
}

.bg-warning,
.badge-warning {
	background-color: ${({ theme }) => theme.orange2} !important;
}

.bg-danger,
.badge-danger {
	background-color: ${({ theme }) => theme.red2} !important;
}

.bg-white {
	background-color: ${({ theme }) => theme.white};
}

.text-primary,
.dropdown-menu>li>a.text-primary {
	color: ${({ theme }) => theme.orange} !important;
}

.text-success,
.dropdown-menu>li>a.text-success {
	color: ${({ theme }) => theme.green7} !important;
}

.text-danger,
.dropdown-menu>li>a.text-danger {
	color: ${({ theme }) => theme.red2} !important;
}

.text-info,
.dropdown-menu>li>a.text-info {
	color: ${({ theme }) => theme.blue5} !important;
}

.text-warning,
.dropdown-menu>li>a.text-warning {
	color: ${({ theme }) => theme.orange2} !important;
}

.text-purple,
.dropdown-menu>li>a.text-purple {
	color: #7460ee !important;
}

.text-muted {
	color: #8e8e8e !important;
	font-size: 12px;
	line-height: 14px;
	font-weight: 500;
}

.btn-purple {
	background-color: #7460ee;
	border: 1px solid #7460ee;
	color: ${({ theme }) => theme.white};
}

.btn-purple:hover,
.btn-purple:focus {
	background-color: #482fe9;
	border: 1px solid #482fe9;
}

.btn-primary {
	background-color: ${({ theme }) => theme.greenLight};
	border: 1px solid ${({ theme }) => theme.greenLight};
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
	background-color: #66e6c1;
	border: 1px solid #66e6c1;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
	background-color: #8df7d9;
	border: 1px solid #8df7d9;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
	background-color: ${({ theme }) => theme.greenLight};
	border-color: ${({ theme }) => theme.greenLight};
	color: ${({ theme }) => theme.white};
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: ${({ theme }) => theme.orange};
	border-color: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

.btn-success {
	background-color: ${({ theme }) => theme.greenLight};
	border: 1px solid ${({ theme }) => theme.greenLight};
	/* font-size: 21.9195px; */
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
	background: #66e6c1;
	border: 1px solid #66e6c1;
	color: ${({ theme }) => theme.white};
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
	background: ${({ theme }) => theme.green6};
	border: 1px solid ${({ theme }) => theme.green6}
}

.btn-success.disabled,
.btn-success:disabled {
	background-color: ${({ theme }) => theme.green7};
	border-color: ${({ theme }) => theme.green7};
	color: ${({ theme }) => theme.white};
}

.btn-info {
	background-color: ${({ theme }) => theme.blue5};
	border: 1px solid ${({ theme }) => theme.blue5}
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}


.btn-info.disabled,
.btn-info:disabled {
	background-color: ${({ theme }) => theme.blue5};
	border-color: ${({ theme }) => theme.blue5};
	color: ${({ theme }) => theme.white};
}

.btn-warning {
	background: ${({ theme }) => theme.orange2};
	border: 1px solid ${({ theme }) => theme.orange2}
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
	background: ${({ theme }) => theme.orange3};
	border: 1px solid ${({ theme }) => theme.orange3}
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
	background: ${({ theme }) => theme.orange3};
	border: 1px solid ${({ theme }) => theme.orange3}
}


.btn-warning.disabled,
.btn-warning:disabled {
	background-color: ${({ theme }) => theme.orange2};
	border-color: ${({ theme }) => theme.orange2};
	color: ${({ theme }) => theme.white};
}

.btn-danger {
	background: ${({ theme }) => theme.red2};
	border: 1px solid ${({ theme }) => theme.red2}
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
	background-color: ${({ theme }) => theme.red3};
	border: 1px solid ${({ theme }) => theme.red3}
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
	background: ${({ theme }) => theme.orange3};
	border: 1px solid ${({ theme }) => theme.orange3}
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
	background-color: ${({ theme }) => theme.red2};
	border-color: ${({ theme }) => theme.red2};
	color: ${({ theme }) => theme.white};
}

.btn-custom {
	background: ${({ theme }) => theme.orange};
	background: -moz-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: -webkit-linear-gradient(left, ${({ theme }) =>
    theme.orange} 0%, ${({ theme }) => theme.red4} 100%);
	background: -ms-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: linear-gradient(to right, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	color: ${({ theme }) => theme.white};
}

.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
	background: ${({ theme }) => theme.orange};
	background: -moz-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: -webkit-linear-gradient(left, ${({ theme }) =>
    theme.orange} 0%, ${({ theme }) => theme.red4} 100%);
	background: -ms-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: linear-gradient(to right, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	color: ${({ theme }) => theme.white};
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background-color: ${({ theme }) => theme.orange};
	border-color: ${({ theme }) => theme.orange};
}

.pagination>li>a,
.pagination>li>span {
	color: ${({ theme }) => theme.orange};
}

.page-link:hover {
	color: ${({ theme }) => theme.orange};
}

.page-link:focus {
	box-shadow: unset;
}

.page-item.active .page-link {
	background-color: ${({ theme }) => theme.orange};
	border-color: ${({ theme }) => theme.orange};
}

.dropdown-menu {
	background-color: ${({ theme }) => theme.white};
}

.navbar-nav .open .dropdown-menu {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.translucentBlack4};
}


.btn-link {
	color: ${({ theme }) => theme.gray3};
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: ${({ theme }) => theme.orange};
}

.list-group-item {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.custom-file .custom-file-label {
	background-color: ${({ theme }) => theme.darkGray8};
	border: 1px solid ${({ theme }) => theme.darkGray8};
}

.custom-file-label::after {
	background-color: ${({ theme }) => theme.darkGray2};
}


.bg-inverse-primary {
	background: ${({ theme }) => theme.translucentBlue} !important;
	color: #4d8af0 !important;
}

.bg-inverse-secondary {
	background: rgba(194, 198, 209, .2) !important;
	color: ${({ theme }) => theme.gray4} !important;
}

.bg-inverse-success {
	background-color: rgba(15, 183, 107, 0.12) !important;
	color: #26af48 !important;
}

.bg-inverse-purple {
	background: rgba(108, 97, 246, .2) !important;
	color: #6c61f6 !important;
}

.bg-inverse-warning {
	background-color: rgba(255, 152, 0, 0.12) !important;
	color: #f39c12 !important;
}

.bg-inverse-danger {
	background-color: rgba(242, 17, 54, 0.12) !important;
	color: #e63c3c !important;
}

.bg-inverse-light {
	background: ${({ theme }) => theme.translucentGray2} !important;
	color: ${({ theme }) => theme.lightGray4} !important;
}

.bg-inverse-dark {
	background: ${({ theme }) => theme.translucentGray3} !important;
	color: ${({ theme }) => theme.gray16} !important;
}

.bg-inverse-white {
	background: ${({ theme }) => theme.translucentWhite1} !important;
	color: ${({ theme }) => theme.white} !important;
}

.bg-inverse-info {
	background-color: ${({ theme }) => theme.translucentTeal1} !important;
	color: #1db9aa !important;
}

.bg-inverse-default {
	background-color: rgba(40, 52, 71, 0.12) !important;
	color: ${({ theme }) => theme.darkGray7} !important;
}

.bg-gradient-primary {
	background: linear-gradient(to right, #6e00ff 10%, #6e00ff 100%);
}

.bg-gradient-secondary {
	background: linear-gradient(to bottom right, #f35c38 0, #f35c38 100%);
}

.bg-gradient-success {
	background: linear-gradient(to bottom right, #0ba408 0, #28e224 100%);
}

.bg-gradient-info {
	background: linear-gradient(to bottom right, #69c7de 0, #1e9fc4 100%);
}

.bg-gradient-warning {
	background: linear-gradient(to bottom right, #ecd53e 0, #efaf28 100%)
}

.bg-gradient-teal {
	background: linear-gradient(to bottom right, #00796b 0, #4db6ac 100%);
}

.bg-gradient-blue {
	background: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}

.bg-gradient-danger {
	background: linear-gradient(to right, #f95058 0, #f33540 100%);
}

.bg-gradient-purple {
	background: linear-gradient(to right, #8a56e6 0, #6f3cca 100%);
}

.btn-light {
	background-color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.4);
	color: ${({ theme }) => theme.gray3};
}

.modal .form-control {
	border-color: ${({ theme }) => theme.lightGray3};
	background-color: ${({ theme }) => theme.white};
	color: ${({ theme }) => theme.gray18};
}

.modal label {
	color: ${({ theme }) => theme.gray18};
}

.modal-title {
	color: ${({ theme }) => theme.white};
}

.modal .select2-container--default .select2-selection--single {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

.form-control:disabled,
.form-control[readonly] {
	background-color: ${({ theme }) => theme.darkGray6};
	opacity: 1;
}

.modal .form-control:disabled,
.modal .form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}

.page-link {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
}

.page-item.disabled .page-link {
	background-color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.4);
	color: ${({ theme }) => theme.gray19};
}

@-webkit-keyframes taskHighlighter {
	0% {
		background: #FEEB99;
	}

	100% {
		background: ${({ theme }) => theme.white};
	}
}

@keyframes taskHighlighter {
	0% {
		background: #FEEB99;
	}

	100% {
		background: ${({ theme }) => theme.white};
	}
}

/*-----------------
	6. Toggle Button
-----------------------*/


.checktoggle {
	background-color: #e0001a;
}

.checktoggle:after {
	background-color: ${({ theme }) => theme.white};
}

.check:checked+.checktoggle {
	background-color: ${({ theme }) => theme.green7};
}


.onoffswitch-inner:before,
.onoffswitch-inner:after {
	color: ${({ theme }) => theme.white};
}

.onoffswitch-inner:before {
	background-color: ${({ theme }) => theme.green7};
	color: ${({ theme }) => theme.white};
}

.onoffswitch-inner:after {
	background-color: ${({ theme }) => theme.gray6};
	color: ${({ theme }) => theme.white};
}

.onoffswitch-switch {
	background: ${({ theme }) => theme.white};
}

.switch input:checked+span {
	background-color: ${({ theme }) => theme.green7};
}

.switch span {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid #eee;
	border-color: ${({ theme }) => theme.translucentBlack4};
}

.switch span:after {
	background-color: ${({ theme }) => theme.white};
	-webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}


.rating-list span a {
	background-color: ${({ theme }) => theme.gray6};
	color: ${({ theme }) => theme.gray18};
}

.rating-list span.rating-bad a {
	background-color: #ad0d20;
	color: ${({ theme }) => theme.white};
}

.rating-list span.rating-normal a {
	background-color: #dccc00;
	color: ${({ theme }) => theme.white};
}

.rating-list span.rating-good a {
	background-color: #00a100;
	color: ${({ theme }) => theme.white};
}

.custom_check {
	color: #555;
}

.custom_check input:checked~.checkmark {
	background-color: ${({ theme }) => theme.orange};
}

.custom_check .checkmark {
	border: 1px solid #d2d8dd;
	background-color: ${({ theme }) => theme.white};
}


.custom_radio {
	color: #555;
}

.custom_radio .checkmark {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray6};
}

.custom_radio .checkmark:after {
	background: ${({ theme }) => theme.orange};
}

.modal {
	color: ${({ theme }) => theme.darkGray1};
}

.custom-modal .close {
	background-color: #a0a0a0;
	color: ${({ theme }) => theme.white};
}

.modal .card {
	background-color: ${({ theme }) => theme.white};
	border-color: rgba(0, 0, 0, 0.125);
}

.modal .card-box .card-title {
	color: ${({ theme }) => theme.gray18};
}

.modal .form-focus .focus-label {
	color: ${({ theme }) => theme.gray10};
}

.modal .punch-det {
	background-color: ${({ theme }) => theme.whiteSmoke};
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

.modal .stats-box {
	background-color: ${({ theme }) => theme.whiteSmoke};
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

.modal .card-title {
	color: ${({ theme }) => theme.darkGray1};
}
.header {
	background: transparent;
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.bar-icon span {
	background-color: ${({ theme }) => theme.gray3};
}



.header .has-arrow .dropdown-toggle:after {
	border-bottom: 2px solid ${({ theme }) => theme.gray3};
	border-right: 2px solid ${({ theme }) => theme.gray3};
}


.page-title-box h3 {
	color: ${({ theme }) => theme.gray3};
}

.user-menu.nav>li>a {
	color: ${({ theme }) => theme.gray3};
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}


.user-img .status {
	border: 2px solid ${({ theme }) => theme.white};
}

.status {
	background-color: #263238;
}

.status.online {
	background-color: ${({ theme }) => theme.green7};
}

.status.offline {
	background-color: ${({ theme }) => theme.red2};
}

.status.away {
	background-color: #faa937;
}


.sidebard {
	background-color: #172A3A;
}

.sidebard a {
	color: #508991;
}

.sidebard div a {
	color: #508991;
}

.sidebard div {
	color: #508991;
}

.sidebard-button {
	background-color: ${({ theme }) => theme.greenLight};
}

.sidebard a.active {
	border-right: ${({ theme }) => theme.greenLight} 7px solid;
	color: ${({ theme }) => theme.greenLight};
}

.sidebard a:hover:not(.active) {
	background-color: #508991;
	color: ${({ theme }) => theme.greenLight};
}

.sidebard div a.child-link:hover {
    background-color: #508991;
    color: ${({ theme }) => theme.greenLight};
}

.sidebard div:hover:not(.active .parent-link) {
	background-color: #508991;
	color: ${({ theme }) => theme.greenLight};
}

.sidebard-content {
	background-color: #F8F8F8;
}


.sidebar {
	background-color: ${({ theme }) => theme.darkGray1};
}


.sidebar-menu li a {
	color: ${({ theme }) => theme.gray8};
}

.sidebar-menu li a:hover {
	color: ${({ theme }) => theme.white};
}

.sidebar-menu li.active a {
	color: ${({ theme }) => theme.orange};
}

.menu-title {
	color: #ebecf1;
}


.sidebar-menu li.menu-title a {
	color: ${({ theme }) => theme.orange};
}

.sidebar-menu li.menu-title a.btn {
	color: ${({ theme }) => theme.white};
}

.sidebar-menu ul ul a.active {
	color: ${({ theme }) => theme.orange};
}

.mobile-user-menu {
	color: ${({ theme }) => theme.white};
}

.mobile-user-menu>a {
	color: ${({ theme }) => theme.white};
}

.mobile-user-menu a:hover {
	color: ${({ theme }) => theme.white};
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.55);
	border-color: ${({ theme }) => theme.translucentBlack4};
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
	color: ${({ theme }) => theme.white};
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: ${({ theme }) => theme.white};
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
	color: ${({ theme }) => theme.white};
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.55);
	border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
	color: ${({ theme }) => theme.white};
}

.navbar-btn {
	color: ${({ theme }) => theme.greenLight} !important;
	/* background: ${({ theme }) => theme.gray5}; */
	border: 1px solid ${({ theme }) => theme.greenLight};
	box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);
	border-radius: 50px;
	background-color: ${({ theme }) => theme.transparent};
}


.landing-title {
	/* color: ${({ theme }) => theme.black}; */
}

.landing-subHead {
	color: ${({ theme }) => theme.gray9};
}

.landing-text {
	color: #0F0F0F;
}

.landing-btn {
	color: ${({ theme }) => theme.greenLight};
	background: #172A3A;
	border: 0.843208px solid #BABABA;
	box-shadow: 0px 40px 58px rgba(0, 67, 70, 0.32);
}


.achievement_text {
	color: #0F0F0F;
}

.achieve_head {
	color: ${({ theme }) => theme.black};
}

.achieve_subhead {
	color: #787878;
}

.features_head {
	color: ${({ theme }) => theme.black};
}

.features_subhead {
	color: #787878;
}

.features_card {
	background: #172A3A;
	box-shadow: 0px 40px 58px rgba(0, 67, 70, 0.32);
}

.features_cardhead {
	color: ${({ theme }) => theme.greenLight};
}

.features_cardsubhead {
	color: ${({ theme }) => theme.white}EFF;
}



.how_it_work_head {
	color: ${({ theme }) => theme.black};
}

.how_it_work_subhead {
	color: #787878;
}
.how_it_work_text {
	color: #081C15;
}

.compare_head {
	color: #172A3A;
}

.compare_card_midas {
	background: #172A3A;
}

.compare_midas_text {
	color: ${({ theme }) => theme.white};
}

.compare_bulk_text {
	color: ${({ theme }) => theme.black};
}

.compare_card_bulk {
	background: ${({ theme }) => theme.lightGray2};
}

.compare_cardtext {
	color: ${({ theme }) => theme.black};
}


.get_started_card {
	background: #172A3A;
}

.get_started_header {
	color: ${({ theme }) => theme.greenLight};
}

.get_started_subheader {
	color: ${({ theme }) => theme.white};
}

.get_started_button {
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	border: 2.46701px solid ${({ theme }) => theme.white};
}

.faq_header {
	color: ${({ theme }) => theme.black};
}

.faq_card {
	background: #F2F2F2;
}


.faq_cardheader {
	color: #508991;
}

.faq_cardtext {
	color: #787878;
}

.footer {
	background: ${({ theme }) => theme.body};
}


.footer_left_text {
	color: #6B7E8E;
}

.footer_address {
	color: ${({ theme }) => theme.greenLight};
}


.footer_right_text {
	color: ${({ theme }) => theme.white};
}

.dashboard-name {
	color: ${({ theme }) => theme.black};
}

.card-headername {
	color: ${({ theme }) => theme.black};
}

.dashboard-subname {
	color: ${({ theme }) => theme.gray7};
}

.view-quickpay {
	color: ${({ theme }) => theme.greenLight};
}

.upload-excel-container {
	background-color: ${({ theme }) => theme.whiteSmoke};
	border-color: ${({ theme }) => theme.lightGray5};
}

.card-headersubname {
	color: ${({ theme }) => theme.gray23};
}



.page-header .breadcrumb {
	color: ${({ theme }) => theme.gray19};
}

.page-header .breadcrumb a {
	color: ${({ theme }) => theme.gray3};
}

.card {
	box-shadow: 0px 40px 58px rgba(0, 67, 70, 0.32);
	background: ${({ theme }) => theme.white};
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.card-title {
	color: ${({ theme }) => theme.gray3};
}

.card-header {
	background-color: ${({ theme }) => theme.darkGray1};
	border-color: ${({ theme }) => theme.darkGray2};
}

.card-footer {
	background-color: ${({ theme }) => theme.darkGray1};
	border-color: ${({ theme }) => theme.darkGray2};
}

.pagination-number {
	border-right: rgb(122, 69, 69);
}


.page-title {
	color: ${({ theme }) => theme.gray3};
}

.page-sub-title {
	color: ${({ theme }) => theme.gray3};
}

.add-btn {
	background-color: ${({ theme }) => theme.greenLight};
	border: 1px solid ${({ theme }) => theme.greenLight};
	color: ${({ theme }) => theme.white};
}

.add-btn:hover,
.add-btn:focus,
.add-btn:active {
	background-color: #8df7d9;
	border: 1px solid #8df7d9;
	color: ${({ theme }) => theme.white};
}


.cal-icon:after {
	color: #979797;
}



.account-subtitle {
	color: ${({ theme }) => theme.gray3};
}

.account-box {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray1};
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.account-box .account-btn {
	background: ${({ theme }) => theme.orange};
	background: -moz-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: -webkit-linear-gradient(left, ${({ theme }) =>
    theme.orange} 0%, ${({ theme }) => theme.red4} 100%);
	background: -ms-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: linear-gradient(to right, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
}


.account-box .form-control {
	background-color: ${({ theme }) => theme.darkGray8};
	border: 1px solid ${({ theme }) => theme.darkGray8};
}

.account-box label {
	color: #7f8fa4;
}


.account-footer-text {
	color: ${({ theme }) => theme.gray9};
}

.account-header-text {
	color: ${({ theme }) => theme.black};
}


.account-connect-writeup {
	color: ${({ theme }) => theme.greenLight};
}

.account-writeup {
	color: ${({ theme }) => theme.gray9};
}


.account-footer a {
	color: ${({ theme }) => theme.orange};
}

.account-footer a:hover {
	color: ${({ theme }) => theme.orange};
}

/*-----------------
	12. Dashboard
-----------------------*/

.card-table .card-footer {
	border-color: ${({ theme }) => theme.background};
}

.card-table .card-footer a {
	color: ${({ theme }) => theme.gray3};
}

.card-title>a.btn {
	color: ${({ theme }) => theme.white};
}

.custom-table tr {
	background-color: ${({ theme }) => theme.whiteSmoke};
	color: ${({ theme }) => theme.gray23};

}


.dash-widget-icon {
	background-color: #CFFDF0;
	color: ${({ theme }) => theme.greenLight};
}

.dash-widget-info>h3 {
	color: #172A3A;
}

.dash-widget-info>span {
	color: ${({ theme }) => theme.gray23};
}


.leave-info-box {
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.load-more a {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.lightGray5};
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

/*-----------------
	13. Activity
-----------------------*/

.activity .activity-list>li .activity-content {
	background-color: ${({ theme }) => theme.darkGray1};
}

.activity-list::before {
	background: ${({ theme }) => theme.gray6};
}

.activity .activity-list li::before {
	background: ${({ theme }) => theme.gray6};
}

.activity-list li::before {
	background: #eee;
}

.activity-list>li {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.activity-list>li .activity-user {
	background: ${({ theme }) => theme.darkGray1};
}

.activity-list>li .activity-content {
	background-color: ${({ theme }) => theme.white};
}

.activity-list>li .activity-content .timeline-content {
	color: #9e9e9e;
}

.activity-list>li .activity-content .timeline-content a {
	color: ${({ theme }) => theme.gray24};
}

.activity-list>li .time {
	color: ${({ theme }) => theme.gray25};
}

/*-----------------
	14. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid ${({ theme }) => theme.gray6};
}

.select2-container--default .select2-selection--single {
	background-color: ${({ theme }) => theme.darkGray8};
	border: 1px solid ${({ theme }) => theme.darkGray8};
}


.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: ${({ theme }) => theme.gray6} transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent ${({ theme }) => theme.gray6};
}



.select2-container--default .select2-selection--single .select2-selection__rende${({
  theme,
}) => theme.red} {
	color: #676767;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: ${({ theme }) => theme.orange};
}

/*-----------------
	15. Nav Tabs
-----------------------*/

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	background-color: #eee;
	color: ${({ theme }) => theme.gray18};
}

.nav-tabs.nav-justified>li>a:hover,
.nav-tabs.nav-justified>li>a:focus {
	border-bottom-color: ${({ theme }) => theme.gray6};
}


.nav-tabs.nav-tabs-solid>li>a {
	color: ${({ theme }) => theme.gray3};
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
	background-color: ${({ theme }) => theme.orange};
	border-color: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}


.nav-tabs-justified>li>a:hover,
.nav-tabs-justified>li>a:focus {
	border-bottom-color: ${({ theme }) => theme.gray6};
}


/*-----------------
	16. Holidays
-----------------------*/

.custom-table tr.holiday-completed {
	color: #616a72;
}


/*-----------------
	17. Delete Modal
-----------------------*/

.form-header h3 {
	color: ${({ theme }) => theme.gray18};
}

.form-header p {
	color: #929292;
}


.continue-btn {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.orange};

	color: ${({ theme }) => theme.orange};
}

.continue-btn:hover,
.continue-btn:focus,
.continue-btn:active {
	background-color: ${({ theme }) => theme.orange};
	border: 1px solid ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

.cancel-btn {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.orange};
}

.cancel-btn:hover,
.cancel-btn:focus,
.cancel-btn:active {
	background-color: ${({ theme }) => theme.orange};
	border: 1px solid ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

/*-----------------
	18. Edit Profile
-----------------------*/



.fileupload.btn {
	background: rgba(33, 33, 33, 0.5);
}


.btn-text {
	color: ${({ theme }) => theme.white};
}

/*-----------------
	19. Chat
-----------------------*/

.chat-window {
	background-color: #23272b;
}

.fixed-header {
	background-color: ${({ theme }) => theme.darkGray8};
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}


.fixed-header .user-info a {
	color: #76838f;
}

.typing-text {
	color: ${({ theme }) => theme.orange};
}

.last-seen {
	color: #a3afb7;
}
.custom-menu.nav>li>a {
	color: ${({ theme }) => theme.orange};
}


.search-box .btn {
	color: ${({ theme }) => theme.gray3};
}

.search-box .input-group {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
}



.chat-right .chat-content {
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(0, 0, 0, 0.3);
	color: ${({ theme }) => theme.white};
}

.chat-time {
	color: rgba(255, 255, 255, 0.6);
}


.chat-bubble .chat-action-btns a {
	color: #8c8c8c;
}

.chat-line {
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}

.chat-date {
	background-color: #23272b;
	color: ${({ theme }) => theme.orange};
}




.chat-left .chat-avatar {
	float: left
}


.chat-left .chat-content {
	color: #76838f;
}

.avatar {
	background-color: #101010;
	color: ${({ theme }) => theme.white};
}

.avatar:hover {
	color: ${({ theme }) => theme.white};
}

.chat-left .chat-time {
	color: #a3afb7
}

.attach-list {
	color: ${({ theme }) => theme.lightGray5};
}


.chat-footer {
	background-color: ${({ theme }) => theme.darkGray8};
	border-top: 1px solid ${({ theme }) => theme.darkGray2};
}


.message-bar .link {
	color: ${({ theme }) => theme.gray2};
}


.message-area .input-group .form-control {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
	color: #555;
}


.change-img {
	background-color: rgba(0, 0, 0, 0.3);
	color: ${({ theme }) => theme.white};
}

.user-name {
	color: ${({ theme }) => theme.gray3};
}

.user-det-list .text-muted {
	color: ${({ theme }) => theme.gray10};
}

.files-list>li {
	border-bottom: 1px solid ${({ theme }) => theme.darkGray8};
}


.files-icon {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
}

.files-icon i {
	color: ${({ theme }) => theme.gray3};
}


.file-name a {
	color: ${({ theme }) => theme.gray3};
}


.file-date {
	color: ${({ theme }) => theme.gray10};
}

.file-author a {
	color: ${({ theme }) => theme.orange};
}

.files-action>li>a {
	color: ${({ theme }) => theme.gray2};
}


.chat-placeholder {
	background: rgba(69, 81, 97, 0.6);
	color: ${({ theme }) => theme.white};
}

.chat-right .chat-content.img-content {
	color: #76838f;
}

.chat-right .img-content .chat-time {
	color: #a3afb7;
}

.chat-user-list .media {
	border-bottom: 1px solid ${({ theme }) => theme.darkGray8};
	background-color: ${({ theme }) => theme.darkGray8};
}

.chat-user-list .media:hover {
	background-color: ${({ theme }) => theme.lightGray2};
}

.designation {
	color: #9e9e9e;
}

.online-date {
	color: #9e9e9e;
}

.drop-zone {
	border: 2px dashed ${({ theme }) => theme.lightGray5};
}

.upload-list .file-list {
	background-color: ${({ theme }) => theme.darkGray8};
	border-top: 1px solid ${({ theme }) => theme.darkGray2};
}

.upload-list .file-size {
	color: ${({ theme }) => theme.gray10};
}

.upload-list .file-close {
	color: ${({ theme }) => theme.lightGray5};
}

.upload-list .file-close:hover {
	color: ${({ theme }) => theme.red2}
}

.upload-list .upload-process {
	color: ${({ theme }) => theme.gray10};
}

.upload-list .file-name i {
	color: #fda75c;
}

.upload-drop-zone {
	background-color: ${({ theme }) => theme.darkGray8};
	border: 2px dashed ${({ theme }) => theme.lightGray5};
	color: ${({ theme }) => theme.gray6};
}

.upload-drop-zone.drop {
	color: ${({ theme }) => theme.gray18};
	border-color: ${({ theme }) => theme.gray18};
}


.files-share-list {
	border: 1px solid ${({ theme }) => theme.lightGray5};
}

.modal .chat-user-list .media {
	border-bottom: 1px solid ${({ theme }) => theme.lightGray5};
	background-color: ${({ theme }) => theme.white};
}

.modal .chat-user-list .media:hover {
	background-color: ${({ theme }) => theme.lightGray2};
}

.modal .chat-user-list .media .user-name {
	color: ${({ theme }) => theme.gray18};
}

/*-----------------
	20. Focus Label
-----------------------*/

.form-focus.focused .form-control::-webkit-input-placeholder {
	color: ${({ theme }) => theme.lightGray5};
}

.form-focus .select2-container .select2-selection--single {
	border: 1px solid ${({ theme }) => theme.darkGray8};
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #5b5e61 transparent transparent;
}

.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent ${({ theme }) => theme.gray6};
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__rende${({
  theme,
}) => theme.red} {
	color: ${({ theme }) => theme.gray3};
}

.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: ${({ theme }) => theme.red4};
}

/*-----------------
	21. Leave
-----------------------*/

/*-----------------
	22. Employee
-----------------------*/

.action-icon {
	color: ${({ theme }) => theme.gray2};
}


.profile-widget {
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.profile-widget .user-name>a {
	color: ${({ theme }) => theme.black};
}
.profile-small {
	color: ${({ theme }) => theme.black};
}


.mobile-no>a {
	color: ${({ theme }) => theme.gray2};
}

.staff-mail>a {
	color: ${({ theme }) => theme.gray2};
}

.view-icons .btn {
	color: ${({ theme }) => theme.gray10};
}

.view-icons .btn.active {
	color: ${({ theme }) => theme.greenLight};
	background-color: #CAF3E8;
}

/*-----------------
	23. Events
-----------------------*/


.fc-day {
	background: ${({ theme }) => theme.darkGray8};
}

.fc th.fc-widget-header {
	background: #21272c;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #374149;
}

.fc-unthemed td.fc-today {
	background: #21272c;
}

.fc-button {
	background: #2c2f32;
	color: ${({ theme }) => theme.gray3};
}

.fc-state-hover {
	background: #f3f3f3;
}

.fc-state-highlight {
	background: ${({ theme }) => theme.background};
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: ${({ theme }) => theme.orange} !important;
	color: ${({ theme }) => theme.white} !important;
}

.fc-cell-overlay {
	background: ${({ theme }) => theme.background};
}

.fc-unthemed .fc-today {
	background: #21272c;
}

.external-event.bg-primary {
	background-color: rgba(127, 193, 252, 0.3) !important;
	color: #7fc1fc;
}

.external-event.bg-success {
	background-color: rgba(75, 211, 150, 0.3) !important;
	color: #4bd396;
}

.external-event.bg-info {
	background-color: rgba(58, 201, 214, 0.3) !important;
	color: #3ac9d6;
}

.external-event.bg-warning {
	background-color: rgba(249, 200, 81, 0.3) !important;
	color: #f9c851;
}

.external-event.bg-danger {
	background-color: rgba(245, 112, 122, 0.3) !important;
	color: #f5707a;
}

.external-event.bg-pink {
	background-color: rgba(240, 98, 146, 0.3) !important;
	color: #f06292;
}

.external-event.bg-purple {
	background-color: rgba(107, 95, 181, 0.3) !important;
	color: #6b5fb5;
}

.external-event.bg-inverse {
	background-color: rgba(59, 62, 71, 0.3) !important;
	color: #3b3e47;
}

.external-event.bg-orange {
	background-color: rgba(255, 152, 0, 0.3) !important;
	color: #ff9800;
}

.external-event.bg-brown {
	background-color: rgba(141, 110, 99, 0.3) !important;
	color: #8d6e63;
}

.external-event.bg-teal {
	background-color: rgba(38, 166, 154, 0.3) !important;
	color: #26a69a;
}

/*-----------------
	24. Profile
-----------------------*/


.personal-info li .title {
	color: ${({ theme }) => theme.gray3};
}

.personal-info li .text {
	color: ${({ theme }) => theme.gray3};
}

.edit-icon {
	border: 1px solid ${({ theme }) => theme.lightGray3};
	color: ${({ theme }) => theme.lightGray5};
}

.edit-icon:hover {
	background-color: ${({ theme }) => theme.orange};
	border-color: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

.delete-icon {
	color: #e30b0b;
}

.delete-icon:hover {
	color: #e30b0b;
}


.experience-list::before {
	background: ${({ theme }) => theme.darkGray8};
}

.experience-list>li .experience-content .timeline-content {
	color: ${({ theme }) => theme.gray3};
}

.experience-list>li .experience-content .timeline-content a.name {
	color: ${({ theme }) => theme.gray3};
}

.experience-list>li .time {
	color: ${({ theme }) => theme.gray25};
}

.before-circle {
	background-color: ${({ theme }) => theme.darkGray8};
}

.skills>span {
	border: 1px solid ${({ theme }) => theme.darkGray8};
}

.profile-info-left {
	border-right: 2px dashed ${({ theme }) => theme.gray6};
}

.bootstrap-tagsinput {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

.add-more a {
	color: ${({ theme }) => theme.orange};
}

.add-more a:hover {
	color: #ff851a;
}


/*-----------------
	25. Notifications
-----------------------*/

.notifications .notification-heading {
	color: ${({ theme }) => theme.gray24};
}

.notifications .notification-time {
	color: ${({ theme }) => theme.gray25};
}

.notifications ul.notification-list>li {
	border-bottom: 1px solid ${({ theme }) => theme.lightGray4};
}


.notifications ul.notification-list>li a:hover {
	background-color: ${({ theme }) => theme.gray4};
}

.notifications .media {
	border-bottom: 1px solid ${({ theme }) => theme.lightGray4};
}

.notifications .media a:hover {
	background-color: ${({ theme }) => theme.gray4};
}

.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
}

.topnav-dropdown-header .notification-title {
	color: ${({ theme }) => theme.gray18};
}

.topnav-dropdown-header .clear-noti {
	color: #f83f37;
}

.topnav-dropdown-footer a {
	color: ${({ theme }) => theme.gray3};
}

.user-menu.nav>li>a .badge {
	color: ${({ theme }) => theme.white};
}

.user-menu.nav>li>a .badge {
	background-color: ${({ theme }) => theme.red4};
}

.noti-details {
	color: #989c9e;
}

.noti-title {
	color: ${({ theme }) => theme.gray18};
}


/*-----------------
	26. Roles & Permissions
-----------------------*/

.roles-menu>ul {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray1};
}

.roles-menu>ul>li a {
	color: ${({ theme }) => theme.gray3};
}

.roles-menu>ul>li.active a {
	border-color: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.orange};
}

.roles-menu>ul>li a:hover {
	background-color: #eee;
	border-color: ${({ theme }) => theme.orange};
}


.roles-menu>ul>li a:hover .role-action {
	display: block;
}


/*-----------------
	27. Chat Right Sidebar
-----------------------*/

.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}


.list-item {
	border-bottom: 1px solid #394048;
}

.files-icon {
	background-color: ${({ theme }) => theme.darkGray8};
	border: 1px solid ${({ theme }) => theme.darkGray8};
}

.files-icon i {
	color: ${({ theme }) => theme.gray2};
}


.message-author {
	color: #7b848c;
}

.message-time {
	color: #7b848c;
}

.message-content {
	color: ${({ theme }) => theme.gray4};
}


/*-----------------
	28. Projects
-----------------------*/


.team-members>li>a {
	border: 2px solid ${({ theme }) => theme.darkGray1};
}

.all-users {
	background-color: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

.all-users:hover,
.all-users:active {
	color: ${({ theme }) => theme.white};
}


.project-title>a {
	color: ${({ theme }) => theme.gray3};
}


.avatar-dropdown .avatar-pagination {
	border-top: 1px solid ${({ theme }) => theme.translucentBlack4};
}



/*-----------------
	29. Invoice
-----------------------*/

.file-remove {
	color: #f00;
}


/*-----------------
	30. Task
-----------------------*/

.task-chat-contents {
	background-color: #23272b;
}


.task-chat-view {
	border-left: 1px solid ${({ theme }) => theme.darkGray2};
}

.task-chat-view .chat-date {
	background-color: ${({ theme }) => theme.white};
}

.followers-add {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray6};
	color: ${({ theme }) => theme.gray6};
}

.followers-add:hover {
	border: 1px solid #8c8c8c;
	color: #8c8c8c;
}


.file-attached {
	color: ${({ theme }) => theme.gray8};
}


.task-attach-img>img {
	border: 1px solid ${({ theme }) => theme.lightGray5};
}


.task-chat-view .chat-left .chat-time {
	color: #a3afb7;
}

.task-chat-user {
	color: ${({ theme }) => theme.gray8};
}

.task-time {
	color: #a3afb7;
}

.task-success {
	color: ${({ theme }) => theme.green7};
}

.task-success a {
	color: ${({ theme }) => theme.green7};
}

.task-line {
	border-color: ${({ theme }) => theme.darkGray2};
}

.task-information {
	color: #95a1a9;
}

.task-user {
	color: #95a1a9;
}

.action-circle {
	border: 1px solid ${({ theme }) => theme.gray6};
}

.action-circle .material-icons {
	color: ${({ theme }) => theme.gray6};
}

.action-circle.completed {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.action-circle.completed .material-icons {
	color: ${({ theme }) => theme.white};
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-finished {
	color: #35BA67;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete {
	color: #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle {
	border: 1px solid #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle .material-icons {
	color: #666;
}


.task-wrapper .task-list-header h3,
.task-wrapper .task-list-footer h3 {
	color: #666;
}

.task-wrapper #task-list {
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}

.task-wrapper #task-list li .task-container {
	background: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.task-wrapper #task-list li .task-container .task-label {
	color: ${({ theme }) => theme.gray3};
}

.task-wrapper #task-list li .task-container .task-action-btn .action-circle:hover {
	border: 1px solid #8c8c8c;
	background: ${({ theme }) => theme.white};
}

.task-wrapper #task-list li .task-container .task-action-btn .action-circle:hover .material-icons {
	color: #8c8c8c;
}

.task-wrapper #task-list li .task-container:hover {
	background: ${({ theme }) => theme.darkGray8};
}

.task-wrapper #task-list li.completed .task-container {
	background: ${({ theme }) => theme.darkGray8};
}

.task-wrapper #task-list li.completed .task-container .complete-btn {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.task-wrapper #task-list li.completed .task-container .complete-btn .material-icons {
	color: ${({ theme }) => theme.white};
}

.task-wrapper #task-list li.completed .task-container .complete-btn:hover {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.task-wrapper #task-list li.completed .task-container .complete-btn:hover .material-icons {
	color: ${({ theme }) => theme.white};
}

.task-wrapper #task-list li.completed .task-container .task-label {
	color: ${({ theme }) => theme.gray6};
}

.task-wrapper .task-list-footer .add-task-btn {
	border: 2px solid #9B1BCC;
}

.task-wrapper .task-list-footer .add-task-btn:hover {
	background: #9B1BCC;
}

.task-wrapper .task-list-footer .add-task-btn:hover .material-icons {
	color: #EBEEEF;
}

.task-wrapper .task-list-footer .add-task-btn .material-icons {
	color: #9B1BCC;
	font-size: 34px;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea {
	background-color: ${({ theme }) => theme.darkGray8};
	border: 1px solid ${({ theme }) => theme.darkGray2};
}
.task-wrapper .task-list-footer .new-task-wrapper textarea:focus {
	border: 1px solid #bfbfbf;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea.error {
	border: 1px solid #D93737;
}

.task-wrapper .task-list-footer .new-task-wrapper .error-message {
	color: #D93737;
}


.task-wrapper .task-list-footer .new-task-wrapper .add-new-task-btn {
	background: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
	color: ${({ theme }) => theme.gray3};
}

.notification-popup {
	background: #1F8FEF;
	border: 1px solid #1082e4;
	color: ${({ theme }) => theme.white};
}

.notification-popup.success {
	background: #35BA67;
	border: 1px solid #2fa65c;
}


.sidebar-overlay {
	background-color: rgba(0, 0, 0, 0.6);
}


.task-header .assignee-info>a {
	color: ${({ theme }) => theme.gray3};
}

.task-header .task-head-title {
	color: #8e8e8e;
}

.assignee-info:hover {
	border-color: ${({ theme }) => theme.lightGray3};
}

.remove-icon {
	background-color: ${({ theme }) => theme.lightGray3};
	color: ${({ theme }) => theme.gray18};
}

.remove-icon:hover {
	background-color: #d3d3d3;
}

.remove-icon i {
	color: #8e8e8e;
}

.due-icon span {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray6};
	color: ${({ theme }) => theme.gray6};
}


.task-due-date:hover {
	border-color: ${({ theme }) => theme.lightGray3};
}

.task-header .task-due-date>a {
	color: ${({ theme }) => theme.gray18};
}

.due-info .due-date {
	color: ${({ theme }) => theme.red2};
}


.task-desc-icon {
	color: ${({ theme }) => theme.gray6};
}

.task-textarea:hover .form-control {
	border-color: ${({ theme }) => theme.lightGray3};
}

.task-complete-btn {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
	color: ${({ theme }) => theme.gray3};
}

.task-complete-btn:hover {
	background-color: ${({ theme }) => theme.lightGray2};
	color: ${({ theme }) => theme.gray18};
}

.task-completed {
	background-color: #35ba67;
	border-color: #2fa65c;
	color: ${({ theme }) => theme.white};
}

.task-completed:hover {
	background-color: #35ba67;
	border-color: #2fa65c;
	color: ${({ theme }) => theme.white};
}

/*-----------------
	31. Project View
-----------------------*/

.project-files i {
	color: #76838f;
	font-size: 50px;
}


.table-border {
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.file-size {
	color: ${({ theme }) => theme.gray10};
}
.uploaded-box {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray6};
}

.uploaded-box .uploaded-img-name {
	color: ${({ theme }) => theme.gray18};
}


/*-----------------
	33. Attendance
-----------------------*/

.stats-info {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.stats-info h6 {
	color: ${({ theme }) => theme.gray3};
}


.stats-info h4 span {
	color: ${({ theme }) => theme.gray3};
}

.punch-det {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.punch-det p {
	color: #727272;
}


.punch-hours {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 5px solid ${({ theme }) => theme.darkGray2};
}

.modal .punch-hours {
	background-color: ${({ theme }) => theme.whiteSmoke};
	border: 5px solid ${({ theme }) => theme.lightGray3};
}


.stats-box {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.recent-activity .res-activity-list li:before {
	border: 2px solid ${({ theme }) => theme.orange};
	background: ${({ theme }) => theme.white};
}



.recent-activity .res-activity-list:after {
	border: 1px solid #e5e5e5;
}

.recent-activity .res-activity-time {
	color: ${({ theme }) => theme.lightGray5};
}

/*-----------------
	35. Client Profile
-----------------------*/
.team-members a.followers-add {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray6};
	color: ${({ theme }) => theme.gray6};
}

/*-----------------
	36. Inbox
-----------------------*/

.mail-list>li>a {
	color: #808991;
}

.mail-list>li.active>a {
	color: ${({ theme }) => theme.orange};
}

.unread .name,
.unread .subject,
.unread .mail-date {
	color: ${({ theme }) => theme.white};
}

.checked.unread .name,
.checked.unread .subject,
.checked.unread .mail-date {
	color: ${({ theme }) => theme.black};
}

.table-inbox .fa-star {
	color: #ffd200;
}

.table-inbox .star${({ theme }) => theme.red}.fa-star {
	color: #ffd200;
}



.note-editor.note-frame .note-statusbar {
	background-color: ${({ theme }) => theme.white};
}


.note-editor.note-frame .note-editing-area .note-editable {
	background-color: ${({ theme }) => theme.darkGray8};
	color: ${({ theme }) => theme.gray3};
}

.note-editor.note-frame .note-statusbar {
	background-color: ${({ theme }) => theme.darkGray8};
}

.note-editor.panel-default>.panel-heading {
	background-color: ${({ theme }) => theme.darkGray8};
}

.note-popover .popover-content,
.card-header.note-toolbar {
	background: ${({ theme }) => theme.darkGray8};
}

table.table-inbox tbody tr.checked {
	background-color: ${({ theme }) => theme.white}fcc;
}
/*-----------------
	37. Mail View
-----------------------*/


.attachments li {
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.attach-file {
	color: ${({ theme }) => theme.gray2};
}

.attach-filename {
	color: ${({ theme }) => theme.gray3};
}

.attach-filesize {
	color: #999;
}

.mailview-header {
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}

.mailview-footer {
	border-top: 1px solid ${({ theme }) => theme.darkGray2};
}

.receiver-name {
	color: ${({ theme }) => theme.gray2};
}

/*-----------------
	38. Voice call
-----------------------*/


.voice-call-avatar .call-avatar {
	border: 1px solid ${({ theme }) => theme.translucentBlack4};
	background-color: ${({ theme }) => theme.white};
}

.call-icons .call-items .call-item a {
	color: ${({ theme }) => theme.gray2};
	border: 1px solid ${({ theme }) => theme.gray6};
}

.my-video ul li img {
	border: 3px solid ${({ theme }) => theme.white};
}

.end-call a {
	background-color: #f06060;
	color: ${({ theme }) => theme.white};
}


.call-users ul li img {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.translucentBlack4};
}

.call-mute {
	background-color: rgba(0, 0, 0, 0.5);
	color: ${({ theme }) => theme.white};
}

.call-timing {
	color: #a3afb7;
}

/*-----------------
	40. Outgoing Call
-----------------------*/

.call-box .call-wrapper .call-user h4 {
	color: ${({ theme }) => theme.white};
}

.call-box .call-wrapper .call-user span {
	color: ${({ theme }) => theme.white};
}

.call-box .call-wrapper .call-items .call-item {
	background-color: rgba(255, 255, 255, 0.2);
	color: ${({ theme }) => theme.white};
}


.call-box .call-wrapper .call-items .call-item.call-end {
	background: #f06060;
	border: 1px solid #f06060;
	color: ${({ theme }) => theme.white};
}

.call-box .call-wrapper .call-items .call-item.call-start {
	background: ${({ theme }) => theme.green7};
	border: 1px solid ${({ theme }) => theme.green7};
	color: ${({ theme }) => theme.white};
}

.call-box .btn {
	background: rgba(0, 0, 0, 0);
}

@-webkit-keyframes ripple {
	0% {
		-webkit-box-shadow: 0 0 0 0 ${({ theme }) => theme.translucentBlack4};
	}

	100% {
		-webkit-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
	}
}

@keyframes ripple {
	0% {
		-moz-box-shadow: 0 0 0 0 ${({ theme }) => theme.translucentBlack4};
		box-shadow: 0 0 0 0 ${({ theme }) => theme.translucentBlack4};
	}

	100% {
		-moz-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
	}
}

/*-----------------
	41. Incoming Call
-----------------------*/

.incoming-btns {
	margin-top: 20px;
}

/*-----------------
	42. Contacts
-----------------------*/

.contacts-header {
	background-color: ${({ theme }) => theme.white};
	border-bottom: 1px solid #1a1a1a;
}


.contact-list>li {
	background-color: ${({ theme }) => theme.darkGray1};
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}

.contact-icon {
	background-color: #f3f7f9;
	border: 1px solid #e4eaec;
}

.contact-icon i {
	color: #76838f;
}

.contact-date {
	color: ${({ theme }) => theme.gray10};
}

.contact-author a {
	color: #00bf6f;
}


.contact-alphapets {
	background-color: ${({ theme }) => theme.darkGray1};
	border-left: 1px solid ${({ theme }) => theme.darkGray1};
}

.alphapets-inner a {
	color: ${({ theme }) => theme.gray3};
}

/*-----------------
	43. Chat Sidebar
-----------------------*/

.chat-sidebar {
	background-color: ${({ theme }) => theme.darkGray1};
	border-left: 1px solid ${({ theme }) => theme.darkGray2};
}

.chat-sidebar .chat-contents {
	background-color: ${({ theme }) => theme.darkGray1};
}

.chat-sidebar .chat-date {
	background-color: ${({ theme }) => theme.darkGray1};
}


.chat-sidebar .chat-left .chat-time {
	color: ${({ theme }) => theme.gray10};
}

/*-----------------
	44. Jobs
-----------------------*/

.apply-btn {
	background: ${({ theme }) => theme.orange};
	background: -moz-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: -webkit-linear-gradient(left, ${({ theme }) =>
    theme.orange} 0%, ${({ theme }) => theme.red4} 100%);
	background: -ms-linear-gradient(left, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	background: linear-gradient(to right, ${({ theme }) => theme.orange} 0%, ${({
  theme,
}) => theme.red4} 100%);
	box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
	color: ${({ theme }) => theme.white};
}

.job-list {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.job-list h4.job-department {
	color: #737882;
}

.job-list h3.job-list-title {
	color: ${({ theme }) => theme.gray3};
}

.job-list .job-list-footer {
	background-color: ${({ theme }) => theme.darkGray8};
}


.job-list .job-list-footer ul li {
	color: #737882;
}

.job-list .job-list-footer ul li i {
	color: #737882;
}
.job-types {
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.orange};
}

/*-----------------
	45. Job Details
-----------------------*/

.job-info {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid #efefef;
}

.job-title {
	color: ${({ theme }) => theme.gray3};
}

.job-info .job-dept {
	color: #737882;
}

.job-post-det {
	color: #737882;
}


.job-det-info {
	background-color: ${({ theme }) => theme.white};
}

.info-list {
	color: #737882;
}

.info-list span {
	color: ${({ theme }) => theme.orange};
}

.info-list>h5 {
	color: ${({ theme }) => theme.gray3};
}

.app-ends {
	color: #46cd38;
}

.job-btn {
	border: 2px solid ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.orange};
}

.job-btn:hover {
	background-color: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

.job-widget {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
}


.job-widget h4 {
	color: ${({ theme }) => theme.gray3};
}

.job-description>p {
	color: #737882;
}

.square-list li {
	color: #737882;
}

.text-blue {
	color: #009ce7;
}


/*-----------------
	47. Leave Settings
-----------------------*/


.leave-edit-btn {
	color: #216ef4;
}

.leave-header {
	align-items: center;
	color: ${({ theme }) => theme.white};
}

.leave-box .subtitle {
	color: #8e8e8e;
}

.leave-duallist {
	background-color: ${({ theme }) => theme.whiteSmoke};
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

/*-----------------
	48. Termination
-----------------------*/
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: ${({ theme }) => theme.orange};
}

/*-----------------
	49. Loader
-----------------------*/

#loader-wrapper {
	background-color: ${({ theme }) => theme.white};
}

/*-----------------
	51. Error
-----------------------*/

.error-page {
	background-color: #23272b;
	color: ${({ theme }) => theme.gray3};
}


.error-box h1 {
	color: ${({ theme }) => theme.orange};
}


/*-----------------
	53. OTP
-----------------------*/

.otp-input {
	background-color: ${({ theme }) => theme.darkGray8};
	border: 1px solid ${({ theme }) => theme.darkGray8};
	color: ${({ theme }) => theme.gray3};
}

/*-----------------
	55. Components
-----------------------*/

.stickyside .sidebar-menu>ul>li>a.active {
	color: ${({ theme }) => theme.orange};
}

/*-----------------
	56. Search
-----------------------*/

.search-result u {
	color: ${({ theme }) => theme.orange};
}

.search-result p {
	color: ${({ theme }) => theme.gray2};
}

.search-lists .nav-tabs.nav-tabs-solid {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

.top-nav-search .form-control {
	background-color: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.15);
}

.top-nav-search .btn {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control::-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.7);
}

.top-nav-search .form-control::placeholder {
	color: rgba(255, 255, 255, 0.7);
}

/*-----------------
	57. Knowledgebase
-----------------------*/

.topics {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.topics .topic-title {
	color: ${({ theme }) => theme.gray3};
}

.topics .topic-title a {
	color: ${({ theme }) => theme.gray3};

}

.topics .topic-title a:hover {
	color: ${({ theme }) => theme.orange};
}

.topics .topic-title a span {
	color: ${({ theme }) => theme.white};
}

.topics .topic-title a i {
	color: ${({ theme }) => theme.orange};
}


.topics .topics-list li a {
	color: ${({ theme }) => theme.gray4};
}

.topics .topics-list li a:hover {
	color: ${({ theme }) => theme.orange};
}

.topics .topics-list li:before {
	color: ${({ theme }) => theme.gray4};
}

.widget {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.widget h4 {
	color: ${({ theme }) => theme.gray3};
}

.widget-category li:before {
	color: #5e6977;
}

.widget-category li a {
	color: #5e6977;
}

.widget-category li a:hover {
	color: ${({ theme }) => theme.orange};
}


.post .meta {
	background-color: ${({ theme }) => theme.whiteSmoke};
	border: 1px solid #efefef;
}

.post .meta li {
	display: inline-block;
	color: #86939e;
}

.post .meta li span {
	color: #5e6977;
}


.feedback {
	background-color: ${({ theme }) => theme.whiteSmoke};
	border: 1px solid #efefef;
}

.feedback h3 {
	color: ${({ theme }) => theme.gray3};
}


.post .meta a {
	color: ${({ theme }) => theme.orange};
}

.widget .widget-title i {
	color: ${({ theme }) => theme.orange};
}

ul.comment-list li div.comment {
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}


ul.comment-list li .author-name>a {
	color: ${({ theme }) => theme.gray3};
}

ul.comment-list li .reply a {
	color: ${({ theme }) => theme.gray4};
}


/*-----------------
	58. FAQ
-----------------------*/

.faq-card .card-header {
	background-color: ${({ theme }) => theme.darkGray1};
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}

.faq-card .card {
	border-color: ${({ theme }) => theme.darkGray2};
}

.faq-card .card .card-header h4>a {
	color: ${({ theme }) => theme.gray3};
}

.faq-card .card .card-header h4>label {
	color: ${({ theme }) => theme.white};
}


/*-----------------
	59. Employee Dashboard
-----------------------*/

.welcome-box {
	background-color: ${({ theme }) => theme.darkGray1};
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}


.dash-list {
	background-color: white;
	border-color: ${({ theme }) => theme.gray4}8;
	color: #2e424d;
}


.e-avatar {
	background-color: ${({ theme }) => theme.white};
	border: 3px solid ${({ theme }) => theme.gray4};
}

.dash-info-list .dash-card {
	background-color: ${({ theme }) => theme.darkGray1};
	border: 1px solid ${({ theme }) => theme.darkGray2};
	color: ${({ theme }) => theme.gray4};
}

.dash-sidebar p {
	color: ${({ theme }) => theme.gray4};
}

.dash-sidebar .card {
	background-color: ${({ theme }) => theme.darkGray1};
}

.dash-stats-list+.dash-stats-list {
	border-left: 1px solid ${({ theme }) => theme.gray4};
}

.dash-stats-list h4 {
	color: ${({ theme }) => theme.gray3};
}

/*-----------------
	60. Performance Review
-----------------------*/

.review-table tr {
	background-color: ${({ theme }) => theme.darkGray1};
}

.review-header {
	background-color: ${({ theme }) => theme.darkGray1};
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}


/*-----------------
	61. Kanban Board
-----------------------*/

.task-header a {
	color: ${({ theme }) => theme.darkGray1};
}

.kanban-list>.kanban-header>.status-title {
	color: ${({ theme }) => theme.white};
}

.kanban-list>.kanban-wrap>.drag-placeholder {
	background-color: rgba(0, 0, 0, 0.05);
}


.kanban-list>.kanban-wrap>.card {
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

.kanban-list>.kanban-wrap>.card.drop-here>a {
	color: ${({ theme }) => theme.gray18};
	font-weight: 500;
}

.kanban-list>.kanban-wrap>.card:last-child {
	margin-bottom: 0;
}

.kanban-list>.kanban-wrap>.card.ui-sortable-helper {
	box-shadow: 0 2px 20px 0 #d7dceb;
}


.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #42a5f5;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer .ks-icon {
	color: #d7dceb;
}


.kanban-list.kanban-danger {
	background-color: #fef7f6;
}

.kanban-list.kanban-danger>.kanban-header {
	background-color: #ef5350;
}

.kanban-list.kanban-danger>.kanban-wrap>.card.drop-here {
	border-color: #f7c1b7;
}

.kanban-list.kanban-danger>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #ef5350;
}

.kanban-list.kanban-success {
	background-color: ${({ theme }) => theme.whiteSmoke};
}

.kanban-list.kanban-success>.kanban-header {
	background-color: ${({ theme }) => theme.green2};
}

.kanban-list.kanban-success>.kanban-wrap>.card.drop-here {
	border-color: #abebc6;
}

.kanban-list.kanban-success>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: ${({ theme }) => theme.green2};
}

.kanban-list.kanban-info {
	background-color: ${({ theme }) => theme.whiteSmoke};
}

.kanban-list.kanban-info>.kanban-header {
	background-color: #42a5f5;
}

.kanban-list.kanban-info>.kanban-wrap>.card.drop-here {
	border-color: #a6dbf9;
}

.kanban-list.kanban-warning {
	background-color: ${({ theme }) => theme.whiteSmoke};
}

.kanban-list.kanban-warning>.kanban-header {
	background-color: #ffb300;
}

.kanban-list.kanban-warning>.kanban-wrap>.card.drop-here {
	border: 1px solid #f2e49e;
}

.kanban-list.kanban-warning>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #ffb300;
}

.task-board-header a {
	color: ${({ theme }) => theme.darkGray1};
}

.progress>.progress-bar {
	background-color: #25628F;
}


.kanban-list>.kanban-header>.status-title {
	color: ${({ theme }) => theme.white};
}

.kanban-list>.kanban-wrap>.card {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.lightGray3};
}

.kanban-list>.kanban-wrap>.card.active {
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.kanban-list>.kanban-wrap>.card.ui-sortable-helper {
	box-shadow: 0 2px 20px 0 #d7dceb;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #42a5f5;
}


.kanban-list.kanban-danger>.kanban-wrap>.card.active {
	border-color: #ef5350;
}

.kanban-list.kanban-success>.kanban-wrap>.card.active {
	border-color: ${({ theme }) => theme.green2};
}

.kanban-list.kanban-info>.kanban-wrap>.card.active {
	border-color: #42a5f5;
}

.kanban-list.kanban-warning>.kanban-wrap>.card.active {
	border-color: #ffb300;
}

.kanban-list.kanban-danger {
	background-color: #fef7f6;
}

.kanban-list.kanban-danger>.kanban-header {
	background-color: #ef5350;
}

.kanban-list.kanban-danger>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #ef5350;
}

.kanban-list.kanban-success {
	background-color: ${({ theme }) => theme.whiteSmoke};
}

.kanban-list.kanban-success>.kanban-header {
	background-color: ${({ theme }) => theme.green2};
}

.kanban-list.kanban-success>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: ${({ theme }) => theme.green2};
}

.kanban-list.kanban-info {
	background-color: ${({ theme }) => theme.whiteSmoke};
}

.kanban-list.kanban-info>.kanban-header {
	background-color: #42a5f5;
}

.kanban-list.kanban-warning {
	background-color: ${({ theme }) => theme.whiteSmoke};
}

.kanban-list.kanban-warning>.kanban-header {
	background-color: #ffb300;
}

.kanban-list.kanban-warning>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #ffb300;
}

.kanban-list.kanban-purple {
	background-color: #f1effd;
}

.kanban-list.kanban-purple>.kanban-header {
	background-color: #7460ee;
}

.kanban-list.kanban-purple>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #7460ee;
}

.kanban-list.kanban-primary {
	background-color: ${({ theme }) => theme.white}5ec;
}

.kanban-list.kanban-primary>.kanban-header {
	background-color: ${({ theme }) => theme.orange};
}

.kanban-list.kanban-primary>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: ${({ theme }) => theme.orange};
}


.kanban-action>a {
	color: ${({ theme }) => theme.white};
	background-color: rgba(0, 0, 0, 0.3);
}



.board-control>.board-control-input:checked~.board-indicator:before {
	color: ${({ theme }) => theme.white};
}

.board-control.board-primary>.board-indicator {
	background: ${({ theme }) => theme.orange};
}

.board-control.board-success>.board-indicator {
	background: ${({ theme }) => theme.green2};
}

.board-control.board-info>.board-indicator {
	background: #42a5f5;
}

.board-control.board-purple>.board-indicator {
	background: #7460ee;
}

.board-control.board-warning>.board-indicator {
	background: #ffb300;
}

.board-control.board-danger>.board-indicator {
	background: #ef5350;
}



.board-view-header .view-icons {
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray6};
}

.pro-progress-bar .progress {
	background-color: ${({ theme }) => theme.lightGray3};
}

.pro-progress-bar .progress>.progress-bar {
	background-color: ${({ theme }) => theme.orange};
}

.pro-teams .avatar-group .avatar .border {
	border: 3px solid ${({ theme }) => theme.white} !important;
}

.border-white {
	border-color: ${({ theme }) => theme.white} !important;
}import { screen } from '@testing-library/react';


.pro-teams .avatar-title {
	background-color: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

.task-follower-list span i {
	color: #f00;
}

.add-new-task a {
	color: ${({ theme }) => theme.gray18};
}
/*-----------------
	62. File Manager
-----------------------*/

.wday-box .checkmark {
	border: 2px solid ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.orange};
}

.wday-box input:checked~.checkmark {
	background: ${({ theme }) => theme.orange};
	color: ${({ theme }) => theme.white};
}

.user-add-shedule-list a {
	border: 1px dashed #a7a7a7;
	color: #a7a7a7;
}

.user-add-shedule-list h2 a {
	border: 2px dashed #1EB53A;
	color: #1EB53A;
}

.file-wrap {
	border: 1px solid ${({ theme }) => theme.darkGray2};
}

.file-wrap .file-sidebar {
	border-right: 1px solid ${({ theme }) => theme.darkGray2};
}

.file-wrap .file-sidebar .file-header {
	background-color: ${({ theme }) => theme.darkGray1};
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
	color: #76838f;
}


.file-wrap .file-sidebar .file-search {
	background-color: #23272b;
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}

.file-wrap .file-sidebar .file-search .input-group .form-control {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
}

.file-wrap .file-sidebar .file-search .input-group .form-control:focus {
	border-color: rgba(0, 0, 0, 0.4);
}

.file-wrap .file-sidebar .file-search .input-group .input-group-prepend {
	color: #76838f;
}

.file-wrap .file-sidebar .file-pro-list {
	background-color: #23272b;
}

.file-side-close {
	background-color: #eee;
	border: 1px solid ${({ theme }) => theme.lightGray3};
	color: ${({ theme }) => theme.lightGray5};
}

.file-wrap .file-content .file-header {
	background-color: #23272b;
	border-bottom: 1px solid #e0e3e4;
}

.file-wrap .file-content .file-body {
	background-color: #23272b;
}

.dropdown-file .dropdown-link {
	color: ${({ theme }) => theme.gray2};
}

.dropdown-file .dropdown-link:hover,
.dropdown-file .dropdown-link:focus {
	color: #1b2e4b;
}

.card-file .card-footer {
	color: #a9a9a9;
}


.card-file h6 a {
	color: #76838f;
}

.card-file span {
	color: ${({ theme }) => theme.gray10};
}

.card-file-thumb {
	background-color: ${({ theme }) => theme.darkGray8};
	color: ${({ theme }) => theme.gray3};
}


.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header {
	background: ${({ theme }) => theme.darkGray1};
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header>span {
	color: #76838f;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-sidebar-toggle {
	color: #76838f;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>a,
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>span {
	color: #76838f;
}


.file-wrap .file-content .file-search {
	background-color: #23272b;
	border-bottom: 1px solid ${({ theme }) => theme.darkGray2};
}


.file-wrap .file-content .file-search .input-group .form-control {
	background-color: rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.4);
}

.file-wrap .file-content .file-search .input-group .form-control:focus {
	border-color: rgba(0, 0, 0, 0.4);
}

.file-wrap .file-content .file-search .input-group .input-group-prepend {
	color: #76838f;
}

.file-menu li a {
	color: #76838f;
}

.file-menu li a i {
	color: #878787;
}

.file-menu li a:hover,
.file-menu li.active a,
.file-menu li a:focus {
	background: rgba(33, 33, 33, 0.05);
}

/*-----------------
	63. Subscriptions
-----------------------*/

.pricing-box ul li i {
	color: #00bf6f;
}


/*-----------------
	64. Responsive
-----------------------*/

@media only screen and (min-width: 991px) {
	#toggle_btn {
		color: ${({ theme }) => theme.white};
	}
}

@media only screen and (min-width: 768px) {

	.nav-tabs.nav-justified.nav-tabs-top {
		border-bottom: 1px solid ${({ theme }) => theme.darkGray6};
	}

	.nav-tabs.nav-tabs-top>li.open>a,
	.nav-tabs.nav-tabs-top>li>a:hover,
	.nav-tabs.nav-tabs-top>li>a:focus {
		border-top-color: ${({ theme }) => theme.darkGray6};
	}


	.nav-tabs.nav-tabs-top>li>a.active,
	.nav-tabs.nav-tabs-top>li>a.active:hover,
	.nav-tabs.nav-tabs-top>li>a.active:focus {
		background-color: transparent;
		border-top-color: ${({ theme }) => theme.orange};
		color: ${({ theme }) => theme.white};
	}


	.nav-tabs.nav-tabs-bottom>li>a.active,
	.nav-tabs.nav-tabs-bottom>li>a.active:hover,
	.nav-tabs.nav-tabs-bottom>li>a.active:focus {
		border-bottom-width: 2px;
		border-color: transparent;
		border-bottom-color: ${({ theme }) => theme.orange};
		background-color: transparent;
		color: ${({ theme }) => theme.white};
	}

	.nav-tabs.nav-tabs-solid {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.nav-tabs.nav-tabs-solid>li>a:hover,
	.nav-tabs.nav-tabs-solid>li>a:focus {
		background-color: ${({ theme }) => theme.lightGray4};
	}

	.nav-tabs.nav-tabs-solid>.open:not(.active)>a {
		background-color: ${({ theme }) => theme.lightGray4};
		border-color: transparent;
	}

	.nav-tabs-justified.nav-tabs-top {
		border-bottom: 1px solid ${({ theme }) => theme.darkGray6};
	}

}


@media only screen and (max-width: 991.98px) {
	.profile-info-left {
		border-bottom: 2px dashed ${({ theme }) => theme.gray6};
	}

	a.mobile_btn {
		color: ${({ theme }) => theme.white};
	}

	.responsive-search {
		color: ${({ theme }) => theme.gray3};
	}

}

@media only screen and (max-width: 767.98px) {
	
	.profile-info-left {
		border-bottom: 2px dashed ${({ theme }) => theme.gray6};
	}

	.nav-tabs.nav-justified {
		border-bottom: 1px solid ${({ theme }) => theme.gray6};
	}

	.nav-tabs.nav-justified>li>a.active,
	.nav-tabs.nav-justified>li>a.active:hover,
	.nav-tabs.nav-justified>li>a.active:focus {
		border-color: transparent transparent transparent ${({ theme }) =>
      theme.orange};
	}

	.nav-tabs {
		background-color: #383838;
		border: 1px solid ${({ theme }) => theme.darkGray6};
	}

	.nav-tabs>li>a:hover,
	.nav-tabs>li>a:focus {
		background-color: ${({ theme }) => theme.gray4};
	}

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active,
	.nav-tabs>li>a.active,
	.nav-tabs>li>a.active:hover,
	.nav-tabs>li>a.active:focus {
		background-color: ${({ theme }) => theme.lightGray4};
		border-color: transparent transparent transparent ${({ theme }) =>
      theme.orange};
	}

	.nav-tabs>li.open:not(.active)>a,
	.nav-tabs>li.open:not(.active)>a:hover,
	.nav-tabs>li.open:not(.active)>a:focus {
		background-color: ${({ theme }) => theme.gray4};
	}


	.nav-tabs-justified {
		border-bottom: 1px solid ${({ theme }) => theme.darkGray6};
	}

	.nav-tabs-justified>li>a.active,
	.nav-tabs-justified>li>a.active:hover,
	.nav-tabs-justified>li>a.active:focus {
		border-left-color: ${({ theme }) => theme.orange};
	}

}

/*Project Board*/
.page-header .page-title i {
	color: #e6e9ef;
}

.page-header .page-title i:hover,
.page-header .page-title i:active {
	color: #ffcb00;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus {
	border: 1px dashed #e6e9ef;
	/*  padding:5px;*/
}

.page-header .text-truncate {
	width: 330px;
}

.avatar-group {
	display: inline-flex;
}

.avatar-group .avatar+.avatar {
	margin-left: -.75rem;
}

.avatar-group .avatar-xs+.avatar-xs {
	margin-left: -1.25rem;
}

.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -1.75rem;
}

.avatar-group .avatar-lg+.avatar-lg {
	margin-left: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
	margin-left: -1.28125rem;
}

.avatar-group .avatar:hover {
	z-index: 1;
}

.avatar-title {
	background-color: #2962ff;
	color: ${({ theme }) => theme.white};
}
`;
