import { OverviewCard, TopNav } from "../reusables";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAddress,
  searchPayroll,
  getFormDetails,
  getPayrollSummary,
  toggleModal
} from "../../redux/actions";
import { CreatePayroll } from "./createPayroll";
import { AllPayroll } from "./allPayroll";
import React, { useEffect } from "react";
import { DeletePayroll } from "./deletePayroll";
import { EditPayroll } from "./editPayroll";
import moment from "moment";
import { ListviewPayrolls } from "./listviewPayrolls";
import { SetReminder } from "./setReminder";
import { QuickPay } from "./quickpay";
import { CreateCurrency } from "./createCurrency";
import CustomModal from "../reusables/modal/customModal";
import SuccessModal from "../reusables/modal/successModal";
import Loader from "../reusables/loader";
import QuickSendApprovalModal from "../reusables/modal/quicksendapprovalModal";
import modalConstants from "../reusables/modal/modalConstants";

import { Container } from "react-bootstrap";

export const Home = props => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.persistedStore.user);
  const blockchain = useSelector(state => state.persistedStore.blockchain);
  const allpayrolls = useSelector(state => state.PayrollReducer.allpayrolls);
  const payrolldetails = useSelector(
    state => state.PayrollReducer.payrolldetails
  );
  const searchname = useSelector(state => state.GeneralReducer.searchname);
  const modalPage = useSelector(state => state.GeneralReducer.modalPage);
  const listview = useSelector(state => state.GeneralReducer.listview);
  const madeAction = useSelector(state => state.GeneralReducer.successfull);
  const curr = useSelector(state => state.GeneralReducer.selectedCurrency);
  const chainChangedHandler = async () => {
    dispatch(storeAddress());
  };
  const transactionAmt = useSelector(
    state => state.PayrollReducer.transactionAmt
  );

  useEffect(() => {
    auth && dispatch(getPayrollSummary(auth.id));
  }, [madeAction]);

  window.ethereum.on("chainChanged", chainChangedHandler);

  function generateGreetings() {
    var currentHour = moment().format("HH");

    if (currentHour >= 3 && currentHour < 12) {
      return "Rise and Shine, Time To Get That Bread ☕";
    } else if (currentHour >= 12 && currentHour < 15) {
      return "Scorching Weather Ain’t it, Still Gotta Stay On The Grind 🪓";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "Good Evening, How was your day? 🌇";
    } else if (currentHour >= 20 || currentHour < 3) {
      return "Night Owl 🦉, I see you!";
    } else {
      return "Hello";
    }
  }

  return (
    <div className="landing-background">
      <TopNav />

      {/* <SideNav /> */}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {modalPage === modalConstants.PayrollCreated ? (
            <SuccessModal
              icon="/assets/img/success.svg"
              msg="Payroll created successfully!"
            />
          ) : (
            ""
          )}
          {modalPage === modalConstants.CurrencyAdded ? (
            <SuccessModal
              icon="/assets/img/success.svg"
              msg="Currency has been added to this blockchain successfully!"
            />
          ) : (
            ""
          )}
          {modalPage === modalConstants.CreatePayroll ? (
            <CustomModal>
              {" "}
              <CreatePayroll />{" "}
            </CustomModal>
          ) : (
            ""
          )}
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="landing-title">{generateGreetings()}</h3>
                <ul className="breadcrumb">
                  <li className="features_head breadcrumb-item active">
                    Dashboard
                  </li>
                </ul>

                <div className="col-auto float-right ml-auto">
                  <div
                    onClick={() => dispatch(toggleModal(1))}
                    className="landing-btn"
                  >
                    <i className="fa fa-plus mr-3" /> Create Payroll
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <OverviewCard
                icon="fa fa-cubes"
                title="Payroll Plans"
                value={payrolldetails && payrolldetails.totalPayroll}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <OverviewCard
                icon="fa fa-usd"
                title="Quick pay Transactons"
                value={payrolldetails && payrolldetails.numberOfQuickSents}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <OverviewCard
                icon="fa fa-diamond"
                title="Paid Recipients"
                value={payrolldetails && payrolldetails.totalTask}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <OverviewCard
                icon="fa fa-user"
                title="Total Recipients"
                value={payrolldetails && payrolldetails.totalReceipient}
              />
            </div>
          </div>
          {/* Page Header */}
          <QuickPay />
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-auto float-right ml-auto">
                <div
                  onClick={() => dispatch(toggleModal(1))}
                  className="landing-btn"
                >
                  <i className="fa fa-plus mr-3" /> Create Payroll
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <div className="form-group form-focus">
                <input
                  type="text"
                  value={searchname}
                  onChange={e =>
                    dispatch(
                      getFormDetails({
                        props: ["searchname"],
                        value: e.target.value
                      })
                    )
                  }
                  className="form-control floating"
                />
                <label className="focus-label">Payroll Name</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div
                onClick={() => dispatch(searchPayroll(searchname, allpayrolls))}
                className="btn btn-success btn-block"
              >
                {" "}
                Search{" "}
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="view-icons">
                <div
                  onClick={() =>
                    dispatch(
                      getFormDetails({
                        props: ["listview"],
                        value: false
                      })
                    )
                  }
                  className={
                    listview
                      ? "grid-view btn btn-link"
                      : "grid-view btn btn-link active"
                  }
                >
                  <i className="fa fa-th" />
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      getFormDetails({
                        props: ["listview"],
                        value: true
                      })
                    )
                  }
                  className={
                    listview
                      ? "list-view btn btn-link active"
                      : "list-view btn btn-link"
                  }
                >
                  <i className="fa fa-bars" />
                </div>
              </div>
            </div>
          </div>
          {/* Search Filter */}
          {listview ? <ListviewPayrolls /> : <AllPayroll />}
        </div>
      </div>
      <div className="page-wrapper">
        {/* Add Payroll Modal */}
        {/* {<CreatePayroll />} */}
        {/* /Add Payroll Modal */}
        {/* Edit Employee Modal */}
        {modalPage === modalConstants.EditPayroll ? (
          <CustomModal>
            <EditPayroll />{" "}
          </CustomModal>
        ) : (
          ""
        )}
        {/* /Edit Employee Modal */}
        {/* Delete Employee Modal */}
        <DeletePayroll />
        {/* /Delete Employee Modal */}
        <SetReminder />
        {/* Add Currency Modal */}
        {modalPage === modalConstants.CreateCurrency ? (
          <CustomModal>
            <CreateCurrency />
          </CustomModal>
        ) : (
          ""
        )}
        {modalPage === modalConstants.QuickSendApprovalModal ? (
          <QuickSendApprovalModal
            msg={
              transactionAmt.approvedAmount ? (
                `You are about to make a transfer of ${
                  transactionAmt.approvedAmount
                } ${curr && curr.name} at a transaction fee of ${
                  transactionAmt.totaltransactionFee
                } ${blockchain === 1 ? "BNB" :blockchain === 3? "MATIC": "ARBITRUM"}. A total of ${
                  transactionAmt.approvedAmount
                } ${curr && curr.name} will be deducted from your wallet.`
              ) : (
                <Loader size={12} />
              )
            }
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
