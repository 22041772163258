import _const from "../types";

//Gets all Inputs
export const getFormDetails = payload => {
  return dispatch => {
    dispatch({
      type: _const.FILL_OUT_FORM,
      payload: payload
    });
  };
};

export const editFormDetails = payload => {
  return dispatch => {
    dispatch(successfullAction("editing"));
    dispatch({
      type: _const.EDIT_FORM,
      payload: payload
    });
  };
};

export const successfullAction = payload => {
  return dispatch => {
    dispatch({
      type: _const.SUCCESSFULL_ACTION,
      payload: payload
    });
  };
};

export const toggleModal = payload => {
console.log("entered toggle")

  return dispatch => {
    dispatch({
      type: _const.TOGGLE_MODAL,
      payload: payload
    });
  };
};

export const excelRecipient = payload => {
  return dispatch => {
    dispatch({
      type: _const.EXCEL_RECIPIENT,
      payload: payload
    });
  };
};

export const quickpayRecipient = payload => {
  return dispatch => {
    dispatch({
      type: _const.QUICKPAY_RECIPIENT,
      payload: payload
    });
  };
};

export const quickpayToggle = payload => {
  return dispatch => {
    dispatch({
      type: _const.QUICKPAY_TOGGLE,
      payload: payload
    });
  };
};

export const madeImport = payload => {
  return dispatch => {
    dispatch({
      type: _const.MADE_IMPORT,
      payload: payload
    });
  };
};

export const searchPayroll = (query, array) => {
  query = query.toLowerCase();
  let expression = `.*${query}.*`;
  let regex = new RegExp(expression, "g");
  let result = array.filter(each => {
    return each.name.toLowerCase().match(regex);
  });
  return dispatch => {
    dispatch({
      type: _const.PAYROLLL_SEARCH_RESULT,
      payload: result
    });
  };
};

//Clear transaction report
export const clearTransactionReport = () => {
  return dispatch => {
    dispatch({
      type: _const.CLEAR_DATA,
      payload: true
    });
  };
};

//Copy to clipboard
export const copyToClipboard = copyText => {
  const textField = document.createElement("textarea");
  textField.innerText = copyText;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  return dispatch => {
    dispatch({
      type: _const.COPIED_TO_CLIPBOARD,
      payload: copyText
    });
  };
};
