import Web3 from "web3";
import { networks } from "../networks";
import { store } from "./../redux/index";
import { toggleModal } from "../redux/actions";

let web3;

if (window.ethereum) {
  // Modern dapp browsers...
  web3 = new Web3(window.ethereum);
  try {
    // Request account access if needed
    await window.ethereum.request({ method: "eth_requestAccounts" });
    // Accounts now exposed
  } catch (error) {
    console.error("User denied account access");
  }
} else if (window.web3) {
  // Legacy dapp browsers...
  web3 = new Web3(window.web3.currentProvider);
} else {
  // If no injected web3 instance is detected, fall back to a default provider
  const provider = new Web3.providers.HttpProvider("http://localhost:8545");
  web3 = new Web3(provider);
}

export default web3;

export const connectWallet = async (networkId) => {
  let walletDetails = null;

  if (!window.ethereum) {
    alert("Please install MetaMask to use this dApp!");
    return walletDetails;
  }

  try {
    // Request account access
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const address = accounts[0];

    // Update address if accounts change
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        localStorage.setItem("address", accounts[0]);
      } else {
        localStorage.removeItem("connected");
        localStorage.removeItem("networkId");
      }
    });

    // Initialize Web3
    const web3 = new Web3(window.ethereum);

    // Change network
    if (networks[networkId]) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [networks[networkId]],
      });
    } else {
      throw new Error("Network not supported");
    }

    // Personal sign to get signature
    const message =
      "You have the Midas Touch, everything you touch, turns to gold!";
    const signature = await web3.eth.personal.sign(message, address, "");

    // Store connection status and network in local storage
    localStorage.setItem("connected", "true");
    localStorage.setItem("networkId", networkId);
    localStorage.setItem("address", address);

    // Build wallet details object
    walletDetails = {
      address,
      networkId,
      signature,
      network: networks[networkId],
    };

    console.log(walletDetails);
  } catch (error) {
    console.error("Error connecting wallet:", error);
  }
  store.dispatch(toggleModal(0));
  return walletDetails;
};
