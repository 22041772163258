import Web3 from "web3";
import { addError } from "./error";
import _const from "../types";
import axios from "axios";
import config from "../config";
import { toggleModal } from "./generalActions";

export const setAddress = (
  networkId,
  address,
  signature,
  network,
  blockchain,
  isTestnet
) => ({
  type: _const.STORE_ADDRESS,
  networkId,
  address,
  signature,
  network,
  blockchain,
  isTestnet,
});
export const setSignature = (signature) => ({
  type: _const.SIGNATURE,
  signature,
});

export const storeAddress = (data, blockchain, isTestnet) => {
  return async (dispatch) => {
    dispatch({ type: _const.AUTHENTICATING_USER, payload: true });
    try {
      let provider = window.ethereum;
      let address;

      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        address = accounts[0];

        window.ethereum.on("accountsChanged", function (accounts) {
          address = accounts[0];
        });
      } else {
        alert("Please install MetaMask to use this dApp!");
      }

      const web3 = new Web3(provider);
      window.APPWEB3 = new Web3(web3.givenProvider);

      //changing network
      await window.ethereum.request({
        method: "wallet_addEthereumChain", 
        params: [data],
      });

      const networkId = await web3.eth.net.getId();
      console.log("hey", address);
      const signature = await web3.eth.personal.sign(
        `You have the Midas Touch, everything you touch, turns to gold!`,
        address,
        ""
      );
      await axios
        .post(config.apiUrl + `/Payroll/User`, {
          signedMessageSignature: signature,
          walletAddress: address,
          message:
            "You have the Midas Touch, everything you touch, turns to gold!",
        })
        .then((res) => {
          //save access token to local storage
          localStorage.setItem("accessToken", res.data.data);
          //save wallet details
          dispatch(
            setAddress(networkId, address, signature, blockchain, isTestnet)
          );
          //save user details
          dispatch(getUser(address));
          dispatch({ type: _const.AUTHENTICATING_USER, payload: false });
        });
    } catch (error) {
      console.log(error);
      dispatch(addError(error));
      dispatch({ type: _const.AUTHENTICATING_USER, payload: false });
    }
  };
};
export const connectWallet = (network) => {
  return async (dispatch) => {
    dispatch({ type: _const.AUTHENTICATING_USER, payload: true });
    try {
      let provider = window.ethereum;
      let address;
      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        address = accounts[0];
        window.ethereum.on("accountsChanged", function (accounts) {
          address = accounts[0];
        });
      } else {
        alert("Please install MetaMask to use this dApp!");
      }

      const web3 = new Web3(provider);
      window.APPWEB3 = new Web3(web3.givenProvider);
      const data = {
        //chainId: `0x${Number(97).toString(16)}`,
        chainId: network.chainIdString,
        chainName: network.chainName,
        nativeCurrency: network.nativeCurrency,
        rpcUrls: network.rpcUrls,
        blockExplorerUrls: network.blockExplorerUrls,
      };
      //changing network
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [data],
      });

      const networkId = await web3.eth.net.getId();
      const signature = await web3.eth.personal.sign(
        `You have the Midas Touch, everything you touch, turns to gold!`,
        address,
        ""
      );
      dispatch(setAddress(networkId, address, signature, network));
      dispatch({ type: _const.AUTHENTICATING_USER, payload: false });
      dispatch(toggleModal(0));
    } catch (error) {
      console.log(error);
      dispatch(addError(error));
      dispatch({ type: _const.CONNECT_APP_FAIL, payload: false });
    }
  };
};

export const switchNetwork = async (network, address) => {
  return async (dispatch) => {
    dispatch({ type: _const.AUTHENTICATING_USER, payload: true });
    try {
      const provider = window.ethereum;
      if (provider) {
        const web3 = new Web3(provider);
        const data = {
          chainId: network.chainIdString,
          chainName: network.chainName,
          nativeCurrency: network.nativeCurrency,
          rpcUrls: network.rpcUrls,
          blockExplorerUrls: network.blockExplorerUrls,
        };
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [data],
        });
        const networkId = await web3.eth.net.getId();
        const signature = await web3.eth.personal.sign(
          `You have the Midas Touch, everything you touch, turns to gold!`,
          address,
          ""
        );
        dispatch(setAddress(networkId, address, signature, network));
        dispatch({ type: _const.AUTHENTICATING_USER, payload: false });
        dispatch(toggleModal(0));
        return true;
      } else {
        alert("Please install MetaMask to use this dApp!");
        dispatch({ type: _const.AUTHENTICATING_USER, payload: false });
        return false;
      }
    } catch (error) {
      console.error(`Failed to switch network: ${error}`);
      dispatch(addError(error));
      dispatch({ type: _const.CONNECT_APP_FAIL, payload: false });
      return false;
    }
  };
};

export const getUser = (address) => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + `/Payroll/User?walletAddress=${address}`)
      .then((res) => {
        console.log("the gotetetetet", res.data.data);
        dispatch({
          type: _const.GET_USER,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const signAddress = (address) => {
  return async (dispatch) => {
    try {
      let provider = window.ethereum;
      const web3 = new Web3(provider);
      // const nonce = web3.eth.getTransactionCount(address, "pending");
      //  const signature = await web3.eth.sign('test', address)
      const signature = await web3.eth.personal.sign(
        ` "You have the Midas Touch, everything you touch, turns to gold!"`,
        address
      );

      localStorage.setItem("sgs", JSON.stringify(signature));
      dispatch(setSignature(signature));
    } catch (error) {
      dispatch(addError(error));
    }
  };
};

export const removeAddress = () => ({
  type: _const.REMOVE_ADDRESS,
});

export const resetUser = () => ({
  type: _const.RESET_USER,
});
