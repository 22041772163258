import _const from "../types";

export const addError = error => ({
  type: _const.ADD_ERROR,
  error
});

export const removeError = () => ({
  type: _const.REMOVE_ERROR
});
