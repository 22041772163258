import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleModal } from "../../../redux/actions";
import modalConstants from "../../reusables/modal/modalConstants";

const Wrapper = styled.div`
  padding: 20px;
  z-index: 100;
`;

const GetStartedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 140px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: 1px solid ${({ theme }) => theme.greenLight};
  border-radius: 27px;
  backdrop-filter: blur(10px);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
`;

const LeftColumn = styled.div`
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 56px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const SubTitle = styled.p`
  font-size: 32px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Button = styled.button`
  background-color: #ffffff;
  color: #000;
  padding: 15px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 300px;
  border: 1px solid ${({ theme }) => theme.greenLight};
  font-size: 20px;
  font-weight: 500;
  box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);

  &:hover {
    background-color: ${({ theme }) => theme.greenLight};
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 430px;
  height: auto;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

const GetStarted = () => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <GetStartedContainer>
        <LeftColumn>
          <Title>Get Started</Title>
          <SubTitle>
            Connect your wallet and start making payment the MitterPay way
          </SubTitle>
          <Button
            onClick={() => dispatch(toggleModal(modalConstants.SelectNetwork))}
          >
            Launch App
          </Button>
        </LeftColumn>
        <Image src="/assets/img/PNG/rocke_launch_pic.svg" alt="Rocket Launch" />
      </GetStartedContainer>
    </Wrapper>
  );
};

export default GetStarted;
