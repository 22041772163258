import styled from "styled-components/macro";

export const ModalContainer = styled.div`
  display: flex; /* Hidden by default */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* Stay in place */
  z-index: 100000000000;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: ${({ theme }) =>
    theme.translucentBlack5}; /* Black w/ opacity */
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.darkGray5};
  /* margin: 15% auto; 15% from the top and centered */
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.gray10};
  border-radius: 21px;
  width: auto; /* Could be more or less, depending on screen size */
  border-radius: 8px;
  box-shadow: 0px 40px 58px ${({ theme }) => theme.tealLight};
  animation-name: animatetop;
  animation-duration: 0.4s;
  z-index: 1;
  max-width: 90%;
/* 
  @media (min-width: 760px) {
    width: 50%;
  } */
`;

export const Close = styled.span`
  color: ${({ theme }) => theme.gray11};
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
`;

export const ChildrenContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  width: auto;
`;

export const CenterContent = styled.div`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

export const SuccessMsg = styled.p`
  margin-top: 20px;
  text-align: center;
  word-wrap: break-word;
  color: ${({ theme }) => theme.darkGray5};
`;

export const CancelBtn = styled.button`
  border: none;
  height: 50px;
  color: ${({ theme }) => theme.orange1};
  border-radius: 20px;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: 600;
`;

export const ContinueBtn = styled.button`
  border: none;
  height: 50px;
  background-color: ${({ theme }) => theme.orange1};
  color: ${({ theme }) => theme.whiteSmoke};
  border-radius: 20px;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: 600;
`;
