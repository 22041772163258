import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../../redux/actions";
import modalConstants from "../modal/modalConstants";
import Loader from "../loader";
import { IoMoonOutline } from "react-icons/io5";
import { BsSun } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { useTheme } from "styled-components";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  NavItem,
  NavItems,
  ThemeToggleContainer,
  MobileNavItems,
  HamburgerIcon,
} from "./styles";

const Header = ({ currentTheme, themeToggler }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const authenticatingUser = useSelector(
    (state) => state.LoaderReducer.authenticatingUser
  );

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <HeaderContainer>
      <HeaderLeft>
        <a href="/">
          <img
            // style={{ width: 120 }}
            src={
              currentTheme === "light"
                ? "/assets/img/PNG/mitterpay_logo_light.png"
                : "/assets/img/PNG/mitter_logo.png"
            }
            alt="logo"
          />
        </a>
      </HeaderLeft>
      <HeaderRight>
        <NavItems>
          <NavItem href="/">Home</NavItem>
          <NavItem href="/#features">Features</NavItem>
          <NavItem href="/#faqs">FAQ</NavItem>
          <NavItem href="/#tutorial">Tutorial</NavItem>
          <NavItem href="/about">About</NavItem>
          <NavItem className="nav-item mx-2 navbar-btn p-0 nav-link">
            <span
              className="nav-link navbar-btn"
              onClick={() =>
                dispatch(toggleModal(modalConstants.SelectNetwork))
              }
            >
              {authenticatingUser ? <Loader /> : "Launch App"}
            </span>
          </NavItem>
          <ThemeToggleContainer>
            {currentTheme === "light" && (
              <IoMoonOutline
                size={24}
                color={theme.black}
                cursor={"pointer"}
                onClick={themeToggler}
              />
            )}
            {currentTheme === "dark" && (
              <BsSun
                size={24}
                color={theme.black}
                cursor={"pointer"}
                onClick={themeToggler}
              />
            )}
          </ThemeToggleContainer>
        </NavItems>
        <HamburgerIcon onClick={toggleMenu}>
          <FiMenu size={24} />
        </HamburgerIcon>
        {menuOpen && (
          <MobileNavItems>
            <NavItem href="/">Home</NavItem>
            <NavItem href="/#features">Features</NavItem>
            <NavItem href="/#faqs">FAQ</NavItem>
            <NavItem href="/#tutorial">Tutorial</NavItem>
            <NavItem href="/about">About</NavItem>
            <NavItem className="nav-item mx-2 navbar-btn p-0 nav-link">
              <span
                className="nav-link navbar-btn"
                onClick={() =>
                  dispatch(toggleModal(modalConstants.SelectNetwork))
                }
              >
                {authenticatingUser ? <Loader /> : "Launch App"}
              </span>
            </NavItem>
            <ThemeToggleContainer>
              {currentTheme === "light" && (
                <IoMoonOutline
                  size={24}
                  color={theme.black}
                  cursor={"pointer"}
                  onClick={themeToggler}
                />
              )}
              {currentTheme === "dark" && (
                <BsSun
                  size={24}
                  color={theme.black}
                  cursor={"pointer"}
                  onClick={themeToggler}
                />
              )}
            </ThemeToggleContainer>
          </MobileNavItems>
        )}
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
