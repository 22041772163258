import _const from '../types'

export default (state = { message: null }, action) => {
  switch (action.type) {
    case _const.ADD_ERROR:
      return { message: action.error };
    case _const.REMOVE_ERROR:
      return {  message: null };
    default:
      return state;
  }
};