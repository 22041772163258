import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "../../redux/actions";
import PayrollTransactionAction from "../../redux/actions/payrolltransactionActions";

const PayCurrentrecipinets = props => {
  const dispatch = useDispatch();
  const payrollrecipinetsAddresses = useSelector(
    state => state.PayrollReducer.payrollrecipinetsAddresses
  );
  const payrollreciinetsAmounts = useSelector(
    state => state.PayrollReducer.payrollreciinetsAmounts
  );

  return (
    <div
      className="col-auto float-right ml-auto"
      onClick={() => {
        dispatch(toggleModal(30));
        dispatch(
          PayrollTransactionAction(
            payrollreciinetsAmounts,
            payrollrecipinetsAddresses
          )
        );
      }}
    >
      <button
        className="btn add-btn"
        disabled={payrollrecipinetsAddresses.length > 0 ? false : true}
      >
        <i className="fa fa-plus" /> Pay Current Recipients
      </button>
    </div>
  );
};
export default PayCurrentrecipinets;
