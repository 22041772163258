import React from "react";
import Header from "../reusables/Navs/Header";
import GetStarted from "../LandingPage/Sections/GetStarted";
import WhoWeAre from "./WhoWeAre";
import Testimony from "../LandingPage/Sections/Testimony";
import FAQ from "../LandingPage/Sections/FAQs";
import Footer from "../reusables/Navs/Footer";
import Achievements from "./Achievements";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  .blob {
    position: absolute;
    opacity: 0.6;
    z-index: 1;
    width: 100%;
    height: 1323px;
  }

  .blob-6 {
    top: 0;
    left: 0;
  }

  .blob-7 {
    top: 1465px;
    right: 541px;
  }

  .blob-8 {
    top: 2208px;
    left: 446px;
  }

  @media (max-width: 768px) {
    gap: 50px;
    margin-top: 50px;

    .blob {
      height: 600px; /* Adjust for smaller screens */
    }

    .blob-7 {
      right: 0;
    }

    .blob-8 {
      left: 0;
    }
  }
`;

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 100px;
`;

const About = ({ currentTheme, themeToggler }) => {
  return (
    <Wrapper>
      <img
        src="/assets/img/SVG/blob-6.svg"
        className="blob blob-6"
        alt="blob-6"
      />
      <img
        src="/assets/img/SVG/blob-7.svg"
        className="blob blob-7"
        alt="blob-7"
      />
      <img
        src="/assets/img/SVG/blob-8.svg"
        className="blob blob-8"
        alt="blob-8"
      />
      <ParentContainer>
        <Header currentTheme={currentTheme} themeToggler={themeToggler} />
        <WhoWeAre />
        <Achievements />
        <GetStarted />
        <Testimony />
        <FAQ />
        <Footer />
      </ParentContainer>
    </Wrapper>
  );
};

export default About;
