import _const from "../types";

export const saveTransactionDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: _const.STORE_MULTISEND_DETAILS,
      payload: payload,
    });
  };
};
