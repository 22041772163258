import React, { useState } from "react";
import styled from "styled-components";

const TestimonyContainer = styled.div`
  padding: 50px;
  background-color: transparent;
  color: #fff;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const TestimonyHeader = styled.p`
  margin-bottom: 20px;
  font-size: 50px;
  font-weight: 700;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const TestimonySubText = styled.p`
  margin-bottom: 20px;
  font-size: 24px;
  color: ${({ theme }) => theme.lightGray};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const TestimonyRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;
  margin-top: 100px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 50px;
    gap: 30px;
  }
`;

const SingleTestimony = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 2;
  position: relative;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const TestimonyImg = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.greenLight};
  padding: 5px;
  background: #000;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

const TestimonyText = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.lightGray};
  max-width: 400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 18px;
    width: 90%;
  }
`;

const TestimonyName = styled.p`
  font-size: 24px;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Underlay = styled.img`
  position: absolute;
  left: -20px;
  bottom: 100px;
  z-index: 1;
  width: 150px;

  @media (max-width: 768px) {
    width: 80px;
    bottom: 50px;
  }
`;

const SlideDotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;
  margin-top: 50px;

  @media (max-width: 768px) {
    gap: 10px;
    margin-top: 30px;
  }
`;

const SlideDot = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.greenLight};
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.greenLight};
  }

  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
`;

const Testimony = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const slideCount = 3; // Update this based on the actual number of slides

  return (
    <TestimonyContainer>
      <TestimonyHeader>What they say about MitterPay</TestimonyHeader>
      <TestimonySubText>
        See what our users say about MitterPay
      </TestimonySubText>
      <TestimonyRow>
        <SingleTestimony>
          <TestimonyImg>
            <img
              src="/assets/img/PNG/unsplash_XpejolzYGvctestimony_profile_1.png"
              alt="Profile 1"
            />
          </TestimonyImg>
          <TestimonyText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.
          </TestimonyText>
          <TestimonyName>Anthony, CEO Mobis</TestimonyName>
          <Underlay src="/assets/img/PNG/quote_img.png" alt="Quote" />
        </SingleTestimony>
        <SingleTestimony>
          <TestimonyImg>
            <img
              src="/assets/img/PNG/unsplash_l35dDPD3Gystestimny_profile_2.png"
              alt="Profile 2"
            />
          </TestimonyImg>
          <TestimonyText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.
          </TestimonyText>
          <TestimonyName>Anthony, CEO Mobis</TestimonyName>
          <Underlay src="/assets/img/PNG/quote_img.png" alt="Quote" />
        </SingleTestimony>
        <SingleTestimony>
          <TestimonyImg>
            <img
              src="/assets/img/PNG/unsplash_nNj_e121IbItestimony_profile_3.png"
              alt="Profile 3"
            />
          </TestimonyImg>
          <TestimonyText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.
          </TestimonyText>
          <TestimonyName>Anthony, CEO Mobis</TestimonyName>
          <Underlay src="/assets/img/PNG/quote_img.png" alt="Quote" />
        </SingleTestimony>
      </TestimonyRow>
      <SlideDotsContainer>
        {[...Array(slideCount)].map((_, index) => (
          <SlideDot
            key={index}
            className={currentSlide === index + 1 ? "active" : ""}
            onClick={() => setCurrentSlide(index + 1)}
          />
        ))}
      </SlideDotsContainer>
    </TestimonyContainer>
  );
};

export default Testimony;
