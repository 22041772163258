
const modalConstants = {
    SelectBlockchain: 100,
    SelectNetwork: 101,
    CreateCurrency: 21,
    QuickSendApprovalModal: 40,
    PayrollCreated: 10,
    CurrencyAdded : 50,
    CreatePayroll: 1,
    EditPayroll: 2,
    UploadExcelModal: 23,
    TransactionSuccess: 20,
    CreateQuickSend: 120,
    ApprovalModal: 30,
    CreateRecipient:3,
    EditRecipient:4,
    DeleteRecipient:5






}
export default modalConstants;