import _const from "../types";
import transactionFee from "../../methods/contracts/getTransactionFee";

function PayrollTransactionAction(amounts, addresses) {
  return async dispatch => {
    try {
      const fee = await transactionFee(amounts, addresses);
      dispatch({
        type: _const.TRANSACTION_FEES,
        payload: fee
      });
    } catch (err) {
      dispatch({
        type: _const.APPROVING_PAYMENT,
        payload: false
      });

      throw err;
    }
  };
}

export default PayrollTransactionAction;
