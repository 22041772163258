import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SideNavbar } from "../reusables/nav/SideNavbar";

import {
  getPayrollRecipients,
  getFormDetails,
  getPayrolls,
  clearTransactionReport,
  toggleModal,
} from "../../redux/actions";
import moment from "moment";
import Loader from "../reusables/loader";
import SuccessModal from "../reusables/modal/successModal";
import ApprovalModal from "../reusables/modal/approvalModal";
import CreateRecipient from "./createRecipient";
import modalConstants from "../reusables/modal/modalConstants";
import CustomModal from "../reusables/modal/customModal";
import { useTheme } from "styled-components";

const AllRecipients = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const { payrollId } = location.state || {};

  const allPayrollRecipients = useSelector(
    (state) => state.PayrollReducer.allPayrollRecipients
  );
  const blockchain = useSelector((state) => state.persistedStore.blockchain);

  const recipientsdateTo = useSelector(
    (state) => state.GeneralReducer.recipientsdateTo
  );
  const recipientsdateFrom = useSelector(
    (state) => state.GeneralReducer.recipientsdateFrom
  );
  const tagName = useSelector((state) => state.GeneralReducer.tagName);
  const user = useSelector((state) => state.persistedStore.user);
  const gettingpayrollRecipients = useSelector(
    (state) => state.LoaderReducer.gettingpayrollRecipients
  );
  const selectedpayroll = useSelector(
    (state) => state.PayrollReducer.selectedpayroll
  );
  const modalPage = useSelector((state) => state.GeneralReducer.modalPage);
  const transactionAmt = useSelector(
    (state) => state.PayrollReducer.transactionAmt
  );

  function generateGreetings() {
    var currentHour = moment().format("HH");

    if (currentHour >= 3 && currentHour < 12) {
      return "Rise and Shine, Time To Get That Bread ☕";
    } else if (currentHour >= 12 && currentHour < 15) {
      return "Scorching Weather Ain’t it, Still Gotta Stay On The Grind 🪓";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "Good Evening, How was your day? 🌇";
    } else if (currentHour >= 20 || currentHour < 3) {
      return "Night Owl 🦉, I see you!";
    } else {
      return "Hello";
    }
  }

  useEffect(() => {
    dispatch(getPayrolls(user.id, 1, 30, payrollId));
    dispatch(
      getPayrollRecipients(
        payrollId,
        1,
        30,
        tagName,
        recipientsdateFrom,
        recipientsdateTo
      )
    );
  }, [tagName]);

  return (
    <>
      <SideNavbar />

      <div className="sidebard-content">
        {/* Page Header */}
        <div
          style={{ backgroundColor: theme.white, padding: "1%" }}
          className="page-header"
        >
          <div className="row align-items-center">
            <div className="col">
              <h3 className="dashboard-name">Hi, Anthony</h3>
              <ul className="breadcrumb">
                <li className="dashboard-subname">{generateGreetings()}</li>
              </ul>
            </div>
            <div className="col-auto float-right ml-auto">
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  padding: "2%",
                  backgroundColor: theme.gray7,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="page-header">
            <div className="row">
              <div
                className="col-sm-12"
                style={{ flexDirection: "row", flex: 1 }}
              >
                <div className="float-left">
                  <h3 className="landing-title">{`${selectedpayroll.name} receipients`}</h3>
                  <p className="landing-subHead">List of all your payrolls</p>
                </div>

                <div className="col-auto float-right ml-auto">
                  <div
                    onClick={() =>
                      dispatch(toggleModal(modalConstants.CreateRecipient))
                    }
                    className="landing-btn"
                  >
                    <i className="fa fa-plus mr-3" /> Add receipient
                  </div>
                </div>
              </div>
            </div>
          </div>
          {modalPage === modalConstants.ApprovalModal ? (
            <ApprovalModal
              msg={
                transactionAmt.approvedAmount ? (
                  `You are about to make a transfer of ${
                    transactionAmt.approvedAmount
                  } ${
                    selectedpayroll.currency && selectedpayroll.currency.name
                  } at a transaction fee of ${
                    transactionAmt.totaltransactionFee
                  } ${
                    blockchain === 1
                      ? "BNB"
                      : blockchain === 3
                      ? "MATIC"
                      : "ARBITRUM"
                  }. A total of ${transactionAmt.approvedAmount} ${
                    selectedpayroll.currency && selectedpayroll.currency.name
                  } will be deducted from your wallet.`
                ) : (
                  <Loader size={12} />
                )
              }
            />
          ) : (
            ""
          )}
          {modalPage === modalConstants.TransactionSuccess ? (
            <SuccessModal
              icon="/assets/img/success.svg"
              msg="Transaction successsful!"
            />
          ) : (
            ""
          )}
          <div className="table-responsive">
            <table className="table table-striped custom-table datatable">
              <thead>
                <tr>
                  <th>Recipient</th>
                  <th className="none">Employee ID</th>
                  <th>Email</th>
                  <th className="none-low">Join Date</th>
                  <th>Salary</th>
                  <th>Report</th>
                  <th className="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                {allPayrollRecipients.length !== 0 &&
                  allPayrollRecipients.map((each) => {
                    return (
                      <tr key={each.id}>
                        <td>
                          <h2 className="table-avatar">
                            <p className="avatar">
                              <img
                                alt="avatar"
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png"
                              />
                            </p>
                            <p className="none">
                              <span>{each.walletAddress}</span>
                            </p>
                          </h2>
                        </td>
                        <td className="none">{each.tag}</td>
                        <td>{each.emailAddress}</td>
                        <td className="none-low">
                          {moment(each.createdAt).format("D MMM YYYY")}
                        </td>
                        <td>
                          {each.amount}
                          {selectedpayroll.currency &&
                            selectedpayroll.currency.name}
                        </td>
                        <td>
                          <Link
                            to={`/report/${each.id}`}
                            className="btn btn-sm btn-primary"
                            onClick={() => dispatch(clearTransactionReport())}
                          >
                            Generate Report
                          </Link>
                        </td>
                        <td className="text-right">
                          <div className="dropdown dropdown-action">
                            <a
                              href="#"
                              className="action-icon dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="material-icons">more_vert</i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              {/* <a
                          className="dropdown-item"
                          onClick={() => {
                            dispatch(
                              getFormDetails({
                                props: ["recipientname"],
                                value: each.name
                              })
                            );
                            dispatch(toggleModal(4));
                            dispatch(
                              getFormDetails({
                                props: ["recipientwalletAddress"],
                                value: each.walletAddress
                              })
                            );
                            dispatch(
                              getFormDetails({
                                props: ["recipientemail"],
                                value: each.emailAddress
                              })
                            );
                            dispatch(
                              getFormDetails({
                                props: ["amount"],
                                value: each.amount
                              })
                            );
                          }}
                        >
                          <i className="fa fa-pencil m-r-5" /> Edit
                        </a> */}
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  dispatch(
                                    getFormDetails({
                                      props: ["recipientemail"],
                                      value: each.emailAddress,
                                    })
                                  );
                                  dispatch(toggleModal(5));
                                }}
                              >
                                <i className="fa fa-trash-o m-r-5" /> Delete
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {modalPage === modalConstants.CreateRecipient ? (
          <CustomModal width="60%">
            <CreateRecipient />
          </CustomModal>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default AllRecipients;
