import React from "react";
import "./style.css";
import { RxCaretUp } from "react-icons/rx";
import styled from "styled-components";

const FAQItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.17);
  border: none;
  border-radius: 20px;
  width: 80%;
  margin-bottom: 10px;
  backdrop-filter: blur(10px);

  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;
  }
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.black};
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  svg {
    color: ${({ theme }) => theme.black};
    font-size: 30px;
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

const Answer = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.lightGray};
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const FAQItem = ({ question, answer, links, isActive, onToggle }) => {
  return (
    <FAQItemContainer className={`question ${isActive ? "isExpanded" : ""}`}>
      <div onClick={onToggle} style={{ width: "100%" }}>
        <Question>
          <span>{question}</span>
          <span>
            <RxCaretUp className={`faq-icon ${isActive && "rotate"}`} />
          </span>
        </Question>
      </div>
      {isActive && <Answer>{answer}</Answer>}
    </FAQItemContainer>
  );
};

export default FAQItem;
