import React from 'react';
import { useDispatch } from "react-redux";
import { removeAddress } from "../../../redux/actions";
import { useTheme } from 'styled-components';




// Define a new component, PageHeader, that can accept props
const PageHeader = ({ greeting = "Hi", subGreeting = "How is your day going? 😎",}) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    function onDisconnect() {
        localStorage.clear();
        dispatch(removeAddress());
      }
      
    return (
      <div
        style={{ backgroundColor: theme.white, padding: "1%" }}
        className="page-header"
      >
        <div className="row align-items-center">
          <div className="col">
            <h3 className="dashboard-name">{greeting}</h3>
            <ul className="breadcrumb">
              <li className="dashboard-subname">{subGreeting}</li>
            </ul>
          </div>
          <div className="col-auto float-right ml-auto">
            <div
              style={{
                height: 50,
                width: 50,
                borderRadius: 25,
                padding: "2%",
                backgroundColor: theme.gray7,
              }}
            >
              <button onClick={() => onDisconnect()}>Disconnect</button>
            </div>
            {/* <button onClick={onProfileInfo}>Profile Info</button> */}
          </div>
        </div>
      </div>
    );
};

export default PageHeader;
