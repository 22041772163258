export const tutorialSteps = {
  secondTabSteps: [
    "Connect your wallet to the app.",
    "Select your token and specify a list of addresses in CSV format; if you don't know what CSV is, click the 'Show Sample CSV' button.",
    "Click 'Continue' and proceed to the next step.",
    "Click 'Approve' and confirm in the wallet.",
    "Click 'Proceed' and go to the third step.",
    "Select 'self-generated key', click 'Sign key' and sign the message in your wallet.",
    "Make a copy of your temporary private key, check the box, and click 'Continue'.",
    "This step shows the total estimate. You can also change the value of gas price, but we don't recommend changing this field.",
    "Click 'Proceed to approve transaction', then sign the message and confirm the transaction in your wallet.",
    "Done! Do not leave the page until all transactions have been mined.",
    "After the multisend is completed, your self-generated key may be some funds remaining. To receive these funds, click the button with your wallet address in the top right corner. In the pop-up window, locate the ‘refund’ field and click the ‘refund’ button.",
  ],
  firstTabSteps: [
    "Connect your wallet to the app.",
    "Select your token and specify a list of addresses in CSV format; if you don't know what CSV is, click the 'Show Sample CSV' button.",
    "Click 'Continue' and proceed to the next step.",
    "Click 'Approve' and confirm in the wallet.",
    "Click 'Proceed' and go to the third step.",
    "This step shows the total estimate. You can also change the value of gas price, but we don't recommend changing this field.",
    "Click 'Proceed' and confirm the transactions in the wallet.",
    "Done!",
  ],
};
