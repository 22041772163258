import _const from "../types";

const initialState = {
  theme: "light",
  reminderDay: "",
  payrollname: "",
  payrollImage: {},
  searchname: "",
  recipientname: "",
  payerWalletAddress: "",
  payerCurrency: "",
  recipientwalletAddress: "",
  recipientemail: "",
  amount: "",
  recipientId: "",
  payrollId: "",
  successfull: "",
  currency: [],
  madeImport: false,
  quickpayRecipient: [],
  excelRecipient: [],
  quickpay: false,
  editDetails: {},
  listview: false,
  copyBoard: "",
  tagName: "",
  recipientsdateFrom: "",
  recipientsdateTo: "",
  paymentreportpage: 1,
  paymentreportpagesize: 30,
  reportdateFrom: "",
  reportdateTo: "",
  modalPage: 0,
  totalBonuses: 0,
  totalDeductions: 0,
  netPay: 0,
  contractAddress: "",
  blockchainType: "",
  blockchainTypes: [
    { id: 1, value: "Bsc" },
    { id: 2, value: "Ethereum" },
    { id: 3, value: "Matic" },
    { id: 4, value: "Arbitrum" },
  ],
  creatingCurrencyError: "",
  currencyId: "",
  quickpayrecipientFile: "",
  selectedCurrency: {},
};

const GeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.FILL_OUT_FORM:
      return {
        ...state,
        [action.payload.props]: action.payload.value,
      };

    case _const.COPIED_TO_CLIPBOARD:
      return { ...state, copyBoard: action.payload };

    case _const.EDIT_FORM:
      return {
        ...state,
        editDetails: action.payload,
        modalPage: 2,
      };

    case _const.PAYROLL_CREATED:
      return { ...state, payrollname: "", payrollImage: "" };

    case _const.SET_THEME:
      return { ...state, theme: action.payload };

    case _const.QUICKPAY_TOGGLE:
      return { ...state, quickpay: action.payload };

    case _const.EXCEL_RECIPIENT:
      return { ...state, excelRecipient: action.payload };

    case _const.QUICKPAY_RECIPIENT:
      return { ...state, quickpayRecipient: action.payload };

    case _const.MADE_IMPORT:
      return { ...state, madeImport: action.payload };

    case _const.GET_CURRENCY:
      return { ...state, currency: action.payload };

    case _const.SET_THEME:
      return { ...state, theme: action.payload };

    case _const.SUCCESSFULL_ACTION:
      return {
        ...state,
        successfull: action.payload,
        recipientwalletAddress: "",
        recipientemail: "",
        amount: "",
      };

    case _const.RECIPIENT_CREATED:
      return {
        ...state,
        recipientname: "",
        recipientwalletAddress: "",
        amount: "",
        recipientemail: "",
      };

    case _const.TOGGLE_MODAL:
      return { ...state, modalPage: action.payload };

    case _const.CREATING_CURRENCY_ERROR:
      return { ...state, creatingCurrencyError: action.payload };

    case _const.CREATING_PAYROLL_ERR:
      return { ...state, payrollname: "", payrollImage: "" };

    case _const.CURRENCY_CREATED_SUCCESSFULLY:
      return { ...state, contractAddress: "" };

    default:
      return state;
  }
};

export default GeneralReducer;
