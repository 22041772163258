import { useSelector, useDispatch } from "react-redux";
import Loader from "../../reusables/loader";
import { initiatePayrollPayment, toggleModal } from "../../../redux/actions";
import CustomModal from "./customModal";
import {
  CenterContent,
  SuccessMsg,
  CancelBtn,
  ContinueBtn,
} from "./modalstyles";
import Logo from "../../home/images/logo.png";
import { useTheme } from "styled-components";

const ApprovalModal = ({ msg }) => {
  const dispatch = useDispatch();
  const theme = useTheme()
  const payrollrecipinetsAddresses = useSelector(
    (state) => state.PayrollReducer.payrollrecipinetsAddresses
  );
  const payrollreciinetsAmounts = useSelector(
    (state) => state.PayrollReducer.payrollreciinetsAmounts
  );
  const approvingPayment = useSelector(
    (state) => state.LoaderReducer.approvingPayment
  );

  // const blockchain = useSelector(state =>state.GeneralReducer.blockchainType)

  const selectedpayroll = useSelector(
    (state) => state.PayrollReducer.selectedpayroll
  );

  const auth = useSelector((state) => state.persistedStore);
  return (
    <CustomModal>
      <img width="10%" src={Logo} alt="logo" />
      <div
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingRight: "20%",
          paddingLeft: "20%",
        }}
      >
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Approve Transaction
        </h2>
        <CenterContent>
          <SuccessMsg> {msg}</SuccessMsg>
          <div className="row">
            <div className="col-lg-6">
              <CancelBtn onClick={() => dispatch(toggleModal(0))}>
                {" "}
                Cancel
              </CancelBtn>
            </div>
            <div className="col-lg-6">
              <ContinueBtn
                onClick={() => {
                  console.log(auth);
                  dispatch(
                    initiatePayrollPayment(
                      selectedpayroll && selectedpayroll.id,
                      payrollreciinetsAmounts,
                      payrollrecipinetsAddresses,
                      selectedpayroll &&
                        selectedpayroll.currency.contractAddress,
                      auth.address,
                      auth.blockchain
                    )
                  );
                }}
              >
                {approvingPayment ? <Loader /> : "Continue"}
              </ContinueBtn>
            </div>
          </div>
        </CenterContent>
      </div>
    </CustomModal>
  );
};

export default ApprovalModal;
