import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Home, Landing } from "./components";
import PaymentReport from "./components/paymentreport";
import PayRoll from "./components/payroll";
import PaySlip from "./components/payslip";
import { useSelector } from "react-redux";
import QuickSendRecipients from "./components/quickSendReport/QuickSendReceipients";
import QuickSendTransactions from "./components/quickSendReport/QuickSendTransactions";
import { Login } from "./components/auth/Login";
import { Dashboard } from "./components/home/dashboard";
import { NewPayroll } from "./components/home/NewPayroll";
import AllRecipients from "./components/payroll/allRecipients";
import Multisend from "./components/LandingPage/Multisend";
import Tutorial from "./components/Tutorial";
import FAQs from "./components/FAQs";
import About from "./components/About";

const Router = ({ theme, themeToggler }) => {
  const auth = useSelector((state) => state.persistedStore);
  const ProtectedRoute = ({ children, ...rest }) => {
    return auth.isAuthenticated ? (
      children
    ) : (
      <Navigate to="/" replace state={{ from: rest.location }} />
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Landing currentTheme={theme} themeToggler={themeToggler} />}
        />
        <Route
          path="/multisend"
          element={
            <Multisend currentTheme={theme} themeToggler={themeToggler} />
          }
        />
        <Route path="/signin" element={<Login />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route
          path="/about"
          element={<About currentTheme={theme} themeToggler={themeToggler} />}
        />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/payroll" element={<NewPayroll />} />
        <Route path="/payroll/receipients" element={<AllRecipients />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payroll/:payrollId"
          element={
            <ProtectedRoute>
              <PayRoll />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report/:recepientId"
          element={
            <ProtectedRoute>
              <PaymentReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quicksend/:quicksendId"
          element={
            <ProtectedRoute>
              <QuickSendRecipients />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quicksend/transactions/:quicksendId"
          element={
            <ProtectedRoute>
              <QuickSendTransactions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payslip/:transactionId"
          element={
            <ProtectedRoute>
              <PaySlip />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
