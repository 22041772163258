import { TopNav } from "../reusables";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { EmptyCard } from "../reusables";
import { copyToClipboard } from "../../redux/actions";
import Loader from "../reusables/loader";
import { formathash } from "../../methods/helper";
import SearchReport from "./searchReport";

const PaymentReport = () => {
  const dispatch = useDispatch();
  const recipientReport = useSelector(
    state => state.ReportReducer.recipientReport
  );
  const gettingTransaction = useSelector(
    state => state.LoaderReducer.gettingTransaction
  );
  const selectedpayroll = useSelector(
    state => state.PayrollReducer.selectedpayroll
  );

  const copyBoard = useSelector(state => state.GeneralReducer.copyBoard);

  return (
    <div className="main-wrapper">
      <TopNav />
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title breadcrumb-item active">Payments Report</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/home">Dashboard </Link>
                    <Link
                      to={`/payroll/${selectedpayroll && selectedpayroll.id}`}
                    >
                      / {selectedpayroll && selectedpayroll.name}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Payments Report</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}
          <SearchReport />
          {/* /Search Filter */}
          {gettingTransaction ? (
            <EmptyCard
              message={<Loader size={12} />}
              description="Generating Report..."
            />
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-striped custom-table mb-0 datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Transaction Hash</th>
                        <th>Date</th>
                        <th>Wallet Address</th>
                        <th>Payroll Name</th>
                        <th>Status</th>
                        <th className="text-center">Amount</th>
                        <th>Payslip</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recipientReport.map((eachReport, i) => {
                        return (
                          <tr id={eachReport.id}>
                            <td>{i + 1}</td>
                            <td>
                              <b>
                                {eachReport.transctionDetail
                                  ? formathash(
                                      eachReport.transctionDetail
                                        .transactionHash
                                    )
                                  : "--"}{" "}
                              </b>
                              {eachReport.transctionDetail ? (
                                <span
                                  className="copy"
                                  onClick={() =>
                                    dispatch(
                                      copyToClipboard(
                                        eachReport.transctionDetail
                                          .transactionHash
                                      )
                                    )
                                  }
                                >
                                  <i
                                    className={
                                      copyBoard ===
                                      eachReport.transctionDetail
                                        .transactionHash
                                        ? "fa fa-copy fa-lg approved"
                                        : "fa fa-copy fa-lg"
                                    }
                                  />{" "}
                                </span>
                              ) : (
                                " "
                              )}
                            </td>
                            <td>
                              {eachReport.transctionDetail
                                ? moment(
                                    eachReport.transctionDetail
                                      .createdAtTimeStamp
                                  ).format("D MMM YYYY, h:mm:ss a")
                                : "--"}
                            </td>
                            <td>{formathash(eachReport.walletAddress)}</td>
                            <td>{eachReport.payrollRequest.payroll.name}</td>
                            <td>
                              {eachReport.payrollRequest.status === 0 ? (
                                <div className="pending"> Initiated</div>
                              ) : eachReport.payrollRequest.status === 1 ? (
                                <div className="pending">
                                  {" "}
                                  Awaiting Confirmation
                                </div>
                              ) : eachReport.payrollRequest.status === 2 ? (
                                <div className="failed"> Failed</div>
                              ) : eachReport.payrollRequest.status === 3 ? (
                                <div className="approved"> Success</div>
                              ) : (
                                "--"
                              )}{" "}
                            </td>
                            <td className="text-center">
                              {eachReport.amount}{" "}
                              {eachReport.payrollRequest.payroll.currency.name}
                            </td>
                            <td>
                              {eachReport.transctionDetail ? (
                                <Link
                                  to={`/payslip/${eachReport.payrollRequest.batchId}`}
                                  className="btn btn-sm btn-primary"
                                  href="salary-view.html"
                                >
                                  View Payslip
                                </Link>
                              ) : (
                                "--"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {recipientReport.length > 0 ? (
                    ""
                  ) : (
                    <EmptyCard
                      message={"This user has no report yet"}
                      description="Make payments into their wallet address to view the report here."
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentReport;
