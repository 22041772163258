import React from "react";
import { AboutPageContainer } from "./style";
import Header from "../reusables/Navs/Header";
import logo from "../home/images/logo.png";
import Footer from "../reusables/Navs/Footer";
import { tutorialSteps } from "./util";
import { theme } from "../../theme";
import { useTheme } from "styled-components";

const Tutorial = () => {
const theme = useTheme();

  return (
    <>
      <Header />
      <AboutPageContainer>
        <section className="main-content section py-5">
          <div className="container">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="mb-4">
                  <h2 className="card-title text-center">About us</h2>
                  <figure className="text-center mb-4">
                    <img src={logo} alt="Medas-Logo" width="200" />
                  </figure>
                  <p className="card-text">
                    This DApp allows users to send thousands of tokens to
                    multiple addresses efficiently by batching the transfers and
                    automating the process. This tool enables users to save time
                    by automatically generating transactions on MetaMask. This
                    tool is in production since 2018. It accounts for over
                    30,000 transactions on Mainnet, which is equivalent to
                    several billion dollars. You can find the Multisender Smart
                    contract along with all previous transactions on
                    <a
                      href="https://etherscan.io/address/0x88888c037DF4527933fa8Ab203a89e1e6E58db70"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Etherscan
                    </a>
                    , or
                    <a
                      href="https://etherscan.io/address/0xA5025FABA6E70B84F74e9b1113e5F7F4E7f4859f"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>{" "}
                    for the older version.
                  </p>
                </div>

                <h2 className="card-title text-center">Video Tutorial</h2>
                <div className="mb-4">
                  <div className="embed-responsive embed-responsive-16by9 mb-4">
                    <iframe
                      className="embed-responsive-item"
                      id="youtube-player-1"
                      frameborder="0"
                      allowfullscreen
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      title="How to airdrop any ERC 20 token with multisender app"
                      src="https://www.youtube.com/embed/rSJedr32XA4?rel=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2FclassNameic.multisender.app&amp;widgetid=1"
                    ></iframe>
                  </div>
                  <h3 className="card-title text-center">
                    How to send ERC20 tokens to multiple addresses
                  </h3>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="erc20-tab"
                        data-toggle="tab"
                        href="#erc20"
                        role="tab"
                        aria-controls="erc20"
                        aria-selected="true"
                      >
                        Multisend of ERC20 token
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="self-key-tab"
                        data-toggle="tab"
                        href="#self-key"
                        role="tab"
                        aria-controls="self-key"
                        aria-selected="false"
                      >
                        Multisend using self-generated key
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content mt-4" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="erc20"
                      role="tabpanel"
                      aria-labelledby="erc20-tab"
                    >
                      <ul className="list-group list-group-flush">
                        {tutorialSteps?.firstTabSteps?.map((item, index) => (
                          <li
                            className="list-group-item"
                            style={{
                              background: "none",
                              border: "none",
                              color: theme.gray1,
                            }}
                            key={index + 1}
                          >
                            {index + 1}: {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="self-key"
                      role="tabpanel"
                      aria-labelledby="self-key-tab"
                    >
                      <ul className="list-group list-group-flush">
                        {tutorialSteps?.secondTabSteps?.map((item, index) => (
                          <li
                            className="list-group-item"
                            style={{
                              background: "none",
                              border: "none",
                              color: theme.gray1,
                            }}
                            key={index + 1}
                          >
                            {index + 1}: {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AboutPageContainer>
      <Footer />
    </>
  );
};

export default Tutorial;
