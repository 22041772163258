import React, { useEffect, useState } from "react";
import Router from "./Router";
import { Provider } from "react-redux";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./redux";
import { persistor } from "./redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { lightTheme, darkTheme } from "./theme";
import { GlobalStyles } from "./GlobalStyles";

const App = () => {
  const [theme, setTheme] = useState("dark");
  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
    localStorage.setItem("currentTheme", theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem("currentTheme");
    setTheme(currentTheme || "dark");
  }, [theme]);

  return (
    <div>
        <ToastContainer />
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
          <GlobalStyles />
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router theme={theme} themeToggler={themeToggler} />
            </PersistGate>
          </Provider>
        </ThemeProvider>
    </div>
  );
};

export default App;
