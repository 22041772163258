import { useSelector, useDispatch } from "react-redux";
import { deleteOnePayroll } from "../../redux/actions";

export const DeletePayroll = props => {
  const dispatch = useDispatch();
  const payrollId = useSelector(state => state.GeneralReducer.payrollId);
  const user = useSelector(state => state.persistedStore.user);

  return (
    <div className="modal custom-modal fade" id="delete_employee" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="form-header">
              <h3>Delete Payroll</h3>
              <p>Are you sure want to delete this payroll?</p>
            </div>
            <div className="modal-btn delete-action">
              <div className="row">
                <div className="col-6">
                  <a
                    data-dismiss="modal"
                    onClick={() =>
                      dispatch(deleteOnePayroll(payrollId, user.id))
                    }
                    className="btn btn-primary continue-btn"
                  >
                    Delete
                  </a>
                </div>
                <div className="col-6">
                  <a
                    data-dismiss="modal"
                    className="btn btn-primary cancel-btn"
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
