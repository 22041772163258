import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "../../redux/actions";
import { theme } from "../../theme";
import { useTheme } from "styled-components";

export const Currencies = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const payrolldetails = useSelector(
    (state) => state.PayrollReducer.payrolldetails
  );

  const [currencyData, setCurrencyData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currencyPerPage, setCurrencyPerPage] = useState(4);

  // Logic for displaying currencies
  const indexOfLastCurrency = currentPage * currencyPerPage;
  const indexOfFirstCurrency = indexOfLastCurrency - currencyPerPage;
  const currentTodos =
    payrolldetails.totalAmountPaid &&
    payrolldetails.totalAmountPaid.length !== 0 &&
    payrolldetails.totalAmountPaid.slice(
      indexOfFirstCurrency,
      indexOfLastCurrency
    );
  console.log(currentTodos);
  // Logic for displaying page numbers
  const pageNumbers = [];
  for (
    let i = 1;
    i <=
    Math.ceil(
      payrolldetails.totalAmountPaid &&
        payrolldetails.totalAmountPaid.length / currencyPerPage
    );
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <div className="col-md-6 d-flex">
      <div
        className="card card-table flex-fill"
        style={{ minHeight: 400, height: 400 }}
      >
        <div className="card-header">
          <h3 className="card-headername mb-0">Transactions</h3>
        </div>
        <div className="card-body">
          <div
            style={{ backgroundColor: theme.whiteSmoke }}
            className="table-responsive"
          >
            <table className="table custom-table mb-0">
              <thead>
                <tr>
                  <th>Currency Name</th>
                  <th>Amount Paid</th>
                  {/* <th className="text-right">Wallet Amount</th> */}
                </tr>
              </thead>
              <tbody>
                {currentTodos
                  ? currentTodos.map((each) => {
                      return (
                        <tr key={each.currencyId}>
                          <td>
                            <h2>{each.currencyId}</h2>
                          </td>
                          <td>
                            <small className="block text-ellipsis">
                              <span>{each.amountPaid}</span>{" "}
                            </small>
                          </td>
                          {/* <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0)"
                                >
                                  <i className="fa fa-pencil m-r-5" /> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0)"
                                >
                                  <i className="fa fa-trash-o m-r-5" /> Delete
                                </a>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pagination">
          {pageNumbers &&
            pageNumbers.map((number, i) => (
              <li
                key={i}
                className="m-r-10 bg-secondary px-2 rounded"
                style={{ maxHeight: 25 }}
                id={number}
                onClick={(e) => setCurrentPage(Number(e.target.id))}
              >
                {number}
              </li>
            ))}
        </div>
        <div className="card-footer">
          <p> Don't see your token? </p>
          <div
            style={{ fontSize: "0.8rem", cursor: "pointer " }}
            onClick={() => dispatch(toggleModal(21))}
          >
            {" "}
            Import token
          </div>
        </div>
      </div>
    </div>
  );
};
