export const OverviewCard = ({ icon, title, value }) => {
  return (
    <div className="card dash-widget">
      <div className="card-body">
        <span className="dash-widget-icon">
          <i className={icon} />
        </span>
        <div className="dash-widget-info">
         
          <span>{title}</span>
          <h3>{value}</h3>
        </div>
      </div>
    </div>
  );
};
