import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 140px;
  justify-content: center;
  width: 100%;
  gap: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const WhoWeAreHeader = styled.p`
  margin-bottom: 20px;
  font-size: 70px;
  font-weight: 700;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 36px;
    text-align: center;
  }
`;

const WhoWeAreSubText = styled.p`
  margin-bottom: 20px;
  font-size: 38px;
  color: ${({ theme }) => theme.lightGray};
  max-width: 1000px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Button = styled.a`
  background-color: #ffffff;
  color: ${({ theme }) => theme.greenLight};
  padding: 15px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 300px;
  border: 1px solid ${({ theme }) => theme.greenLight};
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: ${({ theme }) => theme.greenLight};
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const WhoWeAre = () => {
  return (
    <Container>
      <WhoWeAreHeader>Who We Are</WhoWeAreHeader>
      <WhoWeAreSubText>
        State what MitterPay is all about here. It should at least be up to
        three lines for visual consistency.
      </WhoWeAreSubText>
      <Button href="mailto:yourname@example.com">Email Us</Button>
    </Container>
  );
};

export default WhoWeAre;
