/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import "./styles.module.css";

const MonacoEditor = ({
  readyOnly,
  initialValue,
  ref,
  download,
  upload,
  getWantedValue,
  currentTheme,
}) => {
  const lines = initialValue
    ?.split(" ")
    ?.map((line, index) => ({ key: index.toString(), value: line }));

  const correctValue = lines?.map((line) => line.value).join("\n");

  const [value, setValue] = useState(correctValue);
  const editorRef = useRef(null);

  const handleDownload = () => {
    const csvContent = correctValue.replaceAll(" ", ",");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleFileUpload = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        setValue(content);
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    const parseAddressesAndAmounts = () => {
      const lines = value?.trim()?.split("\n");
      return lines?.map((line) => {
        const [address, amount] = line?.split(",").map((part) => part.trim());
        return { address, amount };
      });
    };

    if (!readyOnly) {
      const result = parseAddressesAndAmounts();
      getWantedValue(result);
    }
  }, [value]);

  const setEditorTheme = (monaco) => {
    monaco.editor.defineTheme("darkMode", {
      base: "vs-dark",
      inherit: true,
      rules: [
        {
          token: "comment",
          foreground: "#5d7988",
          fontStyle: "italic",
        },
        { token: "constant", foreground: "#e06c75" },
      ],
      colors: {
        "editor.background": "#041320",
        "editor.foreground": "#ffffff",
      },
    });
    monaco.editor.defineTheme("lightMode", {
      base: "vs-dark",
      inherit: true,
      rules: [
        {
          token: "comment",
          foreground: "#5d7988",
          fontStyle: "italic",
        },
        { token: "constant", foreground: "#e06c75" },
      ],
      colors: {
        "editor.background": "#C9D5E7",
        "editor.foreground": "#000000",
      },
    });
  };

  return (
    <>
      <div
        style={{
          border: "1px solid #757575",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <Editor
          height="200px"
          defaultLanguage="plaintext"
          value={value}
          theme={currentTheme === "light" ? "lightMode" : "darkMode"}
          onChange={(newValue) => setValue(newValue)}
          options={{
            lineNumbers: "on",
            automaticLayout: true,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            readOnly: readyOnly,
          }}
          beforeMount={setEditorTheme}
          editorDidMount={(editor, _monaco) => {
            editorRef.current = editor;
          }}
        />
      </div>
      <div className="upload-csv-container">
        {upload && (
          <>
            <label htmlFor="csvInput" className="upload-csv-label">
              Upload CSV
            </label>
            <input
              id="csvInput"
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
          </>
        )}
        {download && (
          <label className="upload-csv-label" onClick={handleDownload}>
            Download CSV
          </label>
        )}
      </div>
    </>
  );
};

export default MonacoEditor;
