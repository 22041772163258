import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  createPayrollRecipient,
  excelRecipient,
  getFormDetails,
  manualQuickSendRecipient,
  quickpayToggle,
  successfullAction
} from "../../redux/actions";

const AddExcelRecipient = ({ addRecipient }) => {
  const dispatch = useDispatch();

  const madeAction = useSelector(state => state.GeneralReducer.successfull);
  const quickpay = useSelector(state => state.GeneralReducer.quickpay);

  function onAdd() {
    const data = {
      A: recipientwalletAddress,
      B: recipientemail,
      C: amount
    };
    addRecipient([data]);
  }

  const recipientemail = useSelector(
    state => state.GeneralReducer.recipientemail
  );
  const recipientwalletAddress = useSelector(
    state => state.GeneralReducer.recipientwalletAddress
  );
  const amount = useSelector(state => state.GeneralReducer.amount);

  return (
    <div id="add_recipient" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add to Excel Recipient</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Recipient's email address</label>
                    <input
                      className="form-control"
                      value={recipientemail}
                      onChange={e =>
                        dispatch(
                          getFormDetails({
                            props: ["recipientemail"],
                            value: e.target.value
                          })
                        )
                      }
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Recipient's wallet address</label>
                    <input
                      className="form-control"
                      value={recipientwalletAddress}
                      onChange={e =>
                        dispatch(
                          getFormDetails({
                            props: ["recipientwalletAddress"],
                            value: e.target.value
                          })
                        )
                      }
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Amount</label>
                    <input
                      className="form-control"
                      type="text"
                      value={amount}
                      onChange={e =>
                        dispatch(
                          getFormDetails({
                            props: ["amount"],
                            value: e.target.value
                          })
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  data-dismiss="modal"
                  onClick={() => onAdd()}
                  className="btn btn-primary submit-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddExcelRecipient;
