import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { OverviewCard } from '../reusables';
import { MainNavbar } from '../reusables/nav/MainNavbar';
import { SideNavbar } from '../reusables/nav/SideNavbar';
import CustomModal from "../reusables/modal/customModal";
import modalConstants from "../reusables/modal/modalConstants";
import { CreateCurrency } from "./createCurrency";
import QuickSendApprovalModal from "../reusables/modal/quicksendapprovalModal";
import Loader from "../reusables/loader";
import PageHeader from '../reusables/nav/PageHeader'




import { QuickPay } from './quickpay';
import {
    getPayrollSummary,
} from "../../redux/actions";


export const Dashboard = () => {
    const dispatch = useDispatch();
    const madeAction = useSelector(state => state.GeneralReducer.successfull);
    const modalPage = useSelector(state => state.GeneralReducer.modalPage);
    const curr = useSelector(state => state.GeneralReducer.selectedCurrency);
    const blockchain = useSelector(state => state.persistedStore.blockchain);


    const auth = useSelector(state => state.persistedStore.user);


    const payrolldetails = useSelector(
        state => state.PayrollReducer.payrolldetails
    );
    const transactionAmt = useSelector(
        state => state.PayrollReducer.transactionAmt
    );

    useEffect(() => {
        auth && dispatch(getPayrollSummary(auth.id));
    }, [madeAction]);
    return (

        <>
            {/* <MainNavbar /> */}
            <SideNavbar />
            <div>

                {/* Page Content */}
                <div className='sidebard-content'>


                    {/* Page Header */}
                    <PageHeader
                greeting="Welcome Back!"
                subGreeting="Ready for another productive day?"
                
            />


                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <OverviewCard
                                icon="fa fa-cubes"
                                title="Payroll Plans"
                                value={payrolldetails && payrolldetails.totalPayroll}
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <OverviewCard
                                icon="fa fa-usd"
                                title="Quick pay Transactons"
                                value={payrolldetails && payrolldetails.numberOfQuickSents}
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <OverviewCard
                                icon="fa fa-diamond"
                                title="Paid Recipients"
                                value={payrolldetails && payrolldetails.totalTask}
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <OverviewCard
                                icon="fa fa-user"
                                title="Total Recipients"
                                value={payrolldetails && payrolldetails.totalReceipient}
                            />
                        </div>
                    </div>

                    <QuickPay />


                </div>
                {/* /Delete Employee Modal */}
            </div>
            {modalPage === modalConstants.CreateCurrency ? (
                <CustomModal>
                    <CreateCurrency />
                </CustomModal>
            ) : (
                ""
            )}
            {modalPage === modalConstants.QuickSendApprovalModal ? (
                <QuickSendApprovalModal
                    msg={
                        transactionAmt.approvedAmount ? (
                            `You are about to make a transfer of ${transactionAmt.approvedAmount
                            } ${curr && curr.name} at a transaction fee of ${transactionAmt.totaltransactionFee
                            } ${blockchain === 1 ? "BNB" : blockchain === 3 ? "MATIC" : "ARBITRUM"}. A total of ${transactionAmt.approvedAmount
                            } ${curr && curr.name} will be deducted from your wallet.`
                        ) : (
                            <Loader size={12} />
                        )
                    }
                />
            ) : (
                ""
            )}
        </>

    )

}