import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPayrollRecipients, getFormDetails } from "../../redux/actions";
import DatePicker from "react-datepicker";

const SearchRecipients = props => {
  const dispatch = useDispatch();
  let { payrollId } = useParams();
  const recipientsdateTo = useSelector(
    state => state.GeneralReducer.recipientsdateTo
  );
  const recipientsdateFrom = useSelector(
    state => state.GeneralReducer.recipientsdateFrom
  );
  const tagName = useSelector(state => state.GeneralReducer.tagName);
  return (
    <div className="row filter-row">
      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
        <div className="form-group form-focus">
          <input
            type="text"
            className="form-control floating"
            value={tagName}
            onChange={e =>
              dispatch(
                getFormDetails({
                  props: ["tagName"],
                  value: e.target.value
                })
              )
            }
          />
          <label className="focus-label">Employee ID</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12"></div>
      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12"></div>
      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
        <div className="form-group form-focus">
          <div className="cal-icon">
            <DatePicker
              selected={recipientsdateFrom}
              onChange={date =>
                dispatch(
                  getFormDetails({
                    props: ["recipientsdateFrom"],
                    value: date
                  })
                )
              }
              className="form-control floating datetimepicker"
            />
          </div>
          <label className="focus-label">From</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
        <div className="form-group form-focus">
          <div className="cal-icon">
            <DatePicker
              selected={recipientsdateTo}
              onChange={date =>
                dispatch(
                  getFormDetails({
                    props: ["recipientsdateTo"],
                    value: date
                  })
                )
              }
              className="form-control floating datetimepicker"
            />
          </div>
          <label className="focus-label">To</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
        <div
          onClick={() =>
            dispatch(
              getPayrollRecipients(
                payrollId,
                1,
                30,
                tagName,
                recipientsdateFrom,
                recipientsdateTo
              )
            )
          }
          className="btn btn-success btn-block"
        >
          {" "}
          Search{" "}
        </div>
      </div>
    </div>
  );
};
export default SearchRecipients;
