import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background-color: transparent;
  color: white;
  border: 1px solid #3a547a;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 100;

  @media (min-width: 768px) {
    padding: 50px;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.black};
  margin-bottom: 10px;
  font-size: 36px;

  @media (min-width: 768px) {
    font-size: 56px;
  }
`;

const Subtitle = styled.p`
  margin-bottom: 40px;
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.gray3};

  @media (min-width: 768px) {
    font-size: 32px;
    margin-bottom: 60px;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
`;

const FeatureCard = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.gray26};
  border-radius: 20px;
  transition: transform 0.2s;
  width: 100%;
  max-width: 656px;
  height: auto;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    gap: 40px;
  }

  @media (min-width: 1024px) {
    gap: 50px;
  }
`;

const FeatureIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FeatureTitle = styled.h3`
  color: ${({ theme }) => theme.text};
  margin-bottom: 10px;
  text-align: left;
  font-size: 24px;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const FeatureDescription = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.text};
  text-align: left;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const Features = () => (
  <Container id="features">
    <Title>Features</Title>
    <Subtitle>Why Individuals and Businesses prefer MitterPay</Subtitle>
    <FeaturesGrid>
      <FeatureCard>
        <FeatureIcon
          src="/assets/img/PNG/fast_payment_pic.png"
          alt="Fast Payment"
          className="special"
        />
        <TextContainer>
          <FeatureTitle>Fast Payment</FeatureTitle>
          <FeatureDescription>
            With MitterPay, you can complete payments of unlimited transactions
            in one minute.
          </FeatureDescription>
        </TextContainer>
      </FeatureCard>
      <FeatureCard>
        <FeatureIcon
          src="/assets/img/PNG/bulk_payment_logo.png"
          alt="Bulk Payment"
        />
        <TextContainer>
          <FeatureTitle>Bulk Payment</FeatureTitle>
          <FeatureDescription>
            With MitterPay, you can pay everyone in your organization by simply
            clicking a button.
          </FeatureDescription>
        </TextContainer>
      </FeatureCard>
      <FeatureCard>
        <FeatureIcon
          src="/assets/img/PNG/team_org_pic.png"
          alt="Team Organization"
        />
        <TextContainer>
          <FeatureTitle>Team Organization</FeatureTitle>
          <FeatureDescription>
            The Payroll feature allows you to organize recipients into teams to
            help for future payments.
          </FeatureDescription>
        </TextContainer>
      </FeatureCard>
      <FeatureCard>
        <FeatureIcon
          src="/assets/img/PNG/Cheap_trans_fee_pic.png"
          alt="Cheap Transaction Fees"
        />
        <TextContainer>
          <FeatureTitle>Cheap Transaction Fees</FeatureTitle>
          <FeatureDescription>
            MitterPay is highly optimized and has been created to give you the
            cheapest transaction fees - The best of both worlds!
          </FeatureDescription>
        </TextContainer>
      </FeatureCard>
    </FeaturesGrid>
  </Container>
);

export default Features;
