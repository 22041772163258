import React, { useState } from "react";
import styled from "styled-components";
import { faqData } from "../../FAQs/util";
import FAQItem from "../../FAQs/FAQItems";

const FAQContainer = styled.div`
  background-color: transparent;
  color: #fff;
  text-align: center;
  z-index: 100;
`;

const FAQHeader = styled.p`
  margin-bottom: 20px;
  font-size: 60px;
  font-weight: 700;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const FAQs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 5px;
  }
`;

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQContainer id="faqs">
      <FAQHeader>Frequently Asked Questions</FAQHeader>
      <FAQs>
        {faqData?.map((item, index) => (
          <FAQItem
            key={index}
            question={item.question}
            answer={item.answer}
            links={item.links}
            isActive={activeIndex === index}
            onToggle={() => toggleFAQ(index)}
          />
        ))}
      </FAQs>
    </FAQContainer>
  );
};

export default FAQ;
