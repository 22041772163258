import _const from "../types";

const initialState = {
  allpayrolls: [],
  payrolldetails: {},
  allPayrollRecipients: [],
  payrollSearchResult: [],
  payrollrecipinetsAddresses: [],
  payrollreciinetsAmounts: [],
  batchId: "",
  creatingPayrollErr: "",
  selectedpayroll: {},
  transactionAmt: {},
  creatingRecipientErr: ""
};

const PayrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ALL_PAYROLL:
      return {
        ...state,
        allpayrolls: action.payload.records
      };

    case _const.ALL_PAYROLL_SUMMARY:
      return { ...state, payrolldetails: action.payload };

    case _const.ALL_PAYROLL_RECIPIENTS:
      let recipientAddresses = [];
      let recipientAmounts = [];
      if (action.payload.records.length > 0) {
        action.payload.records.map(each => {
          recipientAddresses.push(each.walletAddress.trim());
          recipientAmounts.push(each.amount);
        });
      }

      return {
        ...state,
        allPayrollRecipients: action.payload.records,
        payrollrecipinetsAddresses: recipientAddresses,
        payrollreciinetsAmounts: recipientAmounts
      };

    case _const.INITIATING_PAYMENT_SUCCESS:
      return { ...state, batchId: action.payload.batchId };

    case _const.RECIPIENT_CREATED:
      return {
        ...state,
        allPayrollRecipients: []
      };

    case _const.PAYROLLL_SEARCH_RESULT:
      return { ...state, payrollSearchResult: action.payload };

    case _const.CREATING_PAYROLL_ERR:
      return { ...state, creatingPayrollErr: action.payload };

    case _const.SELECTED_PAYROLL_DETAILS:
      return { ...state, selectedpayroll: action.payload };

    case _const.TRANSACTION_FEES:
      return { ...state, transactionAmt: action.payload };

    case _const.CREATING_PAYROLL_RECIPIENT_ERR:
      return { ...state, creatingRecipientErr: action.payload };

    default:
      return state;
  }
};

export default PayrollReducer;
