import React, { useState } from "react";
import FAQItem from "./FAQItems";
import { faqData } from "./util";
import Header from "../reusables/Navs/Header";
import Footer from "../reusables/Navs/Footer";
import styled from "styled-components";

const FAQTitle = styled.p`
  margin-bottom: 20px;
  font-size: 70px;
  font-weight: 700;
  color: ${({ theme }) => theme.black};
`;

const FAQsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0;
`;

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Header />
      <section className="main-content section">
        <div className="container">
          <div className="m-container">
            <FAQTitle>Frequently asked questions</FAQTitle>
            <FAQsContainer>
              {faqData?.map((item, index) => (
                <FAQItem
                  key={index + 1}
                  question={item?.question}
                  answer={item?.answer}
                  links={item?.links}
                  isActive={activeIndex === index}
                  onToggle={() => toggleFAQ(index)}
                />
              ))}
            </FAQsContainer>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FAQs;
