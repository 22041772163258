import _const from "../types";

const initialState = {
  quickPayrecipientsAddresses: [],
  quickPayrecipientsAmounts: [],
  quickpayrecipients: []
};

const QuickSendReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ALL_QUICKPAY_RECIPIENTS:
      let recipientAddresses = [];
      let recipientAmounts = [];
      if (action.payload.length > 0) {
        action.payload.map(each => {
          recipientAddresses.push(each.A.trim());
          recipientAmounts.push(each.C);
        });
      }
      return {
        ...state,
        quickpayrecipients: action.payload,
        quickPayrecipientsAddresses: recipientAddresses,
        quickPayrecipientsAmounts: recipientAmounts
      };

    default:
      return state;
  }
};

export default QuickSendReducer;
