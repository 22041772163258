import { Stepper, Step } from "react-form-stepper";
import React from "react";
import { useTheme } from "styled-components";

const StepperComponent = ({ steps, activeStep }) => {
  const theme = useTheme();

  const styleConfig = {
    activeBgColor: theme.gray5,
    activeTextColor: theme.greenLight,
    completedBgColor: theme.greenLight,
    completedTextColor: theme.white,
    inactiveBgColor: theme.gray12,
    inactiveTextColor: theme.gray1,
    size: "1.5em",
    width: "2em",
  };

  const labelStyle = {
    color: theme.greenLight,
    fontSize: "0.8em", // Smaller font size for labels
    padding: 0, // Adjust padding for smaller size
  };

  const stepperStyle = {
    width: "80%", // Adjust this percentage to control the width
    margin: "0 auto", // Center the stepper
  };

  return (
    <div style={stepperStyle}>
      <Stepper activeStep={activeStep} styleConfig={styleConfig}>
        {steps?.map((step) => (
          <Step
            label={<span style={labelStyle}>{step?.label}</span>}
            key={step}
          />
        ))}
      </Stepper>
    </div>
  );
};

export default StepperComponent;
