import { CenterContent, SuccessMsg } from "./modalstyles";
import Logo from "../../home/images/logo.png";
import { useDispatch } from "react-redux";
import { ModalContainer, ModalContent, Close } from "./modalstyles";
import { toggleModal } from "../../../redux/actions";
import { useTheme } from "styled-components";

const SuccessModal = ({ icon, msg }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <ModalContainer>
      {/* Modal content */}
      <ModalContent
        style={{
          backgroundColor: theme.white,
          width: "40%",
          borderRadius: "3%",
        }}
      >
        <Close className="close" onClick={() => dispatch(toggleModal(0))}>
          ×
        </Close>
        <img width="10%" src={Logo} alt="logo" />
        <div
          style={{
            paddingTop: "5%",
            paddingBottom: "5%",
            paddingRight: "20%",
            paddingLeft: "20%",
          }}
        >
          <h2
            style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
          >
            Success!
          </h2>
          <CenterContent>
            {icon ? <img height="150px" src={icon} alt="success" /> : ""}
            <SuccessMsg> {msg}</SuccessMsg>
          </CenterContent>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

export default SuccessModal;
