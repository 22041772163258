import axios from "axios";
import config from "../config";
import _const from "../types";
import { addError } from "./error";
import { toggleModal } from "./generalActions";

//Get Currencies
export const getCurrenciesAction = blockchain => {
  return dispatch => {
    axios
      .get(
        config.apiUrl +
          `/Currency/GetCurrencies?blockchain=${blockchain}&isTestNet=${config.isTestNet}`
      )
      .then(res => {
        dispatch({
          type: _const.GET_CURRENCY,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch(addError(err));
      });
  };
};

//Add Currency
export const createCurrency = (blockchainType, contractAddress, isTestNet) => {
  console.log(blockchainType, contractAddress)
  let data = {
    contractAddress: contractAddress,
    blockchain: Number(blockchainType),
    isTestnet: isTestNet,
    name: "string",
    logoUrl: "string",
    symbol: "string",
    decimal: 0,
    currencyType: 1
  };
  return dispatch => {
    dispatch({
      type: _const.CREATING_CURRENCY,
      payload: true
    });
    axios
      .post(config.apiUrl + `/Currency/CreateCurrencies`, data)
      .then(res => {
        dispatch({
          type: _const.CREATING_CURRENCY,
          payload: false
        });
        dispatch(toggleModal(50));
        dispatch(getCurrenciesAction(blockchainType));
        dispatch({
          type: _const.CURRENCY_CREATED_SUCCESSFULLY,
          payload: res.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_CURRENCY,
          payload: false
        });
        dispatch({
          type: _const.CREATING_CURRENCY_ERROR,
          payload: err.response.data.data
        });
      });
  };
};
