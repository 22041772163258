import { Link } from "react-router-dom";
import moment from "moment";

export const PayrollCard = ({
  id,
  name,
  createdAt,
  edit,
  remove,
  currency,
  icon,
  reminder,
  receipients
}) => {
  return (
    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-4">
      <div className="profile-widget">
        <div className="profile-img float-left">
          <Link to={`/payroll/${id}`} className="avatar">
            <img src={icon} alt="payroll icon" />
          </Link>
        </div>
        <div className="dropdown profile-action">
          <a
            href="#"
            className="action-icon dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
          <a
              className="dropdown-item"
              onClick={receipients}
              data-toggle="modal"
              data-target="#add_receipients"
            >
              <i className="fa fa-users m-r-5" /> view receipients
            </a>
            <a
              className="dropdown-item"
              onClick={edit}
              data-toggle="modal"
              data-target="#edit_employee"
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </a>
            
            <a
              className="dropdown-item"
              onClick={remove}
              data-toggle="modal"
              data-target="#delete_employee"
            >
              <i className="fa fa-trash-o m-r-5" /> Delete
            </a>
          </div>
        </div>
        <h4 className="user-name m-t-10 mb-3 text-ellipsis">
          <Link to={`/payroll/${id}`}>{name}</Link>
        </h4>
        <div>
          <div className=" float-left small text-muted text-ellipsis">
            {'Currency'}
          </div>
          <div className=" float-right small text-muted text-ellipsis">
            {'Created'}
          </div>
        </div>
        <div>
          <div className=" float-left small profile-small text-ellipsis">
            {currency}
          </div>
          <div className=" float-right small profile-small text-ellipsis">
            {moment(createdAt).format("Do MMMM YYYY, h:mm")}
          </div>
        </div>

      </div>
    </div>
  );
};
