import UploadExcelModal from "../excel/UploadExcelModal";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Currencies } from "./currencies";
import CreateQuickSend from "../quickSend/addQuickSendRecipient";
import QuickSendRecipients from "../quickSend/quickSendRecipient";
import { FaArrowRight } from "react-icons/fa";

import {
  getFormDetails,
  quickpayRecipient,
  quickpayToggle,
  toggleModal
} from "../../redux/actions";
import CustomModal from "../reusables/modal/customModal";
import SuccessModal from "../reusables/modal/successModal";
import excelToJson from "../../excelToJson";
import swal from "sweetalert";
import modalConstants from "../reusables/modal/modalConstants";


export const QuickPay = props => {
  const dispatch = useDispatch();

  const excelImport = useSelector(
    state => state.GeneralReducer.quickpayRecipient
  );
  const madeAction = useSelector(state => state.GeneralReducer.successfull);
  const auth = useSelector(state => state.persistedStore.user);
  const modalPage = useSelector(state => state.GeneralReducer.modalPage);

  const madeImport = useSelector(state => state.GeneralReducer.madeImport);
  const quickpay = useSelector(state => state.GeneralReducer.quickpay);

  const convertExcelToObject = file => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);

      let result = excelToJson({ source: data });
      console.log(result, "i am the result here");
      let d = result.Sheet1[0];

      if (!result.Sheet1) {
        swal({
          title: "Import Failed!",
          text:
            "Wrong file arrangement. Download template for expected column arrangement",
          icon: "warning",
          dangerMode: true
        });
      } else if (!d.A) {
        swal({
          title: "Import Failed!",
          text:
            "Wrong file arrangement. Download template for expected column arrangement",
          icon: "warning",
          dangerMode: true
        });
      } else if (
        d.A.toLowerCase()
          .split(" ")
          .join("") !== "walletaddress" ||
        d.B.toLowerCase()
          .split(" ")
          .join("") !== "emailaddress" ||
        d.C.toLowerCase()
          .split(" ")
          .join("") !== "amount"
      ) {
        swal({
          title: "Import Failed!",
          text:
            "Wrong file arrangement. Download template for expected column arrangement",
          icon: "warning",
          dangerMode: true
        });
        // dispatch(toggleModal(0));
      } else {
        dispatch(quickpayToggle(true));
        result.Sheet1.shift() && dispatch(quickpayRecipient(result.Sheet1));
        //  dispatch(toggleModal(0));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const dropHandler = event => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    dispatch(
      getFormDetails({
        props: ["quickpayrecipientFile"],
        value: file
      })
    );
    file && convertExcelToObject(file);
  };

  // const uploadFileHandler = event => {
  //   const file = event.target.files[0];
  //   dispatch(
  //     getFormDetails({
  //       props: ["quickpayrecipientFile"],
  //       value: file
  //     })
  //   );
  //   file && convertExcelToObject(file);
  // };

  const dragOverHandler = event => {
    event.preventDefault();
  };

  return (
    <div
      className="row"
      style={{ minHeight: 400, height: 400, marginBottom: "5%" }}
    >
      <div className="col-md-6 d-flex">
        <div className="card card-table flex-fill">
          <div className="card-header d-flex flex-row justify-content-between">
            <div className=" d-flex flex-column w-100 ">
              <h3 className="card-headername mb-0">Quick Pay</h3>
             
              <div className="d-flex flex-row justify-content-between">
              <p className="card-headersubname" >Send payroll at a speed of light</p>
                <Link
                  to={`/quicksend/transactions/${"0"}`}
                  className="view-quickpay"
                >
                  View Transactions

                  <FaArrowRight className="ml-2" />
                </Link>
              </div>
            </div>

          </div>
          {excelImport.length > 0 && quickpay && (
            <QuickSendRecipients data={excelImport} />
          )}
          <div className="card-body w-75 h-50 center-content">
            {excelImport.length === 0 && (
              <div
                onDrop={dropHandler}
                onDragOver={dragOverHandler}
                onClick={() => 
                  {
                    dispatch(toggleModal(23))}}
                className="upload-excel-container"
              >
                <div style={{ alignSelf:'center', marginBottom:25 }} >
                  <img src="/assets/img/upload.png" alt="upload" className="img-responsive"  height="50" />
                </div>
                <div>
                  <div style={{ fontSize: "0.8rem", alignSelf:'center' }}>
                    Upload or Drag and Drop an excel file here
                  </div>{" "}
                </div>
              </div>
            )}
            {/* {excelImport.length > 0 ||
              (!madeImport && (
                <div className="col-auto ml-auto float-right">
                  <div
                    className="btn add-btn"
                    onClick={() => dispatch(toggleModal(120))}
                  >
                    <i className="fa fa-plus" /> Add Quick Pay Recipients
                  </div>
                </div>
              ))} */}
          </div>
        </div>
      </div>
      <Currencies />
      {modalPage === modalConstants.UploadExcelModal? (
        <CustomModal>
          <UploadExcelModal quick={true} />
        </CustomModal>
      ) : (
        ""
      )}
      {modalPage === modalConstants.TransactionSuccess ? (
        <SuccessModal
          icon="/assets/img/success.svg"
          msg={
            <div>
              {" "}
              <p> Transaction successsful!</p>{" "}
            </div>
          }
        />
      ) : (
        ""
      )}

      {modalPage === modalConstants.CreateQuickSend ? (
        <CustomModal>
          <CreateQuickSend />
        </CustomModal>
      ) : (
        ""
      )}
    </div>
  );
};
