import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  gap: 30px;
`;

const AchievementsText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 50px;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.black};
  margin-bottom: 20px;
  font-size: 36px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 60px;
    text-align: left;
  }
`;

const Subtitle = styled.p`
  margin-bottom: 40px;
  font-size: 18px;
  color: #7b8b9a;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 32px;
    text-align: left;
  }
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
`;

const StatTitle = styled.p`
  font-size: 18px;
  color: #7b8b9a;
  margin-bottom: 5px;
`;

const StatValue = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

const Achievements = () => {
  return (
    <Container>
      <Title>Achievements</Title>
      <Subtitle>Helping thousands of customers achieve their goals</Subtitle>
      <AchievementsText>
        <Stat>
          <StatTitle>Total Payment Sent</StatTitle>
          <StatValue>1000 M+</StatValue>
        </Stat>
        <Stat>
          <StatTitle>Total Users</StatTitle>
          <StatValue>50k +</StatValue>
        </Stat>
        <Stat>
          <StatTitle>Companies</StatTitle>
          <StatValue>5k +</StatValue>
        </Stat>
      </AchievementsText>
    </Container>
  );
};

export default Achievements;
