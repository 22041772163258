import React from "react";
import PolyGonLogo from "./img/polygon_login.png";
import { BlockchainSelector } from "./style";
import { useDispatch } from "react-redux";
import { storeAddress } from "../../redux/actions";
import Binance_Icon from "../home/images/binance-logo.png";
import Polygon_Icon from "../home/images/polygon.png";
import Arbitrum_Icon from "../home/images/arbitrum.png";
import Logo from "../home/images/logo.png";
import DoublePlane from "./img/doublePlane.png";
import { theme } from "../../theme";
import { useTheme } from "styled-components";

export const Login = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <>
      <img
        style={{ width: "120px", marginLeft: "100px" }}
        src={Logo}
        alt="logo"
      />
      <img src={DoublePlane} className="double_plane" />
      <div
        style={{
          top: "20%",
          left: "40%",
          position: "absolute",
          alignSelf: "center",
        }}
      >
        <p className="account-header-text">Sign In</p>
        <p className="account-writeup">
          Please choose any of the avalable wallet and
        </p>
        <p className="account-writeup">sign in.</p>
        <div className="account-connect">
          <img
            src={PolyGonLogo}
            alt="..."
            style={{ width: "25px", height: "25px", marginRight: 9 }}
          />
          <a href="/" className="account-connect-writeup">
            Learn how to connect
          </a>
        </div>

        <BlockchainSelector
          onClick={() => {
            dispatch(
              storeAddress(
                {
                  //chainId: `0x${Number(97).toString(16)}`,
                  chainId: `0x${Number(56).toString(16)}`,
                  chainName: "Binance Smart Chain",
                  nativeCurrency: {
                    name: "Binance Chain Native Token",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  rpcUrls: ["https://bsc-dataseed.binance.org"],
                  blockExplorerUrls: ["https://bscscan.com"],
                },
                1,
                false
              )
            );
          }}
        >
          <img
            style={{ float: "left", marginLeft: "5%" }}
            src={Binance_Icon}
            alt="..."
          />{" "}
          <h4
            style={{
              fontWeight: 500,
              textAlign: "center",
              fontSize: 20,
              color: theme.black,
            }}
          >
            Binance Smart Chain
          </h4>
          {/* <img
              src="/assets/img/bsclogo.png"
              className="img-responsive"
              alt="BSC logo"
              height={"50px"}
            />{" "} */}
        </BlockchainSelector>
        <BlockchainSelector
          onClick={() => {
            dispatch(
              storeAddress(
                {
                  chainId: `0x${Number(137).toString(16)}`,
                  chainName: "Polygon Mainnet",
                  nativeCurrency: {
                    name: "MATIC Token",
                    symbol: "MATIC",
                    decimals: 18,
                  },
                  rpcUrls: ["https://polygon-rpc.com"],
                  blockExplorerUrls: ["https://polygonscan.com/"],
                },
                3,
                false
              )
            );
          }}
        >
          <img
            style={{ float: "left", marginLeft: "5%" }}
            src={Polygon_Icon}
            alt="..."
          />{" "}
          <h4
            style={{
              fontWeight: 500,
              textAlign: "center",
              fontSize: 20,
              color: theme.black,
            }}
          >
            Polygon
          </h4>
          {/* <img
              src="/assets/img/polygon.png"
              alt="Polygon logo"
              height={"50px"}
            /> */}
        </BlockchainSelector>
        <BlockchainSelector
          onClick={() => {
            dispatch(
              storeAddress(
                {
                  chainId: `0x${Number(42161).toString(16)}`,
                  chainName: "Arbitrum One",
                  nativeCurrency: {
                    name: "Etheruem Token",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  rpcUrls: [
                    "https://arbitrum-mainnet.core.chainstack.com/988f2000f683efacfe854444026c7c94",
                  ],
                  blockExplorerUrls: ["https://arbiscan.io/"],
                },
                4,
                false
              )
            );
          }}
        >
          <img
            style={{ float: "left", marginLeft: "5%" }}
            src={Arbitrum_Icon}
            height={"10%"}
            alt="..."
          />{" "}
          Arbitrum
          {/* <img
              src="/assets/img/polygon.png"
              alt="Polygon logo"
              height={"50px"}
            /> */}
        </BlockchainSelector>
      </div>

      {/* <div className="account-footer" >
        <p className="account-footer-text" >Built by Midas</p>
        <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }} >
          <p className="account-footer-text" >©2021 Midas</p>
          <p className="account-footer-text" >Privacy Policy</p>
          <p className="account-footer-text" >Terms and Conditions</p>
        </div>
      </div> */}

      <div className="account-footer">
        <div className="account-footer-row">
          <p className="account-footer-text">Built by Midas Team</p>
          <div
            style={{
              display: "flex",
              width: "50%",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <p className="account-footer-text">©2021 Midas Bulksender</p>
            <p className="account-footer-text">Privacy Policy</p>
            <p className="account-footer-text">Terms and Conditions</p>
          </div>
        </div>
      </div>
    </>
  );
};
