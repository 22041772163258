import { useDispatch } from "react-redux";
import { ModalContainer, ModalContent, Close } from "./modalstyles";
import { toggleModal } from "../../../redux/actions";
import { useTheme } from "styled-components";

const ConnectWalletModal = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useTheme()
  return (
    <ModalContainer>
      <ModalContent
        style={{
          backgroundColor: theme.body,
          borderRadius: "3%",
        }}
      >
        <Close className="close" onClick={() => dispatch(toggleModal(0))}>
          ×
        </Close>
        {children}
      </ModalContent>
    </ModalContainer>
  );
};
export default ConnectWalletModal;
