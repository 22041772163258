export const faqData = [
  {
    question:
      "What is Multisender?Must I register to access the quick pay functionality?",
    answer:
      "Yes, but registration is very simple, just connect your wallet to register",
  },
  {
    question: "Must I register to access the quick pay functionality?",
    answer:
      "Yes. This tool is in production since 2018. It accounts for over 30,000 transactions on Mainnet, which is equivalent to several billion dollars. You can find the Multisender Smart contract along with all previous transactions on Etherscan.",
  },
  {
    question: "How do I have to connect my wallet",
    answer:
      "It depends on the gas price and network congestion. It typically takes 3 to 5 minutes.",
  },
];
