import { useEffect } from "react";
import { SideNavbar } from "../reusables/nav/SideNavbar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { EmptyCard } from "../reusables";
import {
  copyToClipboard,
  getQuickSendTransactionHistory,
} from "../../redux/actions";
import Loader from "../reusables/loader";
import { formathash } from "../../methods/helper";
import { useTheme } from "styled-components";

const QuickSendTransaction = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const quickSendTransaction = useSelector(
    (state) => state.ReportReducer.quickSendTransaction
  );
  const gettingTransaction = useSelector(
    (state) => state.LoaderReducer.gettingTransaction
  );
  const auth = useSelector((state) => state.persistedStore);
  const copyBoard = useSelector((state) => state.GeneralReducer.copyBoard);

  useEffect(() => {
    dispatch(getQuickSendTransactionHistory(auth.address, 1, 50));
  }, [dispatch]);

  return (
    <>
      <SideNavbar />
      {/* Page Wrapper */}
      <div>
        {/* Page Content */}
        <div className="sidebard-content">
          {/* Page Header */}
          <div
            style={{ backgroundColor: theme.white, padding: "1%" }}
            className="page-header"
          >
            <div className="row align-items-center">
              <div className="col">
                <h3 className="dashboard-name">Hi</h3>
                <ul className="breadcrumb">
                  <li className="dashboard-subname">
                    How is your day going?😎
                  </li>
                </ul>
              </div>
              <div className="col-auto float-right ml-auto">
                <div
                  style={{
                    height: 50,
                    width: 50,
                    borderRadius: 25,
                    padding: "2%",
                    backgroundColor: theme.gray7,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div>
            {gettingTransaction ? (
              <EmptyCard
                message={<Loader size={12} />}
                description="Generating Report..."
              />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>BatchId</th>
                          <th>Created at</th>
                          <th>WalletAddress</th>
                          <th>TransactionHash</th>
                          <th>Status</th>
                          {/* <th>No of Valid Address</th>
                        <th>No of InValid Address</th> */}
                          <th className="text-center">Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quickSendTransaction.map((eachReport, i) => {
                          console.log(eachReport);
                          return (
                            <tr id={eachReport.id} key={i}>
                              <td>{i + 1}</td>
                              <td>{eachReport.payrollRequests[0].batchId}</td>
                              <td>
                                {moment(eachReport.createdAt).format(
                                  "Do MM YYYY, h:mm"
                                )}
                              </td>
                              <td>
                                <b>{formathash(eachReport.walletAddress)}</b>
                                {eachReport.walletAddress && (
                                  <span
                                    className="copy"
                                    onClick={() =>
                                      dispatch(
                                        copyToClipboard(
                                          eachReport.walletAddress
                                        )
                                      )
                                    }
                                  >
                                    <i
                                      className={
                                        copyBoard === eachReport.walletAddress
                                          ? "fa fa-copy fa-lg approved"
                                          : "fa fa-copy fa-lg"
                                      }
                                    />{" "}
                                  </span>
                                )}
                              </td>
                              <td>
                                <b>
                                  {formathash(
                                    eachReport.payrollRequests[0]
                                      .transactionHash
                                  )}
                                </b>
                                {eachReport.payrollRequests[0]
                                  .transactionHash && (
                                  <span
                                    className="copy"
                                    onClick={() =>
                                      dispatch(
                                        copyToClipboard(
                                          eachReport.payrollRequests[0]
                                            .transactionHash
                                        )
                                      )
                                    }
                                  >
                                    <i
                                      className={
                                        copyBoard ===
                                        eachReport.payrollRequests[0]
                                          .transactionHash
                                          ? "fa fa-copy fa-lg approved"
                                          : "fa fa-copy fa-lg"
                                      }
                                    />{" "}
                                  </span>
                                )}
                              </td>
                              <td>
                                <div className="pending">
                                  {" "}
                                  {eachReport.payrollRequests[0].status}
                                </div>
                              </td>
                              {/* <td>{eachReport.totalValidAddresses}</td>
                            <td>{eachReport.totalInvalidAddresses}</td> */}
                              <td className="text-center">
                                {eachReport.quickSendRecipientDetails.reduce(
                                  (accumulator, transaction) =>
                                    accumulator + transaction.amount,
                                  0
                                )}
                              </td>
                              <td>
                                {console.log(
                                  eachReport.quickSendRecipientDetails
                                )}
                                <Link
                                  to={{
                                    pathname: `/quicksend/${eachReport.quickSendId}`,
                                  }}
                                  className="btn btn-sm btn-primary"
                                  href="salary-view.html"
                                >
                                  View Recipients
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {quickSendTransaction.length > 0 ? (
                      ""
                    ) : (
                      <EmptyCard
                        message={"You have no quick pay transactions yet"}
                        description="Make payments into their wallet address to view the report here."
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickSendTransaction;
