import _const from "../types";

const initialState = {
  recipientReport: [],
  quickSendRecipient:[],
  quickSendTransaction:[],
  currentPage: 0,
  totalPages: 1
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.GET_RECEPIENT_REPORT:
      return {
        ...state,
        recipientReport: state.recipientReport.concat(action.payload.records),
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage
      };

      case _const.GET_QUICKSEND_RECIPIENT:
      return {
        ...state,
        quickSendRecipient: action.payload.records,
      };

      case _const.GET_QUICKSEND_TRANSACTION:
        return {
          ...state,
          quickSendTransaction: action.payload.records,
        };

    case _const.CLEAR_DATA:
      return { ...state, recipientReport: [], quickSendReport:[], quickSendTransaction:[], currentPage: 0, totalPages: 1 };

    default:
      return state;
  }
};

export default ReportReducer;
