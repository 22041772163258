// export const FORWARDER_ADDRESS = "0x1F920a8a5a5c5d4288e9b8B4E2De39Fdba1E9433";
// export const FORWARDER_ADDRESS_SOL =
//   "0xA1F0C060F0A63840454cCFE312B1986996a8b5A3";

// export const FORWARDER_ADDRESS = "0x915538C2748B760f2b54b5F3a8656C4D257AcF50";
// export const FORWARDER_ADDRESS_SOL =
//   "0x0cCBaaa2931a76225957aeF25428bA1FD6140835";
// export const FORWARDER_ADDRESS_POLYGON =
//   "0x87FF12A951695883241C4c24aBD1055B4E1ef391";
// export const FORWARDER_ADDRESS_SOL_POLYGON =
//   "0xBB60Ec8f4F278a2DC98cEcD1B91D3150c9062A5E";

let store = localStorage.getItem("persist:persistedStore");
let storeItem = JSON.parse(store);
let blockchain = storeItem && storeItem.blockchain;


  let contractInfo = {
    56: {
      FORWARDER_ADDRESS: "0xF83F80ABd824523Bd8A8Aa11C9BC99Ce9a670012",
      FORWARDER_ADDRESS_SOL: "0xC1eD11557882644E6bD80f4363dfC3dA88025f33",
    },
    42161: {
      FORWARDER_ADDRESS: "0xe2D3862e0923667A735AA7b0E0c2ea07F5455f0c",
      FORWARDER_ADDRESS_SOL: "0x48DffFF1F32DeF2cda4791611115b2ddefF84208",
    },
    42421: {
      FORWARDER_ADDRESS: "0xaCabB839DDaCaA565198aB60C7AA885B5d9352e8",
      FORWARDER_ADDRESS_SOL: "0xF459eb2f7e32Fa6057bBD540f4c340d731BA9c03",
    },
  };


export default contractInfo;
